import React, { useState, useEffect } from "react";
import { TransitionGroup } from "react-transition-group";
import axios from "axios";

import { apiLinks } from "../../utils/links";

import NFTEvalHeader from "./NFTEvalHeader";
import NFTTitle from "./NFTTitle";
import { NFTEvalList } from "./NFTEvalList";
import NFTEvalSection from "./NFTEvalSection";
import NFTPic from "./NFTPic";
import TwitterModal from "../TwitterModal/TwitterModal";

import Page from "../NextPrevButtons/Page";

const NFTEval = (props) => {
  const { open } = props;

  const [newPrev, setNewPrev] = useState("");
  const [allData, setAllData] = useState([]);
  const [nftName, setNftName] = useState("");
  const [email, setEmail] = useState(window.sessionStorage.getItem("email"));
  const [evalSectionVisible, setEvalSectionVisible] = useState(false);
  const [sectionCompleted, setSectionCompleted] = useState(
    new Array(NFTEvalList.length).fill(false)
  );
  const [searchingNFT, setSearchingNFT] = useState(false);
  const [imgStr, setImgStr] = useState("");
  const [allAns, setAllAns] = useState(
    new Array(NFTEvalList.length).fill(false)
  );
  const [moduleName, setModuleName] = useState("nfteval");
  const [moduleId, setModuleId] = useState("");
  const [isError, setIsError] = useState(false);
  const [dbData, setDbData] = useState("");

  function checkTrue(element) {
    return element === true;
  }

  const setToDefault = () => {
    setSectionCompleted(new Array(NFTEvalList.length).fill(false));
    setNftName("");
    setSearchingNFT(false);
    setEvalSectionVisible(false);
    setDbData("");
    setModuleId("");
  };

  useEffect(() => {
    setAllData(NFTEvalList);
    // setSectionCompleted(new Array(NFTEvalList.length).fill(false));
  }, []);

  useEffect(() => {
    if (sectionCompleted.every((element) => checkTrue(element))) {
      window.sessionStorage.setItem(moduleName, true);
      return;
    }
    //this is to ensure new analysis loaded after loading a prev analysis does not cause answers to be loaded in results prematurally
    window.sessionStorage.setItem(moduleName, false);
  }, [sectionCompleted, setSectionCompleted]);

  useEffect(() => {
    //set everything to default
    if (newPrev === "new") {
      setToDefault();
    } else if (newPrev === "prev") {
      // var urlLink = apiLinks.reportApi + moduleName + "/" + email + "/cg";
      setSearchingNFT(true);

      const fetchData = async () => {
        await axios({
          url: apiLinks.reportApi + moduleName + "/" + email + "/cg",
          method: "get",
        })
          .then((res) => {
            // console.log(res.data);

            //no previous case or connection error
            if (!res.data) {
              setIsError(true);
              setToDefault();
              setNewPrev("");
              return;
            }

            setModuleId(res.data.id);
            setNftName(res.data.name);
            setEvalSectionVisible(true);
            setDbData(res.data);
            setSearchingNFT(false);
            setIsError(false);
            setSectionCompleted(new Array(NFTEvalList.length).fill(true));
          })
          .catch((err) => {
            console.log(err);
            setNewPrev("");
            setToDefault();
            setIsError(true);
          });
      };

      fetchData();
    } else {
      setNewPrev("");
    }
  }, [newPrev, setNewPrev]);

  useEffect(() => {
    if (sectionCompleted.every((element) => checkTrue(element))) {
      window.sessionStorage.setItem(moduleName, true);
    }
    //this is to ensure new analysis loaded after loading a prev analysis does not cause answers to be loaded in results prematurally
    window.sessionStorage.setItem(moduleName, false);
  }, [sectionCompleted, setSectionCompleted]);

  return (
    <div className="container mx-auto md:pb-64 pb-28">
      <NFTEvalHeader />
      <div className="pt-12"></div>

      {isError ? (
        <div className="pt-10">
          <div className="text-red-700">
            Error... Please start a new evaluation!
          </div>
          <br />
        </div>
      ) : (
        <></>
      )}

      {newPrev === "" || isError ? (
        <Page
          newPrev={newPrev}
          setNewPrev={setNewPrev}
          setIsError={setIsError}
        />
      ) : (
        <>
          <NFTTitle
            nftName={nftName}
            setNftName={setNftName}
            evalSectionVisible={evalSectionVisible}
            setEvalSectionVisible={setEvalSectionVisible}
            searchingNFT={searchingNFT}
            setSearchingNFT={setSearchingNFT}
            imgStr={imgStr}
            setImgStr={setImgStr}
          />
          <div
            className={`fixed top-50 left-8  ${
              open ? "xl:left-1/4" : "xl:left-1/5"
            }`}
          >
            <div>{imgStr && <NFTPic imgStr={imgStr} />}</div>

            {/** temp remove - twitter changed api so this is not working, may change to matradon */}
            {/* <div className="pt-10">
              {imgStr && <TwitterModal nftName={nftName} imgStr={imgStr} />}
            </div> */}
          </div>

          <div className="grid grid-cols-6">
            <div className="col-span-1"></div>
            <div className="col-span-5">
              {evalSectionVisible && !searchingNFT && imgStr != "" ? (
                <TransitionGroup>
                  {allData &&
                    allData.map((data, index) => {
                      return (
                        <>
                          <NFTEvalSection
                            key={index}
                            data={data}
                            sectionIndex={index}
                            allAns={allAns}
                            setAllAns={setAllAns}
                            moduleName={moduleName}
                            sectionCompleted={sectionCompleted}
                            setSectionCompleted={setSectionCompleted}
                            dbData={dbData}
                            moduleId={moduleId}
                            setModuleId={setModuleId}
                            nftName={nftName}
                            email={email}
                          />{" "}
                          {/* {data.sectionName} */}
                          <br />
                        </>
                      );
                    })}
                </TransitionGroup>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default NFTEval;
