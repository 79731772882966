import React, { useState, useEffect } from "react";

import { TransitionGroup } from "react-transition-group";

import LoginLogoAndTopNav from "../Login/LoginLogoAndTopNav";
import CoinDesk from "../../assets/images/coindesk.png";
import { ConsultingPageInfo } from "./ConsultingPageInfo";
import CollapsiblePanel from "./CollapsiblePanel";

const ConsultingPage = (props) => {
  const [allData, setAllData] = useState(ConsultingPageInfo);
  // console.log(ConsultingPageInfo);
  // console.log(allData);

  return (
    <div className="dark:bg-hideappbg dark:text-lightbg light:bg-lightbg light:text-darkbg pb-16 overflow-y-auto">
      <LoginLogoAndTopNav />
      <h1 className="text-center font-bold text-3xl py-7">
        Consulting Services
      </h1>
      <div className="text-lg grid grid-cols-8 gap-4 text-justify">
        <>
          <div className="col-span-1"></div>
          <div className="col-span-6">
            <h3 className="font-bold text-xl pb-2">Overview</h3>
            Our consulting service, Digital Asset Investment Advisory,
            specializes in providing comprehensive due diligence and investment
            advisory services for individuals and institutional investors
            looking to navigate the dynamic world of digital assets, including
            cryptocurrencies, tokens, and non-fungible tokens (NFTs).
          </div>
          <div className="col-span-1"></div>
        </>

        <>
          <div className="col-span-1"></div>
          <div className="col-span-6">
            <TransitionGroup>
              {allData &&
                allData.map((data, index) => {
                  return (
                    <div className="py-4">
                      <CollapsiblePanel key={index} data={data} />
                      <br />
                    </div>
                  );
                })}
            </TransitionGroup>
          </div>
          <div className="col-span-1"></div>{" "}
        </>

        <>
          <div className="col-span-1"></div>
          <div className="col-span-6">
            Our consulting service leverages cutting-edge technologies,
            including artificial intelligence, blockchain analysis, and
            real-time data feeds, to provide accurate and reliable insights. We
            work closely with our clients to understand their unique
            requirements and tailor our services accordingly, ensuring they make
            informed investment decisions and stay ahead of the curve in the
            rapidly evolving digital asset landscape.
          </div>
          <div className="col-span-1"></div>
        </>

        <>
          <div className="col-span-1"></div>
          <div className="col-span-6">
            By partnering with DYOR, clients gain access to a comprehensive
            suite of services designed to unlock hidden opportunities, mitigate
            risks, and maximize returns in the dynamic world of digital assets.
          </div>
          <div className="col-span-1"></div>
        </>
      </div>

      <div className="pt-16 pb-16"></div>

      <div className="flex flex-col text-center justify-items-center items-center">
        <h2 className="font-bold text-2xl pb-2">Press</h2>
        <div className="grid grid-cols-3 place-content-center place-items-center">
          <div className="col-span-1"></div>
          <div className="col-span-1 py-8 md:w-1/3">
            <a
              href={
                "https://www.coindesk.com/markets/2024/04/24/renzo-restaked-eth-suffers-a-brief-crash-on-uniswap/"
              }
              target="_blank"
              rel="noreferrer"
            >
              <img src={CoinDesk} alt="Coindesk" className="md:bg-contain" />
            </a>
          </div>
        </div>
        <div className="col-span-1"></div>
      </div>
    </div>
  );
};

export default ConsultingPage;
