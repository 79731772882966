import React, { useState, useEffect } from "react";

const CoinEvalSectionResult = (props) => {
  const { sectionName, allQns, coinEvalSectionName } = props;
  const [sectionAns, setSectionAns] = useState([]);

  useEffect(() => {
    //   allQns.forEach((element) => {
    //     console.log(Object.keys(element));
    //   });

    var temp = window.sessionStorage.getItem(
      coinEvalSectionName + " - " + sectionName
    );
    if (temp) {
      var arr = temp.split(",");
      setSectionAns(arr);
    }
  }, []);

  return (
    <div className="text-xl text-left pt-6">
      {sectionAns && (
        <>
          <div className="font-bold underline">{sectionName} : </div>
          <br />
          {allQns.map((element, index) => {
            return (
              <>
                <span>{Object.values(element)} : </span>
                <span className="italic text-orangebtn">
                  {sectionAns[index] === "true" ? "yes" : "no"}
                </span>
                <br />
              </>
            );
          })}
          <br />
          <br />
        </>
      )}
    </div>
  );
};

export default CoinEvalSectionResult;
