import React, { useState, useEffect } from "react";
import axios from "axios";

import CryptoNewsHeader from "./CryptoNewsHeader";
import { apiLinks } from "../../utils/links";
import Questions from "../Questions/Questions";
import NewsCard from "./NewsCard";

const CryptoNews = (props) => {
  const [isLoadingEvent, setIsLoadingEvent] = useState(false);
  const [newsDetails, setNewsDetails] = useState([]);
  const [error, setError] = useState(false);
  const [moduleName, setModuleName] = useState("cryptonews");

  useEffect(() => {
    setIsLoadingEvent(true);

    axios({
      url: apiLinks.cryptoNews,
      method: "get",
    })
      .then((res) => {
        setIsLoadingEvent(false);
        setError(false);
        console.log(res.data.result);
        setNewsDetails(res.data.result);
      })
      .catch((err) => {
        // console.log(err);
        setError(true);
      });
  }, []);

  return (
    <div className="container mx-auto md:pb-64 pb-28">
      <CryptoNewsHeader />
      <br />

      {isLoadingEvent && (
        <h3 className="text-center text-3xl flex justify-center pt-5">
          Getting your crypto news feed...
        </h3>
      )}

      {error ? (
        <h3 className="text-center text-3xl flex justify-center pt-5">
          Loading error! Please wait for 2 minutes before trying again.
        </h3>
      ) : null}

      {!isLoadingEvent && !error && newsDetails ? (
        <>
          <br />
          <div className="items-center justify-center w-full px-4">
            <div className="grid grid-cols-1 lg:grid-cols-4 lg:gap-4">
              {newsDetails.map((newsDetail, index) => {
                return (
                  <NewsCard key={index} newsDetail={newsDetail} index={index} />
                );
                // console.log(eventDetail);
              })}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      <br />
      <br />
      <Questions moduleName={moduleName} />
    </div>
  );
};

export default CryptoNews;
