import React, { useState, useEffect } from "react";
import Linkify from "linkify-react";

const Textbox = (props) => {
  const { data, index, dataArr, setDataArr } = props;
  const [text, setText] = useState(dataArr[index]);

  const handleText = (e) => {
    setText(e.target.value);

    var temp = dataArr;
    temp[index] = e.target.value;
    setDataArr(temp);
  };

  return (
    <Linkify
      tagName="div"
      className="block pb-4"
      options={{
        rel: "noreferral",
        target: "_blank",
        className: "text-linkifylink hover:text-linkifylink-hover",
      }}
    >
      <div className="grid grid-rows-4 text-lg pt-10 flex items-center">
        <label className="row-span-2 text-xl text-justified pb-3 whitespace-pre-line">
          {data.qn}
        </label>
        <input
          className="rounded row-span-2 block flex pt-2 overflow-scroll dark:bg-slategrey bg-lightbg border"
          type="text"
          name={Object.keys(data)}
          value={text}
          onChange={(e) => {
            handleText(e);
          }}
        />
      </div>
    </Linkify>
  );
};

export default Textbox;
