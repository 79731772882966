import React, { useState, useEffect } from "react";
import RewardsHeader from "./RewardsHeader";
import comingsoon from "../../assets/images/comingsoon.png";

const Rewards = (props) => {
  return (
    <div className="container mx-auto">
      {/* <RewardsHeader /> */}
      <div className="pt-10">
        <div className="bg-comingsoonbg bg-center bg-cover bg-no-repeat min-h-screen flex justify-center items-center">
          <span className="text-9xl">Coming Soon</span>
        </div>
      </div>
    </div>
  );
};

export default Rewards;
