import React, { useState, useEffect } from "react";
import Linkify from "linkify-react";

const MultiCheckbox = (props) => {
  const { data, index, dataArr, setDataArr } = props;
  const [isChecked, setIsChecked] = useState([]);

  useEffect(() => {
    if (dataArr[index] !== "") {
      var tempArr = dataArr[index].split(",");
      var arr = [];

      tempArr.forEach((element) => {
        element = element === "true";
        arr.push(element);
      });

      setIsChecked(arr);
      return;
    }

    setIsChecked(new Array(data.options.length).fill(false));
  }, []);

  // useEffect(() => {
  //   if (dataArr[index].length !== data.options.length) {
  //     setIsChecked(new Array(data.options.length).fill(false));
  //   }
  // }, []);

  const handleCheck = (i) => {
    //freecodecamp eg.
    // const updatedCheckedState = isChecked.map((item, index) =>
    //   index === i ? !item : item
    // );

    // setIsChecked(updatedCheckedState);

    //1st tick not detected
    var temp = [...isChecked];
    var tempDataArr = dataArr;
    var tempStr = "";

    temp[i] = !isChecked[i];

    temp.forEach((e, index) => {
      tempStr += e.toString();
      if (index < temp.length - 1) {
        tempStr += ",";
      }
    });

    setIsChecked(temp);

    tempDataArr[index] = tempStr;
    setDataArr(tempDataArr);
  };

  return (
    <Linkify
      tagName="div"
      className="block pb-4 pt-10 flex items-center"
      options={{
        rel: "noreferral",
        target: "_blank",
        className: "text-linkifylink hover:text-linkifylink-hover",
      }}
    >
      <div className="grid grid-rows-2 text-base pt-10 flex items-center">
        <label className="row-span-1 text-xl">{data.qn}</label>
        {data.options &&
          data.options.map((op, i) => {
            return Object.prototype.toString.call(op) === "[object Object]" ? (
              <>
                <div className="grid grid-cols-8 text-base pt-10 flex items-center row-span-1">
                  <label className="pr-4 col-span-6 text-justified whitespace-pre-line">
                    <a
                      href={Object.values(op)}
                      target="_blank"
                      rel="noreferral"
                      className="text-linkifylink hover:text-linkifylink-hover"
                    >
                      {Object.keys(op)}
                    </a>
                  </label>
                  <div className="col-span-1"></div>
                  <input
                    className="w-8 h-8 rounded col-span-1"
                    type="checkbox"
                    name={Object.keys(op)}
                    defaultChecked={isChecked[i]}
                    value={isChecked[i]}
                    onClick={() => {
                      handleCheck(i);
                    }}
                  />
                </div>
              </>
            ) : (
              <div className="grid grid-cols-8 text-base pt-10 flex items-center row-span-1">
                <label className="pr-4 col-span-6 text-justified whitespace-pre-line text-xl">
                  {op}
                </label>
                <div className="col-span-1"></div>
                <input
                  className="w-8 h-8 rounded col-span-1"
                  type="checkbox"
                  name={Object.keys(op)}
                  defaultChecked={isChecked[i]}
                  value={isChecked[i]}
                  onClick={() => {
                    handleCheck(i);
                  }}
                />
              </div>
            );
          })}
      </div>
    </Linkify>
  );
};

export default MultiCheckbox;
