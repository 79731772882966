import React, { useState, useEffect } from "react";

import { CSSTransition } from "react-transition-group";
import { HiChevronDoubleDown, HiChevronDoubleUp } from "react-icons/hi";

import PictureComponent from "./PictureComponent";

import "../CoinEval/CollapsiblePanel.css";

const CollapsiblePanel = (props) => {
  const { data, index } = props;

  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="whitespace-pre-line">
      <div>
        <button
          type="button"
          onClick={() => {
            setIsVisible(!isVisible);
          }}
          className="text-3xl"
        >
          {data.sectionName}
          {isVisible ? (
            <HiChevronDoubleUp className="pl-4 pt-2 float-right" />
          ) : (
            <HiChevronDoubleDown className="pl-4 pt-2 float-right" />
          )}
        </button>
      </div>
      <CSSTransition in={!isVisible} timeout={0} classNames="test">
        {!isVisible ? (
          <></>
        ) : (
          <>
            <div className="text-lg px-4 pt-4 grid grid-cols-5 text-left">
              <div className="col-span-1"></div>
              <div className="col-span-3 text-xl">{data.description}</div>
              <div className="col-span-1"></div>
            </div>
            <div className="grid grid-cols-5 pt-2">
              <div className="col-span-1"></div>
              <div className="col-span-3">
                {data.subSectionName &&
                  data.subSectionName.map((subName, i) => (
                    <div key={data.subSectionPics[i]}>
                      <div className="text-xl font-bold">{subName}</div>
                      <div className="text-lg text-left">
                        {data.subSectionText[i]}
                      </div>
                      <PictureComponent imglink={data.subSectionPics[i]} />
                      <div className="py-8">
                        <hr />
                      </div>
                    </div>
                  ))}
              </div>
              <div className="col-span-1"></div>
            </div>
          </>
        )}
      </CSSTransition>
    </div>
  );
};

export default CollapsiblePanel;
