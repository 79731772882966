import React, { useState, useEffect } from "react";

const Box = (props) => {
  const { transaction, type } = props;

  return (
    <div className="border-4 dark:border-gray-50 border-gray-600 dark:bg-gray-700 bg-gray-100 rounded-lg max-w-min">
      {type == "from" ? (
        <>
          <p className="p-2 text-left text-xs md:p-5 md:text-lg font-bold">
            From :
          </p>

          <p className="p-2 text-left text-xs md:p-5 md:text-lg overflow-hidden truncate">
            Wallet Address: {transaction.from.address} <br />
            {transaction.from.owner == "" ? (
              <>Owner: {transaction.from.owner}</>
            ) : (
              <>Owner: unknown</>
            )}
            <br />
            Owner Type: {transaction.from.owner_type}
          </p>
        </>
      ) : (
        <>
          <p className="p-2 text-left text-xs md:p-5 md:text-lg font-bold">
            To :{" "}
          </p>
          <p className="p-2 text-left text-xs md:pt-5 md:pb-10 md:text-lg overflow-hidden truncate">
            Wallet Address: {transaction.to.address} <br />
            Owner Type: {transaction.to.owner_type}
          </p>
        </>
      )}
    </div>
  );
};

export default Box;
