import React, { useState, useEffect } from "react";

import { HelpInfo } from "./HelpInfo";
import CollapsiblePanel from "./CollapsiblePanel";

const HelpTable = (props) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(HelpInfo);
  }, []);

  return (
    <div className="pt-8">
      {tableData &&
        tableData.map((dat, index) => (
          <div className="pb-4">
            <CollapsiblePanel key={index} data={dat} index={index} />
          </div>
        ))}
    </div>
  );
};

export default HelpTable;
