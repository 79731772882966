export const coins = [
  { name: "ada", full: "cardano", text: "Cardano", id: 2010 },
  { name: "bnb", full: "binancecoin", text: "Binance Coin", id: 1839 },
  { name: "btc", full: "bitcoin", text: "Bitcoin", id: 1 },
  { name: "dai", full: "dai", text: "Dai", id: 2308 },
  { name: "dot", full: "polkadot", text: "Polkadot", id: 11517 },
  { name: "eth", full: "ethereum", text: "Ethereum", id: 1027 },
  { name: "sol", full: "solana", text: "Solana", id: 11733 },
  { name: "sushi", full: "sushi", text: "Sushi", id: 11794 },
  { name: "uni", full: "uniswap", text: "Uniswap", id: 11968 },
  { name: "xmr", full: "monero", text: "Monero", id: 328 },
];
