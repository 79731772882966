import React, { useState, useEffect } from "react";

import { TransitionGroup } from "react-transition-group";
import axios from "axios";

import { CoinEvaluationInfo } from "./CoinEvaluationInfo";
import { apiLinks } from "../../utils/links";

import CoinEvaluationHeader from "./CoinEvaluationHeader";
import CoinName from "./CoinName";
import CollapsiblePanel from "./CollapsiblePanel";
import TwitterModal from "../TwitterModal/TwitterModal";
import CoinInfoPanel from "./CoinInfoPanel";
import Page from "../NextPrevButtons/Page";

const CoinEvaluation = (props) => {
  const { open } = props;

  const [newPrev, setNewPrev] = useState("");
  const [email, setEmail] = useState(window.sessionStorage.getItem("email"));
  const [allData, setAllData] = useState([]);
  const [sectionCompleted, setSectionCompleted] = useState(
    new Array(CoinEvaluationInfo.length).fill(false)
  );
  const [coinName, setCoinName] = useState("");
  const [searchingCoin, setSearchingCoin] = useState(false);
  const [evalSectionVisible, setEvalSectionVisible] = useState(false);
  const [searchedCoinName, setSearchedCoinName] = useState("");
  const [moduleName, setModuleName] = useState("coineval");
  const [moduleId, setModuleId] = useState("");
  const [isError, setIsError] = useState(false);
  const [dbData, setDbData] = useState("");

  function checkTrue(element) {
    return element === true;
  }

  const setToDefault = () => {
    setSectionCompleted(new Array(CoinEvaluationInfo.length).fill(false));
    setCoinName("");
    setSearchingCoin(false);
    setEvalSectionVisible(false);
    setSearchedCoinName("");
    setDbData("");
    setModuleId("");
  };

  useEffect(() => {
    setAllData(CoinEvaluationInfo);
    // setSectionCompleted(new Array(CoinEvaluationInfo.length).fill(false));
  }, []);

  useEffect(() => {
    if (sectionCompleted.every((element) => checkTrue(element))) {
      window.sessionStorage.setItem(moduleName, true);
      return;
    }
    //this is to ensure new analysis loaded after loading a prev analysis does not cause answers to be loaded in results prematurally
    window.sessionStorage.setItem(moduleName, false);
  }, [sectionCompleted, setSectionCompleted]);

  useEffect(() => {
    //set everything to default
    if (newPrev === "new") {
      setToDefault();
    } else if (newPrev === "prev") {
      // var urlLink = apiLinks.reportApi + moduleName + "/" + email + "/cg";
      setSearchingCoin(true);

      const fetchData = async () => {
        await axios({
          url: apiLinks.reportApi + moduleName + "/" + email + "/cg",
          method: "get",
        })
          .then((res) => {
            // console.log(res.data);

            //no previous case or connection error
            if (!res.data) {
              setIsError(true);
              setToDefault();
              setNewPrev("");
              setSearchingCoin(false);
              return;
            }

            setModuleId(res.data.id);
            setCoinName(res.data.name);
            setSearchedCoinName(res.data.name);
            setEvalSectionVisible(true);
            setDbData(res.data);
            setSearchingCoin(false);
            setIsError(false);
            setSectionCompleted(
              new Array(CoinEvaluationInfo.length).fill(true)
            );
          })
          .catch((err) => {
            console.log(err);
            setSearchingCoin(false);
            setNewPrev("");
            setToDefault();
            setIsError(true);
          });
      };

      fetchData();
    } else {
      setNewPrev("");
    }
  }, [newPrev, setNewPrev]);

  // useEffect(() => {
  //   window.sessionStorage.setItem("CoinName", searchedCoinName);
  // }, [searchedCoinName, setSearchedCoinName]);

  return (
    <div className="container mx-auto md:pb-64 pb-28">
      <CoinEvaluationHeader />

      {isError ? (
        <div className="pt-10">
          <div className="text-red-700">
            Error... Please start a new evaluation!
          </div>
          <br />
        </div>
      ) : (
        <></>
      )}

      {newPrev === "" || isError ? (
        <Page
          newPrev={newPrev}
          setNewPrev={setNewPrev}
          setIsError={setIsError}
        />
      ) : (
        <>
          <CoinName
            coinName={coinName}
            setCoinName={setCoinName}
            evalSectionVisible={evalSectionVisible}
            setEvalSectionVisible={setEvalSectionVisible}
            searchedCoinName={searchedCoinName}
            setSearchedCoinName={setSearchedCoinName}
            searchingCoin={searchingCoin}
          />

          {evalSectionVisible && searchedCoinName && (
            <>
              {searchingCoin ? (
                <>
                  <div className="block">Loading...</div>
                </>
              ) : (
                <>
                  <div
                    className={`hidden lg:block fixed top-50 left-8  ${
                      open ? "xl:left-1/4" : "xl:left-1/5"
                    }`}
                  >
                    <CoinInfoPanel searchedCoinName={searchedCoinName} />
                    {/* <div className="pt-10">
              <TwitterModal
                nftName={searchedCoinName}
                imgStr={searchedCoinName}
              />
            </div> */}
                  </div>

                  <div className="lg:hidden">
                    <CoinInfoPanel searchedCoinName={searchedCoinName} />
                  </div>

                  <div className="grid lg:grid-cols-8">
                    <div className="lg:col-span-2"></div>
                    <div className="lg:col-span-6">
                      <TransitionGroup>
                        {allData &&
                          allData.map((data, index) => {
                            return (
                              <>
                                <CollapsiblePanel
                                  key={index}
                                  data={data}
                                  index={index}
                                  sectionCompleted={sectionCompleted}
                                  setSectionCompleted={setSectionCompleted}
                                  searchedCoinName={searchedCoinName}
                                  moduleName={moduleName}
                                  dbData={dbData}
                                  moduleId={moduleId}
                                  setModuleId={setModuleId}
                                  email={email}
                                />{" "}
                                {/* {data.sectionName} */}
                                <br />
                              </>
                            );
                          })}
                      </TransitionGroup>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {/* <Results results={results} coinName={coinName} /> */}
          <br />
        </>
      )}
    </div>
  );
};

export default CoinEvaluation;
