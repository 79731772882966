import React, { useState, useEffect } from "react";

import axios from "axios";

import MultiCheckbox from "./MultiCheckbox";
import SectionCompletedCoins from "../SectionCompletedCoins/SectionCompletedCoins";
import { apiLinks } from "../../utils/links";

//All single checkbox must be BELOW multicheckboxes to prevent count error

const QnChecklist = (props) => {
  const { data, moduleName, moduleId, setModuleId } = props;
  const [email, setEmail] = useState(window.sessionStorage.getItem("email"));
  // const [isChecked, setIsChecked] = useState(
  //   new Array(data.qns.length).fill(false)
  // );
  const [isChecked, setIsChecked] = useState("");
  const [isSubmitPressed, setIsSubmitPressed] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  var count = 0;
  var multiCheckboxCount = -1;

  useEffect(() => {
    var ansData = window.sessionStorage.getItem(moduleName + " tobuy");

    if (ansData) {
      var temp = ansData.split(",");
      setIsChecked(Array.from(temp, (element) => element === "true"));
    } else {
      var qnCount = 0;
      data.qns.forEach((qn) => {
        if (qn.options) {
          qnCount += qn.options.length - 1;
        }
        qnCount += 1;
      });
      setIsChecked(new Array(qnCount).fill(false));
    }
  }, []);

  const handleCheck = (value, index) => {
    var temp = isChecked;
    temp[index] = !isChecked[index];
    // console.log(index);
    setIsChecked(temp);
  };

  const handleSubmit = async (e, sectionName) => {
    var tempId = "";
    var urlLink = "";

    e.preventDefault();
    setIsError(false);
    setIsSubmitPressed(false);
    setIsLoading(true);

    if (moduleId === "") {
      //create, does not exist
      urlLink = apiLinks.reportApi + moduleName;
    } else {
      //edit, exists
      urlLink =
        apiLinks.reportApi + moduleName + "/" + email + "/cg/" + moduleId;
    }

    await axios({
      url: urlLink,
      method: "post",
      data: {
        email: email,
        bull_52d: isChecked[0],
        bull_128d: isChecked[1],
        bull_200d: isChecked[2],
        macd_zero: isChecked[3],
        rsi_30: isChecked[4],
      },
    })
      .then((res) => {
        // console.log(res.data);
        setIsLoading(false);

        if (!res.data) {
          console.log(res);
          setIsError(true);
          return;
        }

        tempId = res.data.id;
      })
      .catch((err) => {
        console.log(err);

        setIsLoading(false);
        setIsError(true);
      });

    window.sessionStorage.setItem(sectionName, true);
    window.sessionStorage.setItem(sectionName + " tobuy", isChecked);
    window.sessionStorage.setItem(moduleName + "id", tempId);

    setIsSubmitPressed(true);
  };

  return (
    <div className="flex flex-col">
      <div className="items-center grid grid-cols-4 text-lg pt-5">
        <div className="col-span-1"></div>
        <table className="table-auto border-2 border-gray-400 text-justify col-span-2">
          <tbody>
            {data.qns &&
              isChecked &&
              data.qns.map((d, index) => {
                <>
                  {data.qns[index].options
                    ? ((multiCheckboxCount += 1),
                      (count += data.qns[index].options.length))
                    : (count += 0)}
                </>;
                return (
                  <tr key={index}>
                    <td className="border-2 border-gray-400 md:text-md text-sm px-2">
                      {data.qns[index].qn}
                    </td>

                    {data.qns[index].options && (
                      <MultiCheckbox
                        data={data.qns[index].options}
                        index={index}
                        isChecked={isChecked}
                        setIsChecked={setIsChecked}
                        count={count}
                      />
                    )}

                    {!data.qns[index].options && (
                      <td className="border-2 border-gray-400 text-center">
                        <input
                          type="checkbox"
                          name={Object.keys(d)}
                          defaultChecked={
                            isChecked[index + count - multiCheckboxCount - 1]
                          }
                          value={
                            isChecked[index + count - multiCheckboxCount - 1]
                          }
                          onChange={() => {
                            handleCheck(
                              Object.values(d),
                              index + count - multiCheckboxCount - 1
                            );
                          }}
                        />
                      </td>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className="col-span-1"></div>
      </div>

      <div className="items-center grid grid-cols-4 text-lg pt-5">
        <div className="col-span-1"></div>
        <div className="pt-2 col-span-2">
          <input
            type="submit"
            value="Save"
            disabled={isLoading}
            onClick={(e) => {
              handleSubmit(e, moduleName);
            }}
            className="bg-buttonlink hover:bg-buttonlink-hover text-white py-2 px-4 rounded-xl text-base cursor-pointer disabled:cursor-wait"
          />

          {isSubmitPressed ? (
            <>
              <div className="pt-2 text-green-700 text-base">Saved!</div>
            </>
          ) : (
            <></>
          )}
        </div>

        {isError ? (
          <>
            <div className="pt-2 text-green-700 text-base">
              Error saving... Please try again!
            </div>
            <br />
          </>
        ) : (
          <></>
        )}

        <div className="col-span-1"></div>
      </div>

      {isSubmitPressed && (
        <SectionCompletedCoins
          isSubmitPressed={isSubmitPressed}
          moduleName={moduleName}
          email={email}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default QnChecklist;
