import React, { useState, useEffect } from "react";

import ReactModal from "react-modal";
import axios from "axios";

import { apiLinks } from "../../utils/links";

const DisclaimerModal = (props) => {
  const { agreed, setAgreed, email } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const agreementText =
    "The use of the suite of tools provided on this program is for educational purposes only and is not intended to provide financial advice.\n\nThe information contained within the tools is not guaranteed to be accurate, complete or current, and should not be relied upon as such. \n\nThe information contained within the tools is not a substitute for professional financial advice and should not be relied upon as such. \n\nNo responsibility is taken for any actions taken based on the information contained within the tools. Please seek professional financial advice before making any investment or financial decisions.";

  const handleAgree = async () => {
    setIsLoading(true);

    await axios({
      url: apiLinks.reportApi + "disclaimer/" + email,
      method: "put",
    })
      .then((res) => {
        setIsLoading(false);

        if (!res.data) {
          setIsError(true);
        }

        setIsError(false);
        setAgreed(res.data.disclaimer_agreed);
        window.sessionStorage.setItem("hasAgreed", res.data.disclaimer_agreed);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setIsError(true);
      });
  };

  return (
    <>
      {agreed === "false" && (
        <ReactModal
          isOpen={agreed === "false"}
          contentLabel={"Coin Gryphon Disclaimer"}
          className="outline-none focus:outline-none text-darkbg dark:text-lightbg w-2/5 h-2/3 relative top-17 left-1/3 overflow-y-auto bg-darkbg p-4 rounded flex-auto"
        >
          <div className="text-center text-xl lg:text-5xl pb-8 font-black">
            Important
          </div>
          <div className="text-center text-base lg:text-lg pb-8 font-bold">
            You agree to the following terms to use this program.
          </div>
          <div className="text-justify whitespace-pre-line text-base px-5">
            {agreementText}
          </div>
          <div className="pt-20"></div>
          <div className="flex flex-col justify-center items-center">
            <button
              className="bg-orangebtn-hover hover:bg-orangebtn text-white py-2 px-4 rounded-xl cursor-pointer disabled:cursor-wait text-center self-center justify-self-center cursor-pointer disabled:cursor-wait"
              onClick={handleAgree}
              disabled={isLoading}
            >
              I have read and agreed to the above terms.
            </button>
          </div>

          {isError && (
            <div className="flex flex-col justify-center items-center">
              <div>
                <h1 className="md:flex md:items-center pt-4 text-red-500 text-base text-center">
                  Error! Please try again later.
                </h1>
              </div>
            </div>
          )}
        </ReactModal>
      )}
    </>
  );
};

export default DisclaimerModal;
