import { BrowserRouter, Switch, Route } from "react-router-dom";
import React, { useState } from "react";
import { Helmet } from "react-helmet";

import logo from "./logo.svg";
import "./App.css";
import "./components/CustomIcon.css";
// import { FaTwitter } from "react-icons/fa";

import AuthRoute from "./components/routes/AuthRoute";

function App() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>DYOR Crypto - Future Center Ventures</title>
        <link rel="canonical" href="https://dyor.futurecenter.ventures" />
        <meta
          name="description"
          content="Dive into Digital Asset Insights"
        />
      </Helmet>
      <AuthRoute />
    </>
  );
  //       {/* <header className="App-header"> */}
  //       {/* <FaTwitter /> */}
  //       {/* <WhaleMovement /> */}
  //       {/* <CryptoCalendar /> */}
  //       {/* </header> */}
}

export default App;
