import React, { useEffect, useState } from "react";
import { Route, Link, BrowserRouter, Routes } from "react-router-dom";
import "../../App.css";

import { useMoralis } from "react-moralis";

import LeftNavbar from "../LeftNavbar/LeftNavbar";

import Main from "../Moralis/Main";
import CoinEvaluation from "../CoinEval/CoinEvaluation";
import SwingTrading from "../SwingTrading/SwingTrading";
import RocketMovement from "../RocketMovement/RocketMovement";
// import CryptoCalendar2 from "../CryptoCalendar/CryptoCalendar2";
import AllCoinsCryptoCalendar from "../CryptoCalendar/AllCoinsCryptoCalendar";
import TechnicalAnalysis from "../TechnicalAnalysis/TechnicalAnalysis";
import Airdrops from "../Airdrops/Airdrops";
import CryptoNews from "../CryptoNews/CryptoNews";
import NFTEval from "../NFTEval/NFTEval";
import ExchangeEvaluation from "../ExchangeEvaluation/ExchangeEvaluation";
import Results from "../Results/Results";
import ErrorPage from "../ErrorPage";
import DailyCheckIn from "../DailyCheckIn/DailyCheckIn";
import Rewards from "../Rewards/Rewards";
import Account from "../Account/Account";
import Tutorial from "../Tutorial/Tutorial";
import Help from "../Help/Help";
import Start from "../Start";
import Privacy from "../LegalPages/Privacy";
import TnC from "../LegalPages/TnC";
import ProgressBar from "../ProgressBar/ProgressBar";
import TopNavbar from "../TopNavbar/TopNavbar";
import TopNavSkele from "../TopNavbar/TopNavSkele";
import Footer from "../Footer/Footer";

const MainRoute = (props) => {
  const totalModules = 3;
  // const { isAuthenticated } = useMoralis();
  const isAuthenticated = true;

  const [open, setOpen] = useState(true);
  const [email, setEmail] = useState(window.sessionStorage.getItem("email"));

  return (
    <>
      {isAuthenticated && email && (
        <div className="App">
          <TopNavSkele children={<TopNavbar />} />
          <LeftNavbar open={open} setOpen={setOpen} />
          <div
            className={`flex flex-col text-3xl h-full justify-center items-center App-move dark:bg-darkbg dark:text-lightbg light:bg-lightbg light:text-darkbg ${
              open ? "w-4/5 float-right overflow-x-scroll" : "w-full"
            }`}
          >
            <Routes className="font-euclidreg">
              <Route path="/index" element={<Start />} />
              <Route
                path="/coineval"
                element={<CoinEvaluation open={open} email={email} />}
              />
              <Route
                path="/technicalanalysis"
                element={<TechnicalAnalysis />}
              />
              <Route path="/cryptocal" element={<AllCoinsCryptoCalendar />} />
              {/* <Route path="/fng" element={<Feargreed />} /> */}
              <Route path="/news" element={<CryptoNews />} />
              <Route path="/walletmovement" element={<RocketMovement />} />
              <Route path="/airdrops" element={<Airdrops />} />
              <Route
                path="/nfteval"
                element={<NFTEval open={open} email={email} />}
              />
              <Route path="/exchanges" element={<ExchangeEvaluation />} />
              <Route path="/results" element={<Results />} />
              <Route path="/daily" element={<DailyCheckIn email={email} />} />
              <Route path="/rewards" element={<Rewards />} />
              <Route path="/account" element={<Account email={email} />} />
              <Route path="/tutorial" element={<Tutorial />} />
              <Route path="/help" element={<Help />} />
              <Route path="/terms" element={<TnC />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="*" element={<Start />} />
              {/* <Route path="/results">
              <Profile />
            </Route> */}
            </Routes>
            {/* <ProgressBar /> */}
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default MainRoute;
