import React, { useState, useEffect } from "react";

import { rocketcoins } from "./rocketcoins";
import CryptoButton from "./CryptoButton";

import "./RocketMovement.css";

const CryptoScoller = (props) => {
  const { setCoinName, setFullCoinName, setClickAway } = props;
  return (
    <div className="h-30 flex flex-shrink overflow-auto md:my-6 xl:mx-60 mx-6">
      {rocketcoins.map((coin) => {
        return (
          <CryptoButton
            key={coin.name}
            coin={coin.name}
            fullName={coin.text}
            setFullCoinName={setFullCoinName}
            setCoinName={setCoinName}
            setClickAway={setClickAway}
            disabled={false}
          />
        );
      })}
    </div>
  );
};

export default CryptoScoller;
