import React, { useState, useEffect } from "react";

import Coins from "../../assets/images/Coin/daily.png";

const TotalCoins = (props) => {
  const { coins, setCoins } = props;

  return (
    <div className="grid flex">
      <div className="bg-lighterbuttonlink rounded-lg overflow-hidden self-center justify-self-center pl-4 md:w-1/4 w-3/5">
        <div className="grid grid-cols-3 items-center">
          <div className="col-span-1">
            <span className="text-sm">My Coins: </span>
            <p className="text-md">{coins}</p>
          </div>

          <div className="col-span-2">
            <img src={Coins} alt="gryphon coins" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalCoins;
