import React from "react";
import ReactDOM from "react-dom";
// import "./index.css";
import "./main.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// import { MoralisProvider } from "react-moralis";

import { ThemeProvider } from "./components/Theme/ThemeContext";
import Background from "./components/Theme/Background";
import ThemeToggle from "./components/Theme/ThemeToggle";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <Background>
        <App />
      </Background>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
