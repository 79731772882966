import React, { useState, useEffect } from "react";

import AirdropsHeader from "./AirdropsHeader";
import AirdropsTable from "./AirdropsTable";

const Airdrops = (props) => {
  return (
    <div className="container mx-auto md:pb-64 pb-28">
      <AirdropsHeader />
      <AirdropsTable />
    </div>
  );
};

export default Airdrops;
