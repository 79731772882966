import React, { useState, useEffect } from "react";

import ReactModal from "react-modal";
import Linkify from "linkify-react";

const CoinEvalTeamMemberModal = (props) => {
  const { showModal, setShowModal, teamMemInfo } = props;

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel="Team Member"
      className="outline-none focus:outline-none text-darkbg dark:text-lightbg w-2/5 h-2/3 absolute top-1/4 left-1/3 overflow-y-auto"
    >
      <div className="bg-verylightpurple dark:bg-darkbg p-1 rounded pt-3">
        <button
          className="float-right pr-4 font-bold text-3xl sticky"
          onClick={toggleModal}
        >
          X
        </button>

        <div className="p-4">
          <div className="py-2 text-center font-bold text-lg">
            {teamMemInfo.name}
          </div>

          <div>
            <div>Name: {teamMemInfo.name}</div>
            <hr />
            <div>Projects involved: {teamMemInfo.teams_count}</div>
            <hr />
            <div>Bio: {teamMemInfo.description}</div>
            <hr />

            <Linkify
              tagName="div"
              options={{
                rel: "noreferral",
                target: "_blank",
                className: "text-linkifylink hover:text-linkifylink-hover",
              }}
            >
              Project Details:
              <br />
              {teamMemInfo.positions &&
                teamMemInfo.positions.map((pos, index) => {
                  return (
                    <>
                      <div key={index} data={pos}>
                        {index + 1}. {pos.position} of {pos.coin_name} (
                        {pos.coin_symbol})
                      </div>
                    </>
                  );
                })}
            </Linkify>
            <hr />

            <Linkify
              tagName="div"
              options={{
                rel: "noreferral",
                target: "_blank",
                className: "text-linkifylink hover:text-linkifylink-hover",
              }}
            >
              Github:
              <br />
              {teamMemInfo.links.github &&
                teamMemInfo.links.github.map((gh, index) => {
                  return (
                    <>
                      <div key={index} data={gh}>
                        {gh.url} - Followers: {gh.followers}
                      </div>
                    </>
                  );
                })}
            </Linkify>

            <Linkify
              tagName="div"
              options={{
                rel: "noreferral",
                target: "_blank",
                className: "text-linkifylink hover:text-linkifylink-hover",
              }}
            >
              Linkedin:
              <br />
              {teamMemInfo.links.linkedin &&
                teamMemInfo.links.linkedin.map((li, index) => {
                  return (
                    <>
                      <div key={index} data={li}>
                        {li.url}
                      </div>
                    </>
                  );
                })}
            </Linkify>

            <Linkify
              tagName="div"
              options={{
                rel: "noreferral",
                target: "_blank",
                className: "text-linkifylink hover:text-linkifylink-hover",
              }}
            >
              Twitter:
              <br />
              {teamMemInfo.links.twitter &&
                teamMemInfo.links.twitter.map((tw, index) => {
                  return (
                    <>
                      <div key={index} data={tw}>
                        {tw.url} - Followers: {tw.followers}
                      </div>
                    </>
                  );
                })}
            </Linkify>

            <Linkify
              tagName="div"
              options={{
                rel: "noreferral",
                target: "_blank",
                className: "text-linkifylink hover:text-linkifylink-hover",
              }}
            >
              Medium:
              <br />
              {teamMemInfo.links.medium &&
                teamMemInfo.links.medium.map((med, index) => {
                  return (
                    <>
                      <div key={index} data={med}>
                        {med.url} - Followers: {med.followers}
                      </div>
                    </>
                  );
                })}
            </Linkify>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default CoinEvalTeamMemberModal;
