import React, { useState, useEffect } from "react";

import { BsInfoCircle } from "react-icons/bs";
import Popup from "reactjs-popup";

const ExplainerVideo = (props) => {
  const { vidId, tooltipText } = props;

  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);

  var vidUrl = "https://www.youtube.com/embed/" + vidId;

  return (
    <span className="has-tooltip-top">
      <BsInfoCircle
        type="button"
        className="button inline cursor-pointer text-lg hover:scale-150"
        onClick={() => {
          setOpen((o) => !o);
          //   console.log(vidId);
        }}
      />

      <span className="tooltip-top rounded shadow-lg p-2 bg-gray-100 text-buttonlink -mt-16">
        {tooltipText}
      </span>

      <Popup open={open} closeOnDocumentClick onClose={closeModal}>
        <div className="sm:block hidden modal">
          <a
            className="close text-6xl text-darkbg dark:text-lightbg cursor-pointer"
            onClick={closeModal}
          >
            &times;
            <iframe width="600" height="400" src={vidUrl}></iframe>
          </a>
        </div>
        <div className="sm:hidden modal">
          <a
            className="close text-6xl text-darkbg dark:text-lightbg cursor-pointer"
            onClick={closeModal}
          >
            &times;
            <iframe width="300" height="200" src={vidUrl}></iframe>
          </a>
        </div>
      </Popup>
    </span>
  );
};

export default ExplainerVideo;
