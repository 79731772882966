import React, { useState, useEffect } from "react";

const TutorialHeader = (props) => {
  return (
    <div>
      <h1 className="text-center text-5xl flex justify-center pt-5">
        Tutorial
      </h1>
    </div>
  );
};

export default TutorialHeader;
