import React, { useState, useEffect } from "react";

import { coins } from "../../utils/coins";
import CryptoButton from "../FnG/CryptoButton";

import "./fng.css";

const CryptoScoller = (props) => {
  const { setCoinName, setCoinLink, setCoinId } = props;
  return (
    <div className="h-30 flex flex-shrink overflow-auto md:m-6">
      {coins.map((coin) => {
        return (
          <CryptoButton
            key={coin.name}
            coin={coin.name}
            coinlink={coin.full}
            coinlong={coin.text}
            coinid={coin.id}
            setCoinName={setCoinName}
            setCoinLink={setCoinLink}
            setCoinId={setCoinId}
            disabled={false}
          />
        );
      })}
    </div>
  );
};

export default CryptoScoller;
