export const QuestionList = [
  {
    module: "googlenews",
    qns: [
      "What insights do you have regarding uncertainty in the market?",
      "What types of uncertainty are there?",
      "Why is there uncertainty?",
      "What score would you give that out of 1-100 (100 being very uncertain)?",
    ],
  },
  {
    module: "cryptonews",
    qns: [
      "What information here is a summary of the main stream news and what is “news” (ie. new information)?",
      "What bias do you see from these news sources?",
      "Are price predictions based in reality or is it confirmation bias?",
      "What information refers to a regulation scenario that could lead to a signal to sell?",
      "What information here is a sign of an emerging trend that suggests a signal to buy?",
    ],
  },
  {
    module: "calendar",
    qns: [
      "What events in the next 2-3 days are most significant on the crypto space and/or your coin interest?",
      "Is the information a meme coin?",
      "Are there any updates regarding coins you follow?",
      "Are there any promising alt-coins coming onto the market?",
    ],
  },
  {
    module: "googletrends",
    qns: ["How much interest is there in the certain crypto at a given time?"],
  },
  {
    module: "fng",
    qns: [
      "How is the market feeling in the short term?",
      "How much does impact do micro and macro events have on the market?",
      "What similarities do you see between the stock and crypto market?",
    ],
  },
  {
    module: "onchain",
    qns: [
      "How much BTC is held by whales and on exchanges?",
      "Is there any incongruence between news and what whales are doing (i.e. are they manipulating the market)?",
      "Are we seeing larges amounts coming on to exchanges?",
      "Look at bitcoin thermacap, bitcoin hodl waves & nupl indicator. When you combine these three do you see market top?",
    ],
  },
  {
    module: "walletmovement",
    qns: [
      "Wallet to wallet: Could this be an example of wash trading (an owner of two wallets transferring them) from the ones they own?",
    ],
  },
  {
    module: "social",
    qns: [
      "What is the average trader sentiment?",
      "What news from twitter from china or korea is breaking news that effects crypto or your portfolio? (Check Colin Wu)",
      "What are Bob Loukas's technical long term insights into the market?",
      "What are Tradersz's technical analysis in the short term?",
    ],
  },
  {
    module: "por",
    qns: [
      "Do the proof of reserves disclosed by the exchange show that the exchange have 1:1 backing for the digital assets it holds in custody for its customers?",
      "Do the types of cryptocurrency held by the exchange show any signs of possible insolvency, like an exchange's value is mostly based on its own tokens?",
    ],
  },
  {
    module: "exchangewallet",
    qns: [
      "Does the supply of coins held in the exchange's wallet show suspicious activity (eg. illegal movement of client funds?)",
      "Does the exchange's net position change reflect any impending events?",
    ],
  },
];
