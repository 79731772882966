import React, { useState, useEffect } from "react";
import axios from "axios";

// import { useMoralisWeb3Api } from "react-moralis";

import { apiLinks } from "../../utils/links";

const NFTTitle = (props) => {
  const {
    nftName,
    setNftName,
    evalSectionVisible,
    setEvalSectionVisible,
    searchingNFT,
    setSearchingNFT,
    imgStr,
    setImgStr,
  } = props;

  // const Web3Api = useMoralisWeb3Api();

  const [isSearchEmpty, setIsSearchEmpty] = useState(false);
  const [isResultsFound, setIsResultsFound] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isError, setIsError] = useState(false);
  const [extendedSearch, setExtendedSearch] = useState(false);

  const handleChange = (e) => {
    setNftName(e.target.value);
  };

  const setErrorSettings = () => {
    setIsResultsFound(false);
    setSearchingNFT(false);
    setIsSearchEmpty(false);
    setEvalSectionVisible(false);
    setButtonDisabled(false);
    setIsError(true);
    setExtendedSearch(false);
    setImgStr("");
  };

  const getNFTData = async (img) => {
    if (img.trim() != "" && img.trim().length >= 3) {
      setIsResultsFound(true);
      setIsSearchEmpty(false);
      setSearchingNFT(true);
      setButtonDisabled(true);
      setIsError(false);
      setExtendedSearch(false);

      //axios call to nftport for search
      // setExtendedSearch(true);
      // setSearchingNFT(false);
      await axios({
        url: apiLinks.nftSearch2Api + img.trim(),
        method: "get",
      })
        .then((res) => {
          // console.log(res.data[0].token.metadata);
          //no nftport result
          if (
            res.data.search_results[0] === undefined ||
            res.data.search_results[0].cached_file_url === ""
          ) {
            //no results from nftport
            setErrorSettings();
          } else {
            // have nftport result
            // console.log(NFTs);
            // console.log(NFTs.result[0].metadata);
            setEvalSectionVisible(true);
            setIsResultsFound(true);
            setButtonDisabled(false);
            setIsError(false);
            setExtendedSearch(false);
            window.sessionStorage.setItem("NftName", img.trim());
            // moralis old server options
            // const tempstr = JSON.parse(NFTs.result[0].metadata);
            // const tempstr = JSON.parse(res.data[0].token.metadata);
            let tempstr = "";

            for (var i = 0; i < res.data.search_results.length; i++) {
              if (res.data.search_results[i].cached_file_url != "") {
                tempstr = res.data.search_results[i].cached_file_url;
                // console.log("nftport");
                // console.log("i is: " + i);
                break;
              }
            }
            console.log(tempstr);
            setImgStr(tempstr);
            // setImgStr("rdmimg");
            // console.log(tempstr);
          }
        })
        .catch((err) => {
          console.log(err);
          setErrorSettings();
        });
    } else {
      setExtendedSearch(false);
      setIsSearchEmpty(true);
      setIsResultsFound(true);
      setEvalSectionVisible(false);
      setImgStr("");
      setSearchingNFT(false);
      setButtonDisabled(false);
    }
  };

  return (
    <div className="pb-8">
      <form
        className="grid grid-cols-6 gap-4"
        onSubmit={(e) => {
          e.preventDefault();
          getNFTData(nftName);
        }}
      >
        <span className="col-span-1" />
        <label className="col-span-1">NFT Name: </label>
        <input
          type="text"
          name="nftname"
          className="dark:bg-slategrey bg-lightbg border rounded-lg col-span-2"
          onChange={(e) => {
            handleChange(e);
          }}
          value={nftName}
        />
        {/* <span className="px-4" /> */}
        <input
          type="submit"
          disabled={buttonDisabled}
          className="bg-orangebtn hover:bg-lighterorangebtn text-white text-lg font-bold py-2 rounded focus:outline-none focus:shadow-outline col-span-1 cursor-pointer disabled:cursor-wait"
          value="Search NFT"
        />
        <span className="col-span-1" />
      </form>

      {searchingNFT && (
        <div className="text-center text-red-500 py-5">
          Please wait, searching...
        </div>
      )}
      {extendedSearch && (
        <div className="text-center text-red-500 py-5">
          Doing an in-depth search, please wait...
        </div>
      )}
      {!isResultsFound && (
        <div className="text-center text-red-500 py-5">No NFT found!</div>
      )}
      {isSearchEmpty && (
        <div className="text-center text-red-500 py-5">
          Please enter a relevant search term or a search term of over 3
          characters!
        </div>
      )}
      {isError && (
        <div className="text-center text-red-500 py-5">
          Error searching, please try again!
        </div>
      )}
    </div>
  );
};

export default NFTTitle;
