import React, { useState, useEffect } from "react";

import axios from "axios";

import CoinCollectionTile from "./CoinCollectionTile";
import { apiLinks } from "../../utils/links";

const CollectCoinsPanel = (props) => {
  const {
    coins,
    setCoins,
    collectedCoins,
    setCollectedCoins,
    currDayNo,
    setCurrDayNo,
    email,
  } = props;

  const days = [1, 1, 2, 4, 6, 8, 10];

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleAddCoins = async () => {
    setIsLoading(true);

    var result = await axios({
      url: apiLinks.reportApi + "coin/" + email.toLowerCase() + "/daily",
      method: "POST",
    })
      .then((res) => {
        // console.log(res.data);
        if (res.data) {
          window.sessionStorage.setItem("coinsNo", res.data.curr_coins);
          window.sessionStorage.setItem("daysSigned", res.data.days_signed_in);
          window.sessionStorage.setItem(
            "coinCollectedDate",
            res.data.last_collected_date
          );

          setCollectedCoins(true);
          // setCoins(parseInt(coins) + days[currDayNo]);
          setCoins(res.data.curr_coins);
          // if (currDayNo >= days.length - 1) {
          //   setCurrDayNo(0);
          // } else {
          //   setCurrDayNo(parseInt(currDayNo) + 1);
          // }
          setCurrDayNo(res.data.days_signed_in);

          setIsError(false);
          setIsLoading(false);
          return;
        }
        //if error message (no user found)
        setIsError(true);
        return;
      })
      .catch((err) => {
        console.log(err);
        setIsError(true);
      });
  };

  return (
    <div>
      <div className="font-semibold">Daily Coin Collection</div>
      <div className="text-base pt-4 pb-10">
        Visit this page everyday to receive bonus coins!
      </div>
      <div className="flex flex-row mb-10 flex-wrap gap-5 items-center justify-center">
        {days.map((e, i) => (
          <CoinCollectionTile
            dayNo={i}
            coinNo={e}
            currDayNo={currDayNo}
            setCurrDayNo={setCurrDayNo}
          />
        ))}
      </div>

      {collectedCoins && (
        <div className="text-base py-4 text-green-500">
          You have already collected coin(s) today!
        </div>
      )}

      {isError && (
        <div className="text-base py-4 text-red-500">
          Error collecting coin(s)! Please try again later.
        </div>
      )}

      {isLoading && (
        <div className="text-base py-4 text-green-500">
          Collecting your coin(s)...
        </div>
      )}

      <button
        disabled={collectedCoins}
        className={`border border-none rounded-xl py-2 px-4 text-base text-lightbg ${
          collectedCoins
            ? "bg-purple cursor-not-allowed"
            : "bg-buttonlink hover:bg-buttonlink-hover"
        }`}
        onClick={handleAddCoins}
      >
        Collect Coins
      </button>
    </div>
  );
};

export default CollectCoinsPanel;
