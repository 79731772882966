import React, { useState, useEffect } from "react";
import { Redirect, Router, Switch, Route } from "react-router-dom";
import { useMoralis } from "react-moralis";

import Start from "../Start";

import "../../App.css";

import AuthModal from "./AuthModal";
import MagicModal from "./MagicModal";

import BannerPic from "../../assets/images/mainwhite.png";
import ThemeToggle from "../Theme/ThemeToggle";

const Main = (props) => {
  const {
    authenticate,
    isAuthenticated,
    user,
    auth,
    hasAuthError,
  } = useMoralis();

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");

  // const handleEmail = (value) => {
  //   if (value == "login") {
  //     setModalTitle("Login");
  //   } else {
  //     setModalTitle("Sign up");
  //   }
  //   setShowModal(true);
  // };

  const handleMagicEmail = () => {
    setShowModal(true);
  };

  const handleMetamask = async () => {
    await authenticate({
      signingMessage: "Login to Coin Gryphon",
      onSuccess: () => {
        console.log("success");
        //nav to index screen
        // console.log("user: " + user.get("username"));
        // alert("🎉" + user.get("username"));
        // console.log(user.get("coins"));
      },
      onError: () => {
        if (hasAuthError) {
          alert("Error: " + auth.error);
        }
      },
    });
  };

  return (
    <div>
      <div className="absolute right-0 top-0 mr-4 mt-4 md:mr-6 md:mt-6">
        <ThemeToggle />
      </div>
      <div className="px-4 bg-loginbg bg-center bg-cover bg-no-repeat h-screen md:pb-64 pb-28 flex flex-col justify-center items-center">
        <div className="md:flex md:items-center pb-5 pt-20">
          <div className="md:w-1/4"></div>
          <img
            src={BannerPic}
            alt="CoinGryphon.com"
            className="md:w-2/4 md:bg-cover"
          />
          <div className="md:w-1/4"></div>
        </div>

        <div className="flex flex-col justify-center items-center text-gray-50">
          <button
            className="text-yellow-300 hover:text-lightbg dark:hover:text-lightbg md:text-2xl text-xl pb-4"
            onClick={handleMetamask}
          >
            Login with Metamask
          </button>
          <button
            className="text-yellow-300 hover:text-lightbg dark:hover:text-lightbg md:text-2xl text-xl pb-4"
            onClick={handleMagicEmail}
          >
            Login / Sign up with Email
          </button>
          {/* <button
          className="text-yellow-300 hover:text-lightbg dark:hover:text-lightbg md:text-2xl text-xl pb-4"
          onClick={() => handleEmail("login")}
        >
          Login with Email
        </button>
        <button
          className="text-yellow-300 hover:text-lightbg dark:hover:text-lightbg md:text-2xl text-xl pb-4"
          onClick={() => handleEmail("signup")}
        >
          Sign up with Email
        </button> */}
        </div>
        {/* <AuthModal
        showModal={showModal}
        setShowModal={setShowModal}
        modalTitle={modalTitle}
        setModalTitle={setModalTitle}
      /> */}
        <MagicModal showModal={showModal} setShowModal={setShowModal} />
      </div>
    </div>
  );
};

export default Main;
