import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import { readRemoteFile, readString } from "react-papaparse";
import Linkify from "linkify-react";

import csvFile from "../../assets/data/airdrops.csv";

const airdropsLink =
  "https://raw.githubusercontent.com/ipolarbear/csvfiles/main/airdrops.csv";

const AirdropsTable = (props) => {
  const [parsedData, setParsedData] = useState([]);

  const parseFile = () => {
    // Papa.parse(file, {
    //   quotes: true,
    //   quoteChar: '"',
    //   download: true,
    //   header: true,
    //   skipEmptyLines: true,
    //   complete: (results, filename) => {
    //     console.log(results);
    //     console.log(filename);
    //     setParsedData(results.data);
    //   },
    // });

    readRemoteFile(airdropsLink, {
      quotes: true,
      quoteChar: '"',
      header: true,
      skipEmptyLines: true,
      complete: (results, filename) => {
        setParsedData(results.data);
      },
    });
  };

  const getData = async () => {
    const response = await fetch(csvFile);
    console.log(csvFile);
    const reader = response.body.getReader();
    console.log(reader);
    const result = await reader.read(); // raw array
    console.log("result");
    console.log(result);
    const decoder = new TextDecoder("utf-8");
    const csv = decoder.decode(result.value); // the csv text
    console.log("csv");
    console.log(csv);

    const results = Papa.parse(csv, {
      quotes: true,
      quoteChar: '"',
      header: true,
    });

    console.log("results");
    console.log(results);
    const parsedResults = results.data;
    setParsedData(parsedResults);
  };

  useEffect(() => {
    parseFile(csvFile);
    console.log(parsedData);
    // getData();
  }, []);

  return (
    <div className="grid grid-cols-9 text-center pt-5 pr-4 flex flex-shrink overflow-auto">
      <div className="col-span-1"></div>
      <table className="table-auto border-2 border-gray-400 col-span-7">
        <thead className="text-center text-2xl">
          <tr>
            <th className="border-2 border-gray-400">Project</th>
            <th className="border-2 border-gray-400">Project Type</th>
            <th className="border-2 border-gray-400">Action Required</th>
            <th className="border-2 border-gray-400">Frequency</th>
            <th className="border-2 border-gray-400">Funding</th>
          </tr>
        </thead>
        <tbody>
          {parsedData &&
            parsedData.map((d, index) => (
              <tr key={index}>
                {d.Site === "/" || d.Project === "\n" ? (
                  <></>
                ) : (
                  <>
                    <td className="border-2 border-gray-400 text-sm">
                      <a
                        href={d.Site}
                        target="_blank"
                        rel="noreferrer"
                        className="text-linkifylink hover:text-linkifylink-hover"
                      >
                        {d.Project} ({d.Blockchain})
                      </a>
                    </td>

                    <td className="border-2 border-gray-400 text-sm">
                      {d.Type}
                    </td>

                    <Linkify
                      options={{
                        rel: "noreferral",
                        target: "_blank",
                        className:
                          "text-linkifylink hover:text-linkifylink-hover",
                      }}
                      tagName="td"
                      className="border-2 border-gray-400 text-sm overflow-auto"
                    >
                      {d.Action}
                    </Linkify>

                    <td className="border-2 border-gray-400 text-sm">
                      {d.Frequency}
                    </td>

                    <td className="border-2 border-gray-400 text-sm">
                      {d.Funding}
                    </td>
                  </>
                )}
              </tr>
            ))}
        </tbody>
      </table>
      <div className="col-span-1"></div>
    </div>
  );
};

export default AirdropsTable;
