import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaLongArrowAltDown } from "react-icons/fa";

import Box from "./Box";

const Transfer = (props) => {
  const { transaction } = props;
  const dateTime = new Date(transaction.timestamp * 1000);
  const formattedTime = format(dateTime, "dd MMM yyyy, hh:mma (O)");
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  // useEffect(() => {
  //   console.log(transaction);
  // }, []);

  return (
    <>
      <div className="hidden sm:block">
        <div className="grid grid-cols-12">
          <div className="col-span-4"></div>
          <div className="col-span-4">
            <h3 className="p-2 text-lg md:p-5 md:text-lg overflow-hidden truncate">
              Transaction Hash: {transaction.hash}
            </h3>
          </div>
          <div className="col-span-4"></div>
        </div>
        <div className="grid justify-items-stretch md:gap-4 md:grid-cols-11">
          <div className="col-span-1"></div>
          <div className="col-span-3">
            <Box transaction={transaction} type="from" />
          </div>
          <div className="col-span-3 text-lg">
            <div className="grid justify-items-center">
              <FaLongArrowAltRight size={70} />
            </div>
            <div className="grid justify-items-center">
              <p>
                <span className="text-base">
                  Transaction Type: {transaction.transaction_type}
                </span>
                <br />
                <span className="font-bold">Time: {formattedTime}</span>
                <br />
                <span className="text-base">
                  Amount (in crypto): {transaction.amount}
                </span>
                <br />
                <div className="pt-2"></div>
                <span className="font-bold">
                  Amount (in USD): {formatter.format(transaction.amount_usd)}
                </span>
              </p>
            </div>
          </div>
          <div className="col-span-3">
            <Box transaction={transaction} type="to" />
          </div>
          <div className="col-span-1"></div>
        </div>
      </div>

      {/**mobile hashes */}
      <div className="sm:hidden">
        <div className="grid grid-cols-8">
          <div className="col-span-1"></div>
          <div className="col-span-6">
            <h3 className="p-2 text-lg md:p-5 md:text-lg overflow-hidden truncate">
              Transaction Hash: {transaction.hash}
            </h3>
          </div>
          <div className="col-span-1"></div>
        </div>
        <div className="grid grid-cols-7">
          <div className="col-span-1"></div>
          <div className="col-span-5">
            <Box transaction={transaction} type="from" />
          </div>
          <div className="col-span-1"></div>
        </div>
        <div className="text-lg">
          <div className="grid justify-items-center">
            <FaLongArrowAltDown size={70} />
          </div>
          <div className="grid justify-items-center">
            <p>
              <span className="text-base">
                Transaction Type: {transaction.transaction_type}
              </span>
              <br />
              <span className="font-bold">Time: {formattedTime}</span>
              <br />
              <span className="text-base">
                Amount (in crypto): {transaction.amount}
              </span>
              <br />
              <div className="pt-2"></div>
              <span className="font-bold">
                Amount (in USD): {formatter.format(transaction.amount_usd)}
              </span>
            </p>
          </div>
          <div className="grid justify-items-center">
            <FaLongArrowAltDown size={70} />
          </div>
        </div>
        <div className="grid grid-cols-7">
          <div className="col-span-1"></div>
          <div className="col-span-5">
            <Box transaction={transaction} type="to" />
          </div>
          <div className="col-span-1"></div>
        </div>
      </div>
    </>
  );
};

export default Transfer;
