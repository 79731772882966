export const NFTEvalList = [
  {
    section: "ART",
    qns: [
      {
        qn: "Is the project artistically interesting?",
        type: "checkbox",
      },

      {
        qn: "What are these qualities, features and properties?",
        type: "textbox",
      },

      {
        qn:
          "Do these features and traits create a sense of scarcity i.e. rare features and traits? \n\nFor example: Bored Ape Yacht Club has tremendous variety, like fur, hats, colours etc. these scarce items create the perception of greater value through scarcity, more specifically laser eyes or golden fur for Bored Ape, Aliens for Crypto Punks, Tv faces for the Cool Cats, or Gen Zero for the Crypto Kitties.",
        type: "checkbox",
      },

      {
        qn: "What are these features and traits?",
        type: "textbox",
      },

      {
        qn:
          "Is the concept for the collection based upon baseball cards or digital fine art?",
        options: ["baseball cards collection", "digital fine art collection"],
        type: "radio",
      },

      {
        qn: "Is it on rarity.tools?",
        type: "checkbox",
      },

      {
        qn: "What's the score? (Enter 0 for No)",
        type: "textbox",
      },

      {
        qn: "What is the score on OpenSea's properties metrics?",
        type: "textbox",
      },

      {
        qn:
          "Does the collection showcase the following qualities, features and properties?",
        options: ["Unique", "Intricate", "Authentic", "Ornate"],
        type: "multicheckbox",
      },

      {
        qn:
          "Is it derivative of most successful collections? See the following:",
        options: [
          {
            "The Merge by Pak": "themergebypak.jpg",
          },
          {
            "Beeple's Everyday": "beepleseveryday.png",
          },
          {
            "Fidenza 313": "fidenza313.png",
          },
          {
            "This changed Everything by Tim Berners Lee":
              "thischangedeverything.jpg",
          },
          {
            "Mutant Ape Yacht Club serum (Bored Ape Yacht Club)":
              "boredapeyachtclub.jpg",
          },
          {
            Cryptopunks: "cryptopunks.png",
          },
          {
            "Sandbox plot": "sandboxplot.png",
          },
          {
            "Decentraland plot": "decentraland.png",
          },
          {
            "Mystic Axie of Axie Infinity": "mysticaxie.jpg",
          },
        ],
        type: "hovermulticheckbox",
      },
    ],
  },

  {
    section: "FOUNDING TEAM",
    qns: [
      {
        qn: "Is the team anonymous?",
        type: "checkbox",
      },

      {
        qn: "Do they have a significant following?",
        type: "checkbox",
      },

      {
        qn: "How many followers do they have?",
        type: "textbox",
      },

      {
        qn:
          "Are they vocal about their background, or do they have a good reason to be anonymous, i.e. Bored Ape Founders are all women in male-dominated space?",
        options: ["vocal", "anonymous"],
        type: "radio",
      },

      {
        qn:
          "Is the team from the marginalised community? This is currently an essential factor for media attention.",
        type: "checkbox",
      },
    ],
  },

  {
    section: "ROADMAP",
    qns: [
      {
        qn: "Does the NFT project have a road map?",
        type: "checkbox",
      },

      {
        qn: "Is it gamified?",
        type: "checkbox",
      },

      {
        qn: "Does it have defi capabilities?",
        type: "checkbox",
      },

      {
        qn: "Are there rewards to hold the NFTs?",
        type: "checkbox",
      },

      {
        qn: "Are there any upgrades planned?",
        type: "checkbox",
      },

      {
        qn: "Are there any airdrops planned?",
        type: "checkbox",
      },

      {
        qn:
          "Exclusive early membership benefits? (eg. Staking rewards for erc20 for passive income)",
        type: "checkbox",
      },

      {
        qn: "Access to the whitelist of other NFT mints?",
        type: "checkbox",
      },

      {
        qn: "Does it have a merch store?",
        type: "checkbox",
      },
    ],
  },

  {
    section: "COMMUNITY",
    qns: [
      {
        qn: "What's the activity engagement on Twitter?",
        type: "textbox",
      },

      {
        qn: "Is there regular activity on Discord?",
        type: "checkbox",
      },

      {
        qn:
          "Does it have engaged community or following a large suggestion of a connection to collector community (e.g Sotheby's or Christie's) on Telegram?",
        type: "checkbox",
      },

      {
        qn:
          "Do you see the creator making or developing the project on Youtube or Instagram?",
        type: "checkbox",
      },
    ],
  },

  {
    section: "TRADING METRICS",
    qns: [
      {
        qn:
          "Supply to demand ratio: Is the number of owners in proportion to the number of collectors?",
        type: "checkbox",
      },

      {
        qn:
          "What is the supply to holding average ratio (the number of holders concerning the total number of collections)? The ideal is 2 items per holder, this prevents collectors from flooding the market and plummeting the price.",
        type: "textbox",
      },
    ],
  },

  {
    section: "STRATEGY",
    qns: [
      {
        qn: "Is it and ERC 1155 in ERC 721?",
        type: "checkbox",
      },

      {
        qn:
          "Does it have a prominent institutional investor recently purchased from the collection? (See https://www.cnbc.com/2021/08/23/visa-buys-cryptopunk-nft-for-150000.html)",
        type: "checkbox",
      },

      {
        qn: "Is it 1 of 1 or are there many editions?",
        options: ["1 of 1", "Many editions"],
        type: "radio",
      },

      {
        qn:
          "Whitelisting: Is it possible to gain early access before the mint?",
        type: "checkbox",
      },

      {
        qn:
          "Whitelisting: Are there discord threads that are populated by people who believe in the project or philosophy of the project? According to Opensea NFT holders who are on a whitelist are 78% likely to make a profit of 2 x or more.",
        type: "checkbox",
      },

      {
        qn:
          "Whitelisting: Do they have a promotion similar to the following? \n\n Sneak Peek: 🐈‍⬛ 🏍 \n We're going to give away 10 WL spots on this tweet\n To Enter👇🏻\n 1. Follow @Diverse\n 2. RT + Like\n 3. Tag 3 Friends\n 4. Join Discord: https://discord.gg/xhvGBXMbaW \n #Nft #Nfts #Nftgiveaway #nftgiveaways #Nftwhitelist",
        type: "checkbox",
      },

      {
        qn:
          "After buying NFT's at Mint Prices, is it possible to sell quickly after launch?",
        type: "checkbox",
      },

      {
        qn:
          "How can you be certain that the NFT is available? Gas fees mean there's no guarantee that you can own that (See https://dappradar.com/blog/drama-at-launch-the-sevens-nft-collection-suffers-minting-exploit).",
        type: "textbox",
      },

      {
        qn:
          "Buying NFT's on the secondary market: Is your portfolio diversified?",
        type: "checkbox",
      },

      {
        qn: "Did you pay around 2.2 eth (this is the most common price range)?",
        type: "checkbox",
      },

      {
        qn: "What is the transaction history of the NFT?",
        type: "textbox",
      },

      {
        qn: "How many wallets has it passed through?",
        type: "textbox",
      },

      {
        qn: "What's the market cap for the collection?",
        type: "textbox",
      },

      {
        qn: "What's its trading volume?",
        type: "textbox",
      },
    ],
  },
];
