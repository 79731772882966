import React, { useState, useEffect } from "react";

import { CSSTransition } from "react-transition-group";
import { HiChevronDoubleDown, HiChevronDoubleUp } from "react-icons/hi";
import Linkify from "linkify-react";
import axios from "axios";

import { apiLinks } from "../../utils/links";
import SectionCompletedCoins from "../SectionCompletedCoins/SectionCompletedCoins";

import "./CollapsiblePanel.css";

// const SECTIONNAME_REFERENCE = [
//   { "Market Cap": "marketcap" },
//   { "Community Size": "commsize" },
//   { "Exchange Support": "exchange" },
//   { "Tokens vs Coins": "reviews" },
//   { Whitepaper: "whitepaper" },
//   { "Use Case and Tokenomics": "coin" },
//   { "Excessive Marketing": "organic" },
//   { Founders: "founder" },
// ];

const CollapsiblePanel = (props) => {
  const {
    data,
    sectionCompleted,
    setSectionCompleted,
    moduleName,
    index,
    dbData,
    moduleId,
    setModuleId,
    searchedCoinName,
    email,
  } = props;

  const [isVisible, setIsVisible] = useState(false);
  const [isSubmitPressed, setIsSubmitPressed] = useState(false);
  const [isChecked, setIsChecked] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // console.log(dbData["coin_code"]);

    // for (var j = 0; j < Object.keys(dbData).length; j++) {
    //   if (
    //     String(Object.keys(dbData)[j])
    //       .startsWith(Object.values(SECTIONNAME_REFERENCE[index]))
    //   ) {
    //     console.log(Object.keys(dbData)[j]);
    //   }
    // }

    var tempArr = [];

    if (dbData !== "") {
      switch (index) {
        case 0:
          tempArr[0] = dbData.marketcap_coingecko;
          tempArr[1] = dbData.marketcap_coinmarketcap;
          break;
        case 1:
          tempArr[0] = dbData.commsize_millions;
          tempArr[1] = dbData.commsize_billions;
          break;
        case 2:
          tempArr[0] = dbData.exchange_kucoin;
          tempArr[1] = dbData.exchange_binance;
          tempArr[2] = dbData.exchange_kraken;
          tempArr[3] = dbData.exchange_coinbase;
          tempArr[4] = dbData.exchange_gemini;
          break;
        case 3:
          tempArr[0] = dbData.reviews_real;
          break;
        case 4:
          tempArr[0] = dbData.whitepaper_find;
          tempArr[1] = dbData.whitepaper_jargon;
          tempArr[2] = dbData.whitepaper_recent;
          break;
        case 5:
          tempArr[0] = dbData.coin_purpose;
          tempArr[1] = dbData.coin_evidence;
          tempArr[2] = dbData.coin_code;
          tempArr[3] = dbData.coin_distribution;
          tempArr[4] = dbData.coin_explorer;
          tempArr[5] = dbData.coin_team;
          break;
        case 6:
          tempArr[0] = dbData.organic_growth;
          break;
        case 7:
          tempArr[0] = dbData.founder_startup;
          tempArr[1] = dbData.founder_interviews;
          tempArr[2] = dbData.founder_vision;
          tempArr[3] = dbData.founder_meme;
          break;
        default:
          break;
      }

      setIsChecked(tempArr);
    } else {
      setIsChecked(new Array(data.checklist.length).fill(false));
    }
  }, []);

  const handleCheck = (value, sectionName, index) => {
    var temp = isChecked;
    temp[index] = !isChecked[index];

    setIsChecked(temp);
    // isChecked[index] = !isChecked[index];
    // if (isChecked[index]) {
    //   console.log(val);

    // }
  };

  const handleSubmit = async (e, sectionName) => {
    e.preventDefault();

    setIsLoading(true);
    var urlLink = "";

    if (moduleId === "") {
      //post (create new)
      urlLink = apiLinks.reportApi + moduleName;
    } else {
      //put
      urlLink =
        apiLinks.reportApi + moduleName + "/" + email + "/cg/" + moduleId;
    }

    var dat;

    switch (index) {
      case 0:
        dat = {
          email: email,
          name: searchedCoinName,
          marketcap_coingecko: isChecked[0],
          marketcap_coinmarketcap: isChecked[1],
        };
        break;
      case 1:
        dat = {
          email: email,
          name: searchedCoinName,
          commsize_million: isChecked[0],
          commsize_billions: isChecked[1],
        };
        break;
      case 2:
        dat = {
          email: email,
          name: searchedCoinName,
          exchange_kucoin: isChecked[0],
          exchange_binance: isChecked[1],
          exchange_kraken: isChecked[2],
          exchange_coinbase: isChecked[3],
          exchange_gemini: isChecked[4],
        };
        break;
      case 3:
        dat = {
          email: email,
          name: searchedCoinName,
          reviews_real: isChecked[0],
        };
        break;
      case 4:
        dat = {
          email: email,
          name: searchedCoinName,
          whitepaper_find: isChecked[0],
          whitepaper_jargon: isChecked[1],
          whitepaper_recent: isChecked[2],
        };
        break;
      case 5:
        dat = {
          email: email,
          name: searchedCoinName,
          coin_purpose: isChecked[0],
          coin_evidence: isChecked[1],
          coin_code: isChecked[2],
          coin_distribution: isChecked[3],
          coin_explorer: isChecked[4],
          coin_team: isChecked[5],
        };
        break;
      case 6:
        dat = {
          email: email,
          name: searchedCoinName,
          organic_growth: isChecked[0],
        };
        break;
      case 7:
        dat = {
          email: email,
          name: searchedCoinName,
          founder_startup: isChecked[0],
          founder_interviews: isChecked[1],
          founder_vision: isChecked[2],
          founder_meme: isChecked[3],
        };
        break;
      default:
        dat = {
          email: email,
          name: searchedCoinName,
        };
        break;
    }

    await axios({
      url: urlLink,
      method: "post",
      data: dat,
    })
      .then((res) => {
        // console.log(res.data);
        setIsLoading(false);

        if (!res.data) {
          setIsError(true);
          setIsSubmitPressed(false);
          return;
        }

        setModuleId(res.data.id);
        setIsSubmitPressed(true);
        setIsError(false);

        window.sessionStorage.setItem(
          moduleName + " - " + sectionName,
          isChecked
        );

        var temp = [...sectionCompleted];
        temp[index] = true;
        // console.log(temp);
        setSectionCompleted(temp);
      })
      .catch((err) => {
        console.log(err);

        setIsLoading(false);
        setIsError(true);
        setIsSubmitPressed(false);
      });

    // window.sessionStorage.setItem(moduleName + " - " + sectionName, isChecked);
    // setIsSubmitPressed(true);
  };

  return (
    <div>
      <div className="py-2">
        <button
          type="button"
          onClick={() => {
            setIsVisible(!isVisible);
            // console.log(dbData);
            // console.log(Object.values(SECTIONNAME_REFERENCE[index]));
          }}
          className="text-3xl"
        >
          {data.sectionName}
          {isVisible ? (
            <HiChevronDoubleUp className="pl-4 pt-2 float-right" />
          ) : (
            <HiChevronDoubleDown className="pl-4 pt-2 float-right" />
          )}
        </button>
      </div>
      <CSSTransition in={!isVisible} timeout={300} classNames="test">
        {!isVisible ? (
          <></>
        ) : (
          <div className="flex flex-col">
            <div className="grid grid-cols-5 text-lg pt-5">
              <div className="col-span-1"></div>
              <div className="text-justify col-span-3">{data.description}</div>
              <div className="col-span-1"></div>
            </div>

            <div className="grid grid-cols-5 text-lg pt-5">
              <div className="col-span-1"></div>
              <table className="table-auto border-2 border-gray-400 text-justify col-span-3">
                <tbody>
                  {data.checklist &&
                    isChecked &&
                    data.checklist.map((d, index) => (
                      <tr key={index}>
                        <Linkify
                          tagName="td"
                          className="border-2 border-gray-400 md:text-md text-sm px-2"
                          options={{
                            rel: "noreferral",
                            target: "_blank",
                            className:
                              "text-linkifylink hover:text-linkifylink-hover hover:underline",
                          }}
                        >
                          {Object.values(d)}
                        </Linkify>

                        <td className="border-2 border-gray-400 text-center">
                          <input
                            type="checkbox"
                            name={Object.keys(d)}
                            defaultChecked={isChecked[index]}
                            value={isChecked[index]}
                            onChange={() => {
                              handleCheck(
                                Object.values(d),
                                data.sectionName,
                                index
                              );
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <div className="col-span-1"></div>
            </div>

            <div className="pt-3">
              <input
                type="submit"
                value="Save"
                disabled={isLoading}
                onClick={(e) => {
                  handleSubmit(e, data.sectionName);
                }}
                className="bg-orangebtn hover:bg-orangebtn-hover text-white py-2 px-4 rounded-xl text-base cursor-pointer disabled:cursor-wait"
              />
            </div>

            {isSubmitPressed ? (
              <>
                <div className="pt-2 text-green-700 text-base">Saved!</div>
              </>
            ) : (
              <></>
            )}

            {isError ? (
              <>
                <div className="pt-2 text-red-700 text-base">
                  Error saving... Please try again!
                </div>
                <br />
              </>
            ) : (
              <></>
            )}

            {isSubmitPressed && (
              <SectionCompletedCoins
                isSubmitPressed={isSubmitPressed}
                moduleName={moduleName}
                email={email}
                isLoading={isLoading}
              />
            )}
          </div>
        )}
      </CSSTransition>
    </div>
  );
};

export default CollapsiblePanel;
