import React, { useState, useEffect } from "react";

const NftEvalSectionResult = (props) => {
  const { sectionName, allQns, nftEvalSectionName } = props;
  const [sectionAns, setSectionAns] = useState([]);
  var count = 4;

  useEffect(() => {
    //   allQns.forEach((element) => {
    //     console.log(Object.keys(element));
    //   });

    var temp = window.sessionStorage.getItem(
      nftEvalSectionName + " - " + sectionName
    );

    if (temp) {
      var arr = temp.split(",");
      setSectionAns(arr);
    }

    // console.log(allQns[0].qn);
    // console.log(temp);
  }, []);

  return (
    <div className="text-lg text-left pt-6">
      {sectionAns && (
        <>
          <div className="font-bold">{sectionName} : </div>
          {allQns.map((element, index) => {
            return (
              <>
                <span>{element.qn} </span>
                <span className="italic text-orangebtn">
                  {element.type === "checkbox"
                    ? sectionAns[index] === "true"
                      ? "yes"
                      : "no"
                    : ""}

                  {element.type === "textbox"
                    ? sectionAns[index] === ""
                      ? "-"
                      : sectionAns[index]
                    : ""}

                  {element.type === "radio"
                    ? sectionAns[index] === ""
                      ? "-"
                      : sectionAns[index]
                    : ""}

                  {element.type === "multicheckbox"
                    ? element.options.map((op, opIndex) => {
                        return (
                          <div className="not-italic">
                            {op} :{" "}
                            <span className="italic text-orangebtn">
                              {sectionAns[index + opIndex] === "true"
                                ? "yes"
                                : "no"}
                            </span>
                          </div>
                        );
                      })
                    : ""}

                  {element.type === "hovermulticheckbox"
                    ? element.options.map((op, opIndex) => {
                        return (
                          <div className="not-italic">
                            {Object.keys(op)} :{" "}
                            <span className="italic text-orangebtn">
                              {sectionAns[index + opIndex + count - 1] ===
                              "true"
                                ? "yes"
                                : "no"}
                            </span>
                          </div>
                        );
                      })
                    : ""}
                </span>
                <br />
                <br />
              </>
            );
          })}
        </>
      )}
    </div>
  );
};

export default NftEvalSectionResult;
