import React, { useState, useEffect } from "react";

const CoinEvaluationHeader = (props) => {
  return (
    <div>
      <h1 className="text-center text-5xl flex justify-center pt-5">
        Coin Evaluation Checklist
      </h1>
      <br />
      <br />
    </div>
  );
};

export default CoinEvaluationHeader;
