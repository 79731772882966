import React, { useState, useEffect, useContext } from "react";

import Chart from "react-apexcharts";
import axios from "axios";
import { format, subDays } from "date-fns";

import { apiLinks } from "../../utils/links";
import { ThemeContext } from "../Theme/ThemeContext";

const MacdGraph = (props) => {
  const currTheme = useContext(ThemeContext);
  const { coinName, setCoinName } = props;

  const [macd, setMacd] = useState([]);
  const [signal, setSignal] = useState([]);
  const [hist, setHist] = useState([]);
  const [dateArray, setDateArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [todayDate, setTodayDate] = useState(new Date(Date.now()));

  useEffect(() => {
    setLoading(true);
    var macdLink = "";

    if (coinName == "BTC") {
      macdLink = apiLinks.btcMacdGraph;
    } else if (coinName == "ETH") {
      macdLink = apiLinks.ethMacdGraph;
    }
    // else{
    //   macdLink = apiLinks.macdGraph + coinName;
    // }

    axios({
      url: macdLink,
      method: "get",
    })
      .then((res) => {
        // console.log(res.data);

        var tempdate = [];
        var tempmacd = [];
        var tempsig = [];
        var temphist = [];

        res.data.forEach((e) => {
          var day = format(subDays(todayDate, e.backtrack), "dd MMM yyyy");
          tempdate.push(day);
          tempmacd.push(e.valueMACD);
          tempsig.push(e.valueMACDSignal);
          temphist.push(e.valueMACDHist);
        });

        tempdate = tempdate.reverse();
        tempmacd = tempmacd.reverse();
        tempsig = tempsig.reverse();
        temphist = temphist.reverse();

        setLoading(false);
        setDateArray(tempdate);
        setMacd(tempmacd);
        setSignal(tempsig);
        setHist(temphist);
      })
      .catch((err) => console.log(err));
  }, []);

  //graph options
  const options = {
    stroke: {
      width: [0, 4, 4],
      curve: "smooth",
    },
    colors: ["#2E93fA", "#66DA26", "#E91E63"],
    title: { text: coinName + ` MACD Graph`, align: "center" },
    theme: { mode: currTheme.theme },
    // tooltip: {
    //   theme: currTheme.theme,
    // },
    chart: {
      background: "rgba(0, 0, 0, 0)",
    },
    noData: {
      text: "Loading...",
    },
    grid: {
      borderColor: currTheme.theme === "dark" ? "#fcfcfc" : "#e7e7e7",
      row: {
        colors: currTheme.theme === "dark" ? [] : ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    markers: {
      size: 4,
    },
    xaxis: {
      categories: dateArray,
    },
    yaxis: {
      opposite: false,
      title: {
        text: "MACD",
      },
    },
    // annotations: {
    //   yaxis: [
    //     {
    //       y: 0,
    //       borderColor: "#775DD0",
    //       borderWidth: 5,
    //       label: {
    //         borderColor: "#775DD0",
    //         style: {
    //           color: "#fff",
    //           background: "#775DD0",
    //         },
    //       },
    //     },
    //   ],
    // },
  };

  return (
    <div>
      {macd && signal && hist && (
        <Chart
          options={options}
          series={[
            { name: "Histogram", data: hist, type: "column" },
            { name: "MACD", data: macd, type: "line" },
            { name: "MACD Signal", data: signal, type: "line" },
          ]}
          height={500}
          width={1000}
        />
      )}
    </div>
  );
};

export default MacdGraph;
