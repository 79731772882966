import React, { useState, useEffect } from "react";
import axios from "axios";

import { MdEmail } from "react-icons/md";

import "../../App.css";

import { apiLinks } from "../../utils/links";
import BannerPic from "../../assets/images/mainwhite.png";
import BannerPicBlack from "../../assets/images/mainlight.png";
import ThemeToggle from "../Theme/ThemeToggle";
import AccessCodeModal from "./AccessCodeModal";
import { ThemeContext } from "../Theme/ThemeContext";

const HideApplication = (props) => {
  const { theme } = React.useContext(ThemeContext);
  // const { setPassword, wrongPass, setWrongPass, SECRETPASS } = props;
  const { authenticated, setAuthenticated, SECRETPASS } = props;
  const [isEmail, setIsEmail] = useState(true);
  const [email, setEmail] = useState("");
  const [addedEmail, setAddedEmail] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const emailRegex = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const handleSubmitEmail = async () => {
    // setPassword(pass);

    if (isEmail && email !== "") {
      axios({
        url: apiLinks.emailCapture + "emails/",
        method: "post",
        data: {
          email: email,
        },
      }).catch((err) => console.log(err));

      setAddedEmail(true);
    }
  };

  const handleInputChange = (e) => {
    // console.log(e.target.value);
    // console.log(e.target.id);

    if (e.target.id == "email-input") {
      setEmail(e.target.value.trim());
      setAddedEmail(false);
    }
  };

  const handleBadEmail = (e) => {
    if (!emailRegex.test(e.target.value)) {
      setIsEmail(false);
      setAddedEmail(false);
    } else {
      setIsEmail(true);
      setAddedEmail(false);
    }
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div>
      <div className="absolute right-0 top-0 mr-4 mt-4 md:mr-6 md:mt-6">
        <ThemeToggle />
      </div>
      <div className="px-4 dark:bg-hideappbg md:pb-76 pb-28">
        <div className="md:flex md:items-center pb-5 pt-10">
          <div className="md:w-1/6"></div>
          {theme === "dark" ? (
            <img
              src={BannerPic}
              alt="CoinGryphon.com"
              className="md:w-1/6 md:bg-contain"
            />
          ) : (
            <img
              src={BannerPicBlack}
              alt="CoinGryphon.com"
              className="md:w-1/6 md:bg-contain py-8"
            />
          )}

          <div className="md:w-4/6"></div>
        </div>

        <div className="md:flex md:items-center pt-16 pb-4">
          <div className="md:w-1/4"></div>
          <div className="md:w-2/4 md:bg-contain font-bold text-center text-6xl">
            Optimize your digital asset portfolio.
          </div>
          <div className="md:w-1/4"></div>
        </div>

        <div className="md:flex md:items-center pb-10">
          <div className="md:w-1/4"></div>
          <div className="md:w-2/4 md:bg-contain font-bold text-center text-2xl">
            AI-assisted Asset Research
          </div>
          <div className="md:w-1/4"></div>
        </div>

        <form
          className="max-w-prose mx-auto max-w-xs md:pt-20"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmitEmail(email);
          }}
        >
          <div className="md:flex md:items-center pb-5">
            <div className="md:w-1/3">
              <label className="block font-bold md:text-right mb-1 md:mb-0 pr-4 text-center text-base">
                Find out more: <MdEmail className="inline" />
              </label>
            </div>
            <div className="md:w-2/3">
              <input
                type="email"
                id="email-input"
                name="email"
                placeholder="charles@stayrich.com"
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:shadow-outline"
                value={email}
                onChange={(e) => handleInputChange(e)}
                onBlur={handleBadEmail}
              />
            </div>
          </div>
          <div className="flex flex-col justify-center items-center pt-2">
            <div>
              <input
                type="submit"
                className="bg-buttonlink hover:bg-buttonlink-hover text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline cursor-pointer"
                value="Apply"
              />
            </div>
          </div>
          {!isEmail ? (
            <div className="flex flex-col justify-center items-center">
              <div>
                <h1 className="md:flex md:items-center pt-3 text-red-500 text-base">
                  Please enter a valid email!
                </h1>
              </div>
            </div>
          ) : null}
          {addedEmail ? (
            <div className="flex flex-col justify-center items-center">
              <div>
                <h1 className="md:flex md:items-center pt-5 text-green-500 text-base">
                  We've reached capacity, we'll be in touch soon...
                </h1>
              </div>
            </div>
          ) : null}
        </form>

        <div
          className="text-center pt-10 text-lighterbuttonlink cursor-pointer"
          onClick={toggleModal}
        >
          Have an access code?
        </div>

        <AccessCodeModal
          showModal={showModal}
          setShowModal={setShowModal}
          authenticated={authenticated}
          setAuthenticated={setAuthenticated}
          SECRETPASS={SECRETPASS}
        />
        <div className="pt-10"></div>
      </div>
    </div>
  );
};

export default HideApplication;
