import React, { useState, useEffect } from "react";

import { FaArrowDown } from "react-icons/fa";

import FearGreed from "../FnG/Feargreed";
import SwingTrading from "../SwingTrading/SwingTrading";

const TechnicalAnalysis = (props) => {
  return (
    <div className="container mx-auto md:pb-64 pb-28">
      <FearGreed />
      <div className="pb-10 align-items-center justify-center flex">
        <FaArrowDown size={50} color="white" />
      </div>
      <SwingTrading />
    </div>
  );
};

export default TechnicalAnalysis;
