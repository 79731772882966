import React, { useState, useEffect } from "react";

import axios from "axios";

import { ExchangeList } from "./ExchangeList";
import { apiLinks } from "../../utils/links";

const DropdownList = (props) => {
  const {
    selectedExchange,
    setSelectedExchange,
    isLoading,
    setIsLoading,
    sectionVisible,
    setSectionVisible,
    graphData,
    setGraphData,
    selectedExchangeDisplayName,
    setSelectedExchangeDisplayName,
  } = props;

  const [exchangeList, setExchangeList] = useState(ExchangeList);
  const [isError, setIsError] = useState(false);

  const handleSelect = (e) => {
    var split = e.target.value.split(",");
    setSelectedExchange(split[0]);
    setSelectedExchangeDisplayName(split[1]);
    setSectionVisible(false);
  };

  const handleClick = async () => {
    setIsLoading(true);

    await axios({
      url: apiLinks.proofOfReservesApi + selectedExchange,
      method: "get",
    })
      .then((res) => {
        // console.log(res.data);
        setIsLoading(false);

        if (!res.data) {
          setIsError(true);
          setSectionVisible(false);
        }

        setGraphData(res.data);
        setIsError(false);
        setSectionVisible(true);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setIsError(true);
        setSectionVisible(false);
      });
  };

  return (
    <div className="pt-5">
      <label>Choose Exchange:</label>
      <br />
      <div className="pt-5">
        <select
          className="text-darkbg p-2 border border-1 border-darkbg rounded-lg dark:border-lightbg"
          onChange={(e) => handleSelect(e)}
        >
          <option className="" value="">
            Select Exchange
          </option>
          {exchangeList.map((exchange) => {
            return (
              <option
                className=""
                value={`${exchange.value},${exchange.display}`}
                key={exchange.value}
              >
                {exchange.display}
              </option>
            );
          })}
        </select>
        <div className="pt-8">
          <button
            className="rounded-lg text-2xl p-3 bg-buttonlink text-lightbg hover:bg-lighterorangebtn disabled:cursor-not-allowed"
            disabled={!selectedExchange}
            onClick={handleClick}
          >
            Get Reserves
          </button>
        </div>

        {isError && (
          <>
            <div className="pt-5 text-red-700 text-base">
              Error getting exchange reserves, please try again!
            </div>
            <br />
          </>
        )}
      </div>
    </div>
  );
};

export default DropdownList;
