import React, { useState, useEffect } from "react";
import Button from "./Button";

const Page = (props) => {
  const { newPrev, setNewPrev, setIsError } = props;

  return (
    <div className="h-screen flex flex-col justify-center items-center">
      <div className="grid justify-evenly items-center w-screen">
        <Button
          name={"New Analysis"}
          type={"new"}
          newPrev={newPrev}
          setNewPrev={setNewPrev}
          setIsError={setIsError}
        />
        <br />
        <Button
          name={"Previous Analysis"}
          type={"prev"}
          newPrev={newPrev}
          setNewPrev={setNewPrev}
          setIsError={setIsError}
        />
      </div>
    </div>
  );
};

export default Page;
