import React, { useState, useEffect, useContext } from "react";

import Chart from "react-apexcharts";
import axios from "axios";
import { format, subDays } from "date-fns";

import { apiLinks } from "../../utils/links";
import { ThemeContext } from "../Theme/ThemeContext";

const RsiGraph = (props) => {
  const currTheme = useContext(ThemeContext);
  const { coinName, setCoinName } = props;

  const [rsiValue, setRsiValue] = useState([]);
  const [dateArray, setDateArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [todayDate, setTodayDate] = useState(new Date(Date.now()));

  useEffect(() => {
    setLoading(true);
    var rsiLink = "";

    if (coinName == "BTC") {
      rsiLink = apiLinks.btcRsiGraph;
    } else if (coinName == "ETH") {
      rsiLink = apiLinks.ethRsiGraph;
    }
    // else{
    //   rsiLink = apiLinks.rsiGraph + coinName;
    // }

    axios({
      url: rsiLink,
      method: "get",
    })
      .then((res) => {
        // console.log(res.data);
        var temprsi = [];
        var tempdate = [];

        res.data.forEach((e) => {
          var day = format(subDays(todayDate, e.backtrack), "dd MMM yyyy");
          temprsi.push(e.value);
          tempdate.push(day);
        });

        temprsi = temprsi.reverse();
        tempdate = tempdate.reverse();

        setLoading(false);
        setRsiValue(temprsi);
        setDateArray(tempdate);
      })
      .catch((err) => console.log(err));
  }, []);

  //graph options
  const options = {
    stroke: {
      curve: "smooth",
    },
    title: { text: coinName + ` RSI Graph`, align: "center" },
    theme: { mode: currTheme.theme },
    colors: ["#00B1F2"],
    // tooltip: {
    //   theme: currTheme.theme,
    // },
    chart: {
      background: "rgba(0, 0, 0, 0)",
    },
    noData: {
      text: "Loading...",
    },
    grid: {
      borderColor: currTheme.theme === "dark" ? "#fcfcfc" : "#e7e7e7",
      row: {
        colors: currTheme.theme === "dark" ? [] : ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    markers: {
      size: 4,
    },
    xaxis: {
      categories: dateArray,
    },
    yaxis: {
      min: 0,
      max: 100,
      title: {
        text: "RSI",
      },
    },
    annotations: {
      yaxis: [
        {
          y: 30,
          borderColor: "#FF4560",
          borderWidth: 3,
          label: {
            borderColor: "#FF4560",
            style: {
              color: "#fff",
              background: "#FF4560",
            },
            text: "30",
          },
        },
        {
          y: 50,
          borderColor: "#FF9800",
          borderWidth: 3,
          label: {
            borderColor: "#FF9800",
            style: {
              color: "#fff",
              background: "#FF9800",
            },
            text: "50",
          },
        },
        {
          y: 70,
          borderColor: "#4CAF50",
          borderWidth: 3,
          label: {
            borderColor: "#4CAF50",
            style: {
              color: "#fff",
              background: "#4CAF50",
            },
            text: "70",
          },
        },
      ],
    },
  };

  return (
    <div>
      {rsiValue && dateArray && (
        <Chart
          options={options}
          series={[{ name: "RSI", data: rsiValue }]}
          type="line"
          height={500}
          width={1000}
        />
      )}
    </div>
  );
};

export default RsiGraph;
