import React, { useState, useEffect } from "react";

import { format } from "date-fns";

const NewsCard = (props) => {
  const { newsDetail, index } = props;
  const noimage = require(`../../assets/images/NFT/noimage.png`).default;

  const handleImageError = (e) => {
    e.target.src = noimage;
  };

  return (
    <div className="border-2 rounded h-max p-5 dark:bg-gray-700 bg-gray-100">
      <div className="text-2xl pb-3 hover:underline font-bold">
        <a href={newsDetail.link} target="_blank">
          {newsDetail.title}
        </a>
      </div>

      <img
        src={newsDetail.imgUrl}
        onError={(e) => handleImageError(e)}
        className="object-scale-down h-48 w-72 pb-5 place-self-center"
        alt={newsDetail.title}
      />

      <div className="text-lg pb-5">
        Published on:{" "}
        {format(newsDetail.feedDate, "dd MMMM yyyy, hh:mm aaaa (OOOO)")}
      </div>
      <div className="text-lg pb-3">Source: {newsDetail.source}</div>
      {/* {newsDetail.source.toLowerCase().includes("reddit") ||
      newsDetail.source.toLowerCase().includes("twitter") ? null : (
        <div className="text-base p-3 italic">{newsDetail.description}</div>
      )} */}
    </div>
  );
};

export default NewsCard;
