import React, { useState, useEffect } from "react";
import axios from "axios";

import { apiLinks } from "../../utils/links";
import CryptoCalendarHeader from "./CryptoCalendarHeader";
import CoinCard from "./CoinCard";
import Questions from "../Questions/Questions";

const AllCoinsCryptoCalendar = (props) => {
  const [eventDetails, setEventDetails] = useState([]);
  const [eventNo, setEventNo] = useState(0);
  const [totalPageNo, setTotalPageNo] = useState(0);
  const [noEvent, setNoEvent] = useState(false);
  const [error, setError] = useState(false);
  const [isLoadingEvent, setIsLoadingEvent] = useState(false);
  const [moduleName, setModuleName] = useState("calendar");

  useEffect(() => {
    var tempArr = [];
    let intervalId;

    setIsLoadingEvent(true);
    axios({
      url: apiLinks.coinMarketCalAllCoins,
      // url: apiLinks.coinMarketCal + "pawthereum",
      method: "get",
      // headers: {
      //   "x-api-key": apikey,
      // Accept: "application/json",
      // "Accept-Encoding": "deflate, gzip",
      // },
    })
      .then((res) => {
        // console.log(res.data);
        if (res.data.status.error_code == "0") {
          setEventNo(res.data._metadata.total_count);
          setTotalPageNo(res.data._metadata.page_count);
          setError(false);

          if (res.data._metadata.total_count > 0) {
            //page 1
            var tempStore = res.data.body;
            // tempArr.push(res.data.body);
            tempStore.forEach((element) => {
              tempArr.push(element);
            });

            if (res.data._metadata.page_count > 1) {
              for (let i = 2; i <= res.data._metadata.page_count; i++) {
                setTimeout(() => {
                  axios({
                    url: apiLinks.coinMarketCalAllCoins + i + "/",
                    method: "get",
                  })
                    .then((res) => {
                      var tempStore = res.data.body;
                      tempStore.forEach((element) => {
                        tempArr.push(element);
                        // console.log(tempArr.length);
                      });
                    })
                    .catch((err) => {
                      console.log(err);
                      setError(true);
                    });
                }, 1200);
              }
            }

            setEventDetails(tempArr);
            setNoEvent(false);

            intervalId = setInterval(() => {
              if (res.data._metadata.total_count == tempArr.length) {
                setIsLoadingEvent(false);
                clearInterval(intervalId);
                intervalId = null;
              }
            }, [1000]);
          } else {
            setIsLoadingEvent(false);
            setNoEvent(true);
          }
        } else {
          setIsLoadingEvent(false);
          setError(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  }, []);

  return (
    <div className="container mx-auto md:pb-64 pb-28">
      <CryptoCalendarHeader />
      <br />

      {isLoadingEvent ? (
        <h3 className="text-center text-2xl flex justify-center pt-5">
          Loading all calendar events...
        </h3>
      ) : null}

      {error ? (
        <h3 className="text-center text-2xl flex justify-center pt-5">
          Loading error! Please wait for 2 minutes before trying again.
        </h3>
      ) : null}

      {noEvent && !error && !isLoadingEvent ? (
        <h3 className="text-center text-2xl flex justify-center pt-5">
          There are currently no events planned or ongoing!
        </h3>
      ) : null}

      {!isLoadingEvent && eventNo > 0 && !error ? (
        <>
          <div className="text-2xl">
            There are {eventNo} events ongoing or planned.
          </div>
          <br />
          <div className="items-center justify-center w-full px-4">
            <div className="grid grid-cols-1 lg:grid-cols-4 lg:gap-4">
              {eventDetails.map((eventDetail, index) => {
                return (
                  <CoinCard
                    key={index}
                    eventDetail={eventDetail}
                    index={index}
                  />
                );
                // console.log(eventDetail);
              })}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <br />
      <br />
      <Questions moduleName={moduleName} />
    </div>
  );
};

export default AllCoinsCryptoCalendar;
