import React, { useState, useEffect } from "react";

import axios from "axios";
import Linkify from "linkify-react";

import { apiLinks } from "../../utils/links";
import CoinEvalTeamMemberModal from "./CoinEvalTeamMemberModal";

const CoinEvalTeamMember = (props) => {
  const { teammemId } = props;
  const [teamMemInfo, setTeamMemInfo] = useState("");
  const [teamMemError, setTeamMemError] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    axios({
      url: apiLinks.coinEvalCoinTeamMemberInfo + teammemId,
      method: "get",
    })
      .then((res) => {
        // console.log(res.data);
        setTeamMemError(false);
        setTeamMemInfo(res.data);
        setShowModal(true);
      })
      .catch((err) => {
        setTeamMemError(true);
        setShowModal(false);
        // console.log(err);
      });
  }, [teammemId]);

  return (
    <>
      {teamMemInfo && !teamMemError && (
        <CoinEvalTeamMemberModal
          showModal={showModal}
          setShowModal={setShowModal}
          teamMemInfo={teamMemInfo}
        />
      )}
    </>
  );
};

export default CoinEvalTeamMember;
