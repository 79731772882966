import React, { useState, useEffect } from "react";
import InfoBox from "./InfoBox";

const InfoBoxGrid = (props) => {
  const { coinMaxSupply, coinTotalSupply, coinCirculateSupply } = props;
  return (
    <div className="grid grid-flow-row md:grid-flow-col auto-rows-max md:auto-cols-max md:gap-4 md:grid-cols-3">
      <InfoBox
        title="Max coins"
        value={coinMaxSupply == 0 ? "-" : coinMaxSupply}
      />
      <InfoBox title="Total coins" value={coinTotalSupply} />
      <InfoBox title="Circulating coins" value={coinCirculateSupply} />
    </div>
  );
};

export default InfoBoxGrid;
