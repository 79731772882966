import React, { useState, useEffect } from "react";

import NFTPicCompare from "./NFTPicCompare";

const HoverMultiCheckbox = (props) => {
  const { data, index, dataArr, setDataArr } = props;
  const [isChecked, setIsChecked] = useState([]);

  useEffect(() => {
    if (dataArr[index] !== "") {
      var tempArr = dataArr[index].split(",");
      var arr = [];

      tempArr.forEach((element) => {
        element = element === "true";
        arr.push(element);
      });

      setIsChecked(arr);
      return;
    }

    setIsChecked(new Array(data.options.length).fill(false));
  }, []);

  const handleCheck = (i) => {
    var temp = isChecked;
    var tempDataArr = dataArr;
    var tempStr = "";

    temp[i] = !isChecked[i];

    temp.forEach((e, index) => {
      tempStr += e.toString();
      if (index < temp.length - 1) {
        tempStr += ",";
      }
    });

    setIsChecked(temp);

    tempDataArr[index] = tempStr;
    setDataArr(tempDataArr);
  };

  // useEffect(() => {
  //   if (dataArr[index].length !== data.options.length) {
  //     setIsChecked(new Array(data.options.length).fill(false));
  //   }
  // }, []);

  return (
    <div className="block pb-4 pt-10 flex items-center">
      <div className="grid grid-rows-2 text-lg pt-10 flex items-center">
        <label className="row-span-1 text-xl">{data.qn}</label>
        {data.options &&
          data.options.map((op, i) => {
            return (
              <div className="grid grid-cols-8 text-lg pt-10 flex items-center row-span-1">
                <label className="pr-4 col-span-6 text-justified whitespace-pre-line">
                  <NFTPicCompare
                    text={Object.keys(op)}
                    imglink={Object.values(op)}
                  />
                </label>
                <div className="col-span-1"></div>

                <input
                  className="w-8 h-8 rounded col-span-1"
                  type="checkbox"
                  name={Object.keys(op)}
                  defaultChecked={isChecked[i]}
                  value={isChecked[i]}
                  onClick={() => {
                    handleCheck(i);
                  }}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default HoverMultiCheckbox;
