import React, { useState, useEffect } from "react";
import { coins } from "../../utils/coins";

const CryptoButton = (props) => {
  const {
    coin,
    disabled,
    setCoinName,
    setCoinLink,
    setCoinId,
    coinlong,
    coinlink,
    coinid,
  } = props;
  const svgDir = require.context(
    "!@svgr/webpack!cryptocurrency-icons/svg/color/"
  );

  const images = coins.map((coin) => {
    return {
      name: coin.name,
      svg: svgDir(`./${coin.name}.svg`).default,
    };
  });

  const makesvglink = (coin) => {
    return svgDir(`./${coin}.svg`).default;
  };

  const renderImgByCoin = (coin) => {
    // const Svg = images.find((img) => img.name == coin).svg;
    const Svg = makesvglink(coin);
    return (
      <>
        <Svg width={32} height={32} />
      </>
    );
  };

  const coinUpper = () => {
    return coin.toUpperCase();
  };

  const btnClick = async () => {
    setCoinName(coinlong);
    setCoinLink(coinlink);
    setCoinId(coinid);
  };

  return (
    <button
      className="border-4 dark:border-lightbg border-darkbg hover:border-yellow-600 dark:hover:border-yellow-600 focus:border-pink-600 dark:focus:border-pink-600 rounded-lg"
      disabled={disabled}
      onClick={btnClick}
    >
      <div className="my-5 mx-10">
        <div className="flex justify-center">{renderImgByCoin(coin)}</div>
        {coinUpper()}
      </div>
    </button>
  );
};

export default CryptoButton;
