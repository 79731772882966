import React, { useState, useEffect } from "react";
import { Navigate, Router, Routes, Route } from "react-router-dom";
import Modal from "react-modal";

Modal.setAppElement("#root");

const AccessCodeModal = (props) => {
  const {
    showModal,
    setShowModal,
    authenticated,
    setAuthenticated,
    SECRETPASS,
  } = props;

  const [pass, setPass] = useState("");
  const [wrongPass, setWrongPass] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleSubmit = () => {
    // setPassword(pass);

    if (pass === SECRETPASS) {
      window.sessionStorage.setItem("authenticated", true);
      setAuthenticated(true);
      return (
        <Router>
          <Navigate to="/index" />
        </Router>
      );
    } else {
      setWrongPass(true);
    }
  };

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={toggleModal}
      contentLabel="Access Code"
      className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative bg-verylightpurple dark:bg-darkbg p-1 rounded">
        <button
          className="float-right pr-4 text-darkbg dark:text-lightbg font-bold pt-2"
          onClick={toggleModal}
        >
          X
        </button>
        <form
          className="pt-16 pb-10 pr-10"
          autoComplete="on"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(pass);
          }}
        >
          <div className="md:flex md:items-center pb-5">
            <div className="md:w-1/3">
              <label className="block text-darkbg dark:text-lightbg font-bold md:text-right text-base mb-1 md:mb-0 pr-4 text-center">
                Access Code:
              </label>
            </div>
            <div className="md:w-2/3">
              <input
                type="text"
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:shadow-outline"
                name="authcode"
                value={pass}
                onChange={(e) => setPass(e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-col justify-center items-center pt-2 pl-10">
            <div>
              <input
                type="submit"
                className="bg-buttonlink hover:bg-buttonlink-hover text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                value="Submit"
              />
            </div>
          </div>
          {wrongPass ? (
            <div className="flex flex-col justify-center items-center pl-10">
              <div>
                <h1 className="md:flex md:items-center pt-3 text-red-500 text-base">
                  Wrong code!
                </h1>
              </div>
            </div>
          ) : null}
        </form>
      </div>
    </Modal>
  );
};

export default AccessCodeModal;
