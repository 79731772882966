import React, { useState, useEffect, useContext } from "react";

import Chart from "react-apexcharts";
import axios from "axios";
import { fromUnixTime, format } from "date-fns";

import { apiLinks } from "../../utils/links";
import { ThemeContext } from "../Theme/ThemeContext";

const CandlestickGraph = (props) => {
  const currTheme = useContext(ThemeContext);
  const { coinName, setCoinName } = props;

  const [loading, setLoading] = useState(false);
  const [candle, setCandle] = useState([]);
  const [daysSelected, setDaysSelected] = useState(52);
  const [maxDays, setMaxDays] = useState(13);

  const handleOptionChange = (e) => {
    // console.log(e.target.value);
    setDaysSelected(e.target.value);

    handleDayChanges(e.target.value);
  };

  const handleDayChanges = (days) => {
    if (days == 52) {
      setMaxDays(13);
    } else if (days == 128) {
      setMaxDays(32);
    } else {
      //days = 200
      setMaxDays(50);
    }
  };

  useEffect(() => {
    setLoading(true);

    axios({
      url: apiLinks.candlestickGraph + coinName,
      method: "get",
    })
      .then((res) => {
        // console.log(res.data);
        var tempCandle = [];

        res.data.slice(-maxDays).forEach((e) => {
          var dt = fromUnixTime(e[0] / 1000);
          var details = [];
          var candleObject;

          details.push(e[1]);
          details.push(e[2]);
          details.push(e[3]);
          details.push(e[4]);

          candleObject = { x: dt, y: details };
          tempCandle.push(candleObject);
        });

        setCandle(tempCandle);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [maxDays, setMaxDays]);

  const options = {
    chart: {
      type: "candlestick",
      height: 350,
    },
    noData: {
      text: "Loading...",
    },
    theme: { mode: currTheme.theme },
    chart: {
      background: "rgba(0, 0, 0, 0)",
    },
    title: {
      text: coinName[0].toUpperCase() + coinName.substring(1) + ` Candlestick`,
      align: "center",
    },
    grid: {
      borderColor: currTheme.theme === "dark" ? "#fcfcfc" : "#e7e7e7",
      row: {
        colors: currTheme.theme === "dark" ? [] : ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        formatter: function (val) {
          return format(val, "dd MMM yyyy");
        },
      },
    },
    yaxis: {
      tooltip: {
        enabled: true,
      },
    },
  };

  return (
    <div>
      <form className="flex flex-row space-x-8">
        <div className="radio">
          <label className="text-sm">
            <input
              type="radio"
              value="52"
              checked={daysSelected == 52}
              onChange={handleOptionChange}
            />
            52 days
          </label>
        </div>
        <div className="radio">
          <label className="text-sm">
            <input
              type="radio"
              value="128"
              checked={daysSelected == 128}
              onChange={handleOptionChange}
            />
            128 days
          </label>
        </div>
        <div className="radio">
          <label className="text-sm">
            <input
              type="radio"
              value="200"
              checked={daysSelected == 200}
              onChange={handleOptionChange}
            />
            200 days
          </label>
        </div>
      </form>

      {candle && (
        <div>
          <Chart
            options={options}
            series={[{ data: candle }]}
            type="candlestick"
            height={350}
            width={1000}
          />
        </div>
      )}
    </div>
  );
};

export default CandlestickGraph;
