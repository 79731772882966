import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import ThemeToggle from "../Theme/ThemeToggle";
import { ThemeContext } from "../Theme/ThemeContext";

import TopNavbarNoLogin from "../TopNavNoLogin/TopNavNoLogin";
import TopNavNoLoginSkele from "../TopNavNoLogin/TopNavNoLoginSkele";

import BannerPic from "../../assets/images/dyor_logo_dark.png";
import BannerPicBlack from "../../assets/images/dyor_logo_light.png";

const LoginLogoAndTopNav = (props) => {
  const { theme } = React.useContext(ThemeContext);

  return (
    <>
      <div className="md:flex md:items-center pb-5 pt-10">
        <div className="md:w-1/6"></div>
        {theme === "dark" ? (
          <NavLink to="/" className="md:w-1/6 md:bg-contain py-8">
            <img
              src={BannerPic}
              alt="dyor.futurecenter.ventures"
              className=""
            />
          </NavLink>
        ) : (
          <NavLink to="/" className="md:w-1/6 md:bg-contain py-8">
            <img
              src={BannerPicBlack}
              alt="dyor.futurecenter.ventures"
              className=""
            />
          </NavLink>
        )}
        <div className="md:w-1/6"></div>
        <div className="md:w-2/6 text-center">
          <TopNavNoLoginSkele children={<TopNavbarNoLogin />} />
        </div>
        <div className="md:w-1/6"></div>
      </div>
    </>
  );
};

export default LoginLogoAndTopNav;
