export const apiLinks = {
  fearGreedGeneral: "https://api.alternative.me/fng/",
  fearGreedCoin: "https://api.alternative.me/v2/ticker/",
  //express server link:
  rocketMovement:
    "https://coingryphon-apiserver-production.up.railway.app/rocket/",
  coinMarketCal: "https://coingryphon-apiserver-production.up.railway.app/cal/",
  coinMarketCalAllCoins:
    "https://coingryphon-apiserver-production.up.railway.app/allcal/",
  swingTrading: "https://api.taapi.io/bulk",
  twitterUserSearch:
    "https://coingryphon-apiserver-production.up.railway.app/twitter/user/",
  twitterUsername:
    "https://coingryphon-apiserver-production.up.railway.app/twitter/name/",
  twitterUserTimeline:
    "https://coingryphon-apiserver-production.up.railway.app/twitter/timeline/",
  twitterUserNoDateTimeline:
    "https://coingryphon-apiserver-production.up.railway.app/twitter/timeline/nodate/",
  coinEvalCoinDetails:
    "https://coingryphon-cron-server-production.up.railway.app/coinsearch/",
  coinEvalCoinTeamMemberInfo:
    "https://coingryphon-cron-server-production.up.railway.app/teamsearch/",
  btcRsiGraph:
    "https://coingryphon-cron-server-production.up.railway.app/techanalysis/rsi/btc",
  btcMacdGraph:
    "https://coingryphon-cron-server-production.up.railway.app/techanalysis/macd/btc",
  ethRsiGraph:
    "https://coingryphon-cron-server-production.up.railway.app/techanalysis/rsi/eth",
  ethMacdGraph:
    "https://coingryphon-cron-server-production.up.railway.app/techanalysis/macd/eth",
  candlestickGraph:
    "https://coingryphon-cron-server-production.up.railway.app/techanalysis/candle/",
  cryptoNews: "https://coingryphon-apiserver-production.up.railway.app/news",
  reportApi: "https://dashboard.digitalassetanalytica.com/api/",
  nftSearchApi: "https://nftapi-production.up.railway.app/searchnft/",
  nftSearch2Api: "https://nftapi-production.up.railway.app/search2nft/",
  proofOfReservesApi:
    "https://coingryphon-cron-server-production.up.railway.app/por/",
  coinNewsApi:
    "https://coingryphon-cron-server-production.up.railway.app/coinnews/",
};
