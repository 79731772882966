export const TutorialInfo = [
  {
    sectionNo: 1,
    sectionName: "Getting started",
    questions: [
      "DYOR Legal Disclaimer - For Education Purposes Only",
      "What is DYOR?",
      "Why use DYOR?",
    ],
    ytLink: ["3qyfz0B-Q_4", "KBmXAUvRniU", "BMQwJwRGjzo"],
    tooltipText: [
      "Legal disclaimer - Click for video",
      "What is DYOR? Click for video",
      "Why use DYOR? Click for video",
    ],
  },
  // {
  //   sectionNo: 2.1,
  //   sectionName: "Google News\n(temporary unavailable)",
  //   questions: ["What is Google News, and why is it important?"],
  //   ytLink: ["Wko1APx_dc4"],
  //   tooltipText: ["Why check Google News? Click for video"],
  // },
  {
    sectionNo: 2.1,
    sectionName: "Crypto News",
    questions: ["What is Crypto News, and why is it important?"],
    ytLink: ["_HK6NjNc9qY"],
    tooltipText: ["Why check Crypto News? Click for video"],
  },
  {
    sectionNo: 2.2,
    sectionName: "Calendar",
    questions: ["What is Calendar, and why is it important?"],
    ytLink: ["QLle5N3h5Aw"],
    tooltipText: ["Why check Calendar? Click for video"],
  },
  {
    sectionNo: 2.3,
    sectionName: "Airdrops",
    questions: ["What are airdrops, and why are they important?"],
    ytLink: ["-LRTlfYRGoQ"],
    tooltipText: ["What are airdrops? Click for video"],
  },
  {
    sectionNo: 3.1,
    sectionName: "Coin Evaluation",
    questions: [
      "What is tokenomics, and why is it important?",
      "What is token allocation, and why is it important?",
      "What is the difference between coins and tokens, and why is it important?",
      "What is a whitepaper, and why is it important?",
      "What is consensus, and why is it important?",
      "What are validators and miners, and why is it important?",
      "What is blockchain architecture, and why is it important?",
      "What are virtual machines, and why is it important?",
      "What is sharding, and why is it important?",
      "Why is researching a crypto project's tokenomics important?",
      "Why is researching tokenomics in a crypto project's whitepaper important?",
      "How to use the Coin Evaluation tool overview",
    ],
    ytLink: [
      "tNp8sSAvCT8",
      "f3-Uk4sghhc",
      "jzkojYNe6M8",
      "ks0p2K_TZro",
      "RAne8v6XIXw",
      "Dc_lyitJ4Ys",
      "zHMFSfVJKeo",
      "0CjwcLMeq-Q",
      "fj37Efdn_2Q",
      "nM3yB_F9zRI",
      "XBLcB4CvpC8",
      "YvL1p11PUIM",
    ],
    tooltipText: [
      "What is tokenomics? Click for video",
      "What is token allocation? Click for video",
      "What is the difference between coins and tokens? Click for video",
      "What is a whitepaper? Click for video",
      "What is consensus? Click for video",
      "What are validators and miners? Click for video",
      "What is blockchain architecture? Click for video",
      "What are virtual machines? Click for video",
      "What is sharding? Click for video",
      "Why is researching a crypto project's tokenomics important? Click for video",
      "Why is researching tokenomics in a crypto project's whitepaper important? Click for video",
      "How to use the Coin Evaluation tool? Click for video",
    ],
  },
  {
    sectionNo: 3.2,
    sectionName: "NFT Evaluation",
    questions: [
      "What is artistic integrity in NFT, and why is it important?",
      "What is scarcity in NFT, and why is it important?",
      "Why is researching an NFT's founding team important?",
      "What is an NFT's product roadmap, and why is it important?",
      "What is an NFT's DeFi capability, and why is it important?",
      "What are NFT's incentives, and why is it important?",
      "What is the ratio for NFT investing, and why is it important?",
      "What is an NFT whitelist, and why is it important?",
      "What is an NFT secondary market, and why is it important?",
      "What are mint prices for NFTs, and why is it important?",
    ],
    ytLink: [
      "dE9U7XeAnHU",
      "-s2BZAoXfqw",
      "-pET_e2-juo",
      "YeBMtRbU64s",
      "ZFZlYRDxRo0",
      "gc6PawQfCjc",
      "ajuFTPvQXAM",
      "HbX-O9Ie7ZU",
      "oy4yLKrf_nM",
      "d33EgvlTBuo",
    ],
    tooltipText: [
      "What is artistic integrity in NFT? Click for video",
      "What is scarcity in NFT? Click for video",
      "Why is researching an NFT's founding team important? Click for video",
      "What is an NFT's product roadmap? Click for video",
      "What is an NFT's DeFi capability? Click for video",
      "What are NFT's incentives? Click for video",
      "What is the ratio for NFT investing? Click for video",
      "What is an NFT whitelist? Click for video",
      "What is an NFT secondary market? Click for video",
      "What are mint prices for NFTs? Click for video",
    ],
  },
  {
    sectionNo: 4,
    sectionName: "Exchanges",
    questions: [
      "What is Proof of Reserves, and why is it important?",
      "What is Merkle Tree for in Proof of Reserves, and why is it important?",
      "What is Supply Vesting in a Cryptocurrency or NFT project, and why is it important?",
    ],
    ytLink: ["XcCVpwkphvs", "wbfyNu37lP0", "s-GA07-RjwM"],
    tooltipText: [
      "What is Proof of Reserves? Click for video",
      "What is Merkle Tree for in Proof of Reserves? Click for video",
      "What is Supply Vesting in a Cryptocurrency or NFT project? Click for video",
    ],
  },
  {
    sectionNo: 5,
    sectionName: "Data",
    questions: [
      "What is on chain metrics, and why is it important?",
      "What are PR tactics in crypto investing, and why is it important?",
      "What is artificial price stimulation, and why is it important?",
      "How to spot con artistry in crypto?",
      "How to spot crypto fake projects and protect your investments?",
      "How to identify whale collusions?",
    ],
    ytLink: [
      "NwQyRdcsyPg",
      "U2EIxIxbJBA",
      "kUi6mQhWNtk",
      "avwWSqQB0Qo",
      "FZ5_cNOSQmk",
      "JyQlNzHL7Sg",
    ],
    tooltipText: [
      "What is on chain metrics? Click for video",
      "What are PR tactics in crypto investing? Click for video",
      "What is artificial price stimulation? Click for video",
      "How to spot con artistry in crypto? Click for video",
      "How to spot crypto fake projects and protect your investments? Click for video",
      "How to identify whale collusions? Click for video",
    ],
  },
  {
    sectionNo: 6,
    sectionName: "Charting",
    questions: [
      "What is machine learning on crypto trading, and why is it important?",
      "What are risks in the digital asset market, and why is it important?",
      "What is a diversified digital asset portfolio, and why is it important?",
      "What is the MacD, and why is it important?",
      "What is the RSI, and why is it important?",
    ],
    ytLink: [
      "mzc9IO-850w",
      "LY9KEeU7xRo",
      "I9eQ0OiXgZU",
      "RVT1r1gpYCk",
      "0ezCxWbP0A8",
    ],
    tooltipText: [
      "What is machine learning on crypto trading? Click for video",
      "What are risks in the digital asset market? Click for video",
      "What is a diversified digital asset portfolio? Click for video",
      "What is the MacD? Click for video",
      "What is the RSI? Click for video",
    ],
  },
  {
    sectionNo: 7,
    sectionName: "Rewards",
    questions: ["What are the rewards for using DYOR?"],
    ytLink: ["DGCDnp_4gb4"],
    tooltipText: ["What are the rewards for using DYOR? Click for video"],
  },
];
