import React, { useState, useEffect } from "react";

import { differenceInCalendarDays } from "date-fns";

import DailyCheckInHeader from "./DailyCheckInHeader";
import TotalCoins from "./TotalCoins";
import CollectCoinsPanel from "./CollectCoinsPanel";
import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";

const DailyCheckIn = (props) => {
  const { email } = props;

  const [coins, setCoins] = useState(0);
  const [collectedCoins, setCollectedCoins] = useState(false);
  const [currDayNo, setCurrDayNo] = useState(0);
  const [collectedDate, setCollectedDate] = useState("");
  const todayDate = zonedTimeToUtc(Date.now());

  useEffect(() => {
    var dt = window.sessionStorage.getItem("coinCollectedDate");

    setCoins(window.sessionStorage.getItem("coinsNo"));
    setCurrDayNo(window.sessionStorage.getItem("daysSigned"));
    // setCollectedDate(new Date(dt));

    //determine if collect coins button should be disabled
    //never collected coins before
    if (dt === "null") {
      setCollectedCoins(false);
    } else if (differenceInCalendarDays(new Date(dt), todayDate) !== 0) {
      //TODO: fix bug related to datetime utc here (sgt time is ahead of utc time, causing setCollectedCoins to be set to false when it is still counted as same day on the server backend)
      //NOT collected today
      setCollectedCoins(false);
    } else {
      //collected today
      setCollectedCoins(true);
    }
  }, []);

  return (
    <div className="container mx-auto md:pb-64 pb-28">
      <DailyCheckInHeader />
      <p className="pt-10 pb-8 text-base">
        Collect Gryphon coins and claim rewards!
      </p>
      <TotalCoins coins={coins} setCoins={setCoins} />
      <div className="pb-10" />
      <CollectCoinsPanel
        email={email}
        coins={coins}
        setCoins={setCoins}
        collectedCoins={collectedCoins}
        setCollectedCoins={setCollectedCoins}
        currDayNo={currDayNo}
        setCurrDayNo={setCurrDayNo}
      />
    </div>
  );
};

export default DailyCheckIn;
