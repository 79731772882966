import React, { useState, useEffect } from "react";

const PictureComponent = (props) => {
  const { imglink } = props;
  const image = require(`../../assets/images/HelpCenter/${imglink}`).default;
  return (
    <img src={image} className="pt-4 object-scale-down h-150 inline-block" />
  );
};

export default PictureComponent;
