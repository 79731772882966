import React, { useContext } from "react";

import { ThemeContext } from "./Theme/ThemeContext";

import lostlight from "../assets/images/404.png";
import lostdark from "../assets/images/mainwhite.png";

const ErrorPage = (props) => {
  const { theme } = useContext(ThemeContext);

  return (
    <div className="pt-40 pb-56">
      <h1 className="text-8xl">Page Not found</h1>
      {/* <img className="py-40" src={lostdark} /> */}
      {theme == "dark" ? (
        <img className="py-40" src={lostdark} />
      ) : (
        <img className="py-40" src={lostlight} />
      )}
    </div>
  );
};
export default ErrorPage;
