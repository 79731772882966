import React, { useState, useEffect } from "react";

const RocketMovementHeader = (props) => {
  return (
    <div>
      <h1 className="text-center text-5xl flex justify-center pt-5">
        Selected Wallet Movements
      </h1>
      <h3 className="text-center text-xl flex justify-center pt-5">
        (Over 500k USD shown)
      </h3>
    </div>
  );
};

export default RocketMovementHeader;
