import React, { useState, useEffect } from "react";

import ReactModal from "react-modal";
import Linkify from "linkify-react";

// ReactModal.setAppElement("#root");

const CoinNewsModal = (props) => {
  const { info, showModal, setShowModal } = props;
  const [textContent, setTextContent] = useState("");

  useEffect(() => {
    var temp = info.content
      .replaceAll('<a href="', "")
      .replaceAll('">', " ")
      .replaceAll("</a>", "");

    setTextContent(temp);
  }, []);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      {textContent && (
        <ReactModal
          isOpen={showModal}
          onRequestClose={toggleModal}
          contentLabel="Coin News"
          className="outline-none focus:outline-none text-darkbg dark:text-lightbg lg:w-2/5 w-7/12 h-2/3 absolute top-1/4 left-1/3 overflow-y-auto"
        >
          <div className="bg-verylightpurple dark:bg-darkbg p-1 rounded pt-3">
            <button
              className="float-right pr-4 font-bold text-3xl sticky"
              onClick={toggleModal}
            >
              X
            </button>
            <div className="text-center p-5">
              <div className="font-bold text-xl pb-5">{info.title}</div>
              <div>
                Published: {new Date(`${info.published_at}`).toString()}
              </div>
              <div className="pb-5">Author(s): {info.author.name}</div>

              <Linkify
                options={{
                  rel: "noreferral",
                  target: "_blank",
                  className:
                    "text-linkifylink hover:text-linkifylink-hover hover:underline",
                  format: (value, type) => {
                    if (type === "url") {
                      value = "";
                    }
                    return value;
                  },
                }}
                tagName="div"
                className="text-justify whitespace-pre-line"
              >
                {textContent}
              </Linkify>
              {/* 
              <div className="text-justify whitespace-pre-line">
                {info.content}
              </div> */}
            </div>
          </div>
        </ReactModal>
      )}
    </>
  );
};

export default CoinNewsModal;
