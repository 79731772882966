import React, { useState, useEffect } from "react";

import { QuestionList } from "../Questions/QuestionList";

const BiteResult = (props) => {
  const { moduleName } = props;
  const [qnList, setQnList] = useState(
    QuestionList.find((element) => element.module == moduleName)
  );
  const [qns, setQns] = useState([]);
  const [isAnswered, setIsAnswered] = useState(false);

  useEffect(() => {
    setIsAnswered(window.sessionStorage.getItem(moduleName));
  }, []);

  useEffect(() => {
    var allQns = [];

    qnList.qns.forEach((qn) => {
      var data = window.sessionStorage.getItem(qn);

      if (data) {
        // console.log(data);
        allQns.push({
          qn: qn,
          ans: data,
        });
      } else {
        allQns.push({
          qn: qn,
          ans: "",
        });
      }
    });
    setQns(allQns);
  }, [isAnswered, setIsAnswered]);

  if (isAnswered === "true") {
    return (
      <div>
        <div>
          {qns.map((element) => {
            return (
              <div className="px-12">
                <div className="text-lg text-left">
                  <span className="font-bold">{element.qn}</span>{" "}
                  <p className="italic">{element.ans}</p>
                </div>
                <br />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return null;
};

export default BiteResult;
