import React, { useState, useEffect } from "react";
import InfoBox from "./InfoBox";

const InfoPriceGrid = (props) => {
  const { coinHrChange, coinDayChange, coinWeekChange } = props;

  return (
    <div className="grid grid-flow-row md:grid-flow-col auto-rows-max md:auto-cols-max md:gap-4 md:grid-cols-3">
      <InfoBox
        title="1h"
        value={parseFloat(coinHrChange).toFixed(3) + " %"}
        style={`${
          parseFloat(coinHrChange) < 0.0 ? "text-red-500" : "text-green-500"
        }`}
      />
      <InfoBox
        title="24h"
        value={parseFloat(coinDayChange).toFixed(3) + " %"}
        style={`${
          parseFloat(coinDayChange) < 0.0 ? "text-red-500" : "text-green-500"
        }`}
      />
      <InfoBox
        title="7d"
        value={parseFloat(coinWeekChange).toFixed(3) + " %"}
        style={`${
          parseFloat(coinDayChange) < 0.0 ? "text-red-500" : "text-green-500"
        }`}
      />
    </div>
  );
};

export default InfoPriceGrid;
