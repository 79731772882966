import React, { useState, useEffect } from "react";

const Background = ({ children }) => {
  return (
    <div className="dark:bg-darkbg dark:text-lightbg light:bg-lightbg light:text-darkbg transition-all">
      {children}
    </div>
  );
};

export default Background;
