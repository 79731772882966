import React from "react";
import { FaSun, FaMoon } from "react-icons/fa";
import { ThemeContext } from "./ThemeContext";

const ThemeToggle = (props) => {
  const { theme, setTheme } = React.useContext(ThemeContext);
  const { style } = props;

  return (
    <div
      className={`transition duration-500 ease-in-out rounded-full p-2 ${style}`}
    >
      {theme === "dark" ? (
        <FaSun
          onClick={() => {
            setTheme(theme === "dark" ? "light" : "dark");
            // console.log("sun: " + theme);
          }}
          className="text-gray-500 dark:text-gray-400 text-2xl cursor-pointer"
        />
      ) : (
        <FaMoon
          onClick={() => {
            setTheme(theme === "dark" ? "light" : "dark");
            // console.log("moon: " + theme);
          }}
          className="text-gray-500 dark:text-gray-400 text-2xl cursor-pointer"
        />
      )}
    </div>
  );
};

export default ThemeToggle;
