import React, { useState, useEffect } from "react";

const QuestionHeader = (props) => {
  return (
    <div>
      <h1 className="py-4">Market Insights</h1>
      <hr className="dark:border-lightbg border-slategrey" />
    </div>
  );
};

export default QuestionHeader;
