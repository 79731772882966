import React, { useState, useEffect } from "react";

import { CSSTransition } from "react-transition-group";
import { HiChevronDoubleDown, HiChevronDoubleUp } from "react-icons/hi";

import trendImg from "../../assets/images/SwingTrade/trend.png";
import candleImg from "../../assets/images/SwingTrade/moving.png";

import "../CoinEval/CollapsiblePanel.css";

const PossibleBuySignalsPanel = (props) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="whitespace-pre-line">
      <div>
        <button
          type="button"
          onClick={() => {
            setIsVisible(!isVisible);
          }}
          className="text-3xl"
        >
          Miscellaneous
          {isVisible ? (
            <HiChevronDoubleUp className="pl-4 pt-2 float-right" />
          ) : (
            <HiChevronDoubleDown className="pl-4 pt-2 float-right" />
          )}
        </button>
      </div>
      <CSSTransition in={!isVisible} timeout={0} classNames="test">
        {!isVisible ? (
          <></>
        ) : (
          <>
            <div className="text-lg px-4 pt-4 grid grid-cols-5 text-center">
              <div className="col-span-1"></div>
              <div className="col-span-3 text-2xl pt-10 font-bold">
                Possible Buy Signals
              </div>
              <div className="col-span-1"></div>
            </div>

            <div className="grid grid-cols-5 pt-2">
              <div className="col-span-1"></div>
              <div className="col-span-3">
                <div className="grid text-center grid-cols-4 px-4">
                  <div className="col-span-1"></div>
                  <div className="click-zoom col-span-2">
                    <label>
                      <input type="checkbox" />
                      <img src={trendImg} alt="trends" />
                    </label>
                  </div>
                  <div className="col-span-1"></div>
                </div>

                <div className="grid text-center grid-cols-4 pt-5 px-4">
                  <div className="col-span-1"></div>
                  <div className="click-zoom col-span-2">
                    <label>
                      <input type="checkbox" />
                      <img src={candleImg} alt="candlestick" />
                    </label>
                  </div>
                  <div className="col-span-1"></div>
                </div>
              </div>
              <div className="col-span-1"></div>
            </div>
          </>
        )}
      </CSSTransition>
    </div>
  );
};

export default PossibleBuySignalsPanel;
