import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  FaTwitter,
  FaFacebookSquare,
  FaDiscord,
  FaLinkedin,
  FaAngellist,
} from "react-icons/fa";

import "../../App.css";

const Footer = (props) => {
  const [date, setDate] = useState(null);

  useEffect(() => {
    const d = new Date();
    let year = d.getFullYear();
    setDate(year);
  }, []);

  return (
    // <div className="md:pt-64 pt-28">
    <div className="h-full">
      <hr className="w-screen" />
      <div className="flex flex-auto grid text-center grid-cols-4">
        <div></div>

        <div className="content">
          <div className="pt-5 pb-1 text-base font-bold">Legal</div>
          <div className="grid justify-items-center">
            <NavLink
              to="/terms"
              target="_blank"
              className="text-buttonlink text-sm py-1"
            >
              Terms of Service
            </NavLink>
            <NavLink
              to="/privacy"
              target="_blank"
              className="text-buttonlink text-sm py-1"
            >
              Privacy Policy
            </NavLink>
            <a
              className="py-1 text-buttonlink text-sm"
              href="mailto:hello@futurecenter.ventures"
              target="_blank"
            >
              Contact Us
            </a>
          </div>
        </div>

        <div className="content">
          <div className="pt-5 pb-1 text-base font-bold">Social</div>
          <div className="grid md:grid-cols-7 grid-row-1 justify-items-center">
            <div></div>
            <a
              className="py-1"
              href="https://twitter.com/dyor_fcv"
              target="_blank"
              rel="noreferrer"
            >
              <FaTwitter />
            </a>
            <a
              className="py-1"
              href="https://www.facebook.com/dyorofficial/"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebookSquare />
            </a>
            <a
              className="py-1"
              href="https://discord.gg/xhvGBXMbaW"
              target="_blank"
              rel="noreferrer"
            >
              <FaDiscord />
            </a>
            <a
              className="py-1"
              href="https://wellfound.com/company/dyor/"
              target="_blank"
              rel="noreferrer"
            >
              <FaAngellist />
            </a>
            <a
              className="py-1"
              href="https://www.linkedin.com/company/dyorfuturecenter"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedin />
            </a>
            <div></div>
          </div>
        </div>
        <div></div>
      </div>

      <div className="pt-7">
        <hr />
      </div>
      <div className="pt-2 pb-2 text-center text-base">
        Copyright© <NavLink to="/index">DYOR Crypto - Future Center</NavLink>{" "}
        {date}
      </div>
    </div>
  );
};

export default Footer;
