import React, { useState, useEffect } from "react";

const ResultsHeader = (props) => {
  return (
    <div>
      <h1 className="text-center text-5xl flex justify-center py-5">
        Your insights
      </h1>
      <div className="text-lg">
        All entries of each section must be complete to see results.
      </div>
    </div>
  );
};

export default ResultsHeader;
