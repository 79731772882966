import React, { useState, useEffect } from "react";
import Linkify from "linkify-react";

// import { apiLinks } from "../../utils/links";

const Checkbox = (props) => {
  const { data, index, dataArr, setDataArr } = props;
  const [isChecked, setIsChecked] = useState(
    dataArr[index] === "" ? false : dataArr[index]
  );

  useEffect(() => {
    var temp = dataArr;
    temp[index] = isChecked;
    // console.log(temp);
    setDataArr(temp);
  }, []);

  // useEffect(() => {
  //   var temp = JSON.stringify(linkify.find(data.qn));
  //   temp = JSON.parse(temp);
  //   var linkurl = "";
  //   var linkurlvalue = "";
  //   var tempqn = data.qn;
  //   var linkurlStartPos = 0;
  //   var linkurlEndPos = 0;

  //   if (temp !== []) {
  //     // console.log(temp);
  //     temp.forEach((link) => {
  //       // console.log(link.href);
  //       linkurl = link.href;
  //       linkurlvalue = link.value;
  //       linkurlStartPos = link.start;
  //       linkurlEndPos = link.end;
  //     });

  //     if (linkurl !== "") {
  //       setQnsLink(linkurl);
  //       var newStrVal = "";

  //       newStrVal =
  //         tempqn.substring(0, linkurlStartPos) +
  //         "<a>" +
  //         tempqn.substring(linkurlStartPos, linkurlEndPos) +
  //         "</a>" +
  //         tempqn.substring(linkurlEndPos, tempqn.length);

  //       setQnText(newStrVal);
  //     }
  //   }
  // }, []);

  const handleCheck = () => {
    setIsChecked(!isChecked);
    // var temp = [...dataArr];
    var temp = dataArr;
    temp[index] = !isChecked;
    // console.log(temp);
    setDataArr(temp);
  };

  // const callIframe = (value, type) => {
  //   return <IframeCaller link={value} />;
  // };

  return (
    // <Linkify
    //   tagName="div"
    //   className="block pb-4"
    //   options={{
    //     rel: "noreferral",
    //     target: "_blank",
    //     className: "text-linkifylink hover:text-linkifylink-hover",
    //     tagName: "span",
    //     // formatHref: {
    //     //   url: (href) => apiLinks.iframeServer + encodeURIComponent(href),
    //     // },
    //     format: callIframe,
    //   }}
    // >
    <Linkify
      tagName="div"
      className="block pb-4"
      options={{
        rel: "noreferral",
        target: "_blank",
        className: "text-linkifylink hover:text-linkifylink-hover",
      }}
    >
      <div className="grid grid-cols-8 text-lg pt-10 inline-flex items-center">
        <label className="pr-4 col-span-6 text-justified whitespace-pre-line text-xl">
          {data.qn}
        </label>
        <div className="col-span-1"></div>
        <input
          className="w-8 h-8 rounded col-span-1"
          type="checkbox"
          name={Object.keys(data)}
          defaultChecked={isChecked}
          value={isChecked}
          onChange={handleCheck}
        />
      </div>
    </Linkify>
  );
};

export default Checkbox;
