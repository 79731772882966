import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const SingleButton = (props) => {
  const { title, link } = props;

  return (
    <div className="block justify-center">
      <Link
        to={link}
        className="border-2 rounded-md p-4 text-4xl bg-startbtnbg dark:bg-startbtnbg text-lightbg dark:text-lightbg hover:bg-lightbg hover:text-darkbg hover:border-darkbg dark:hover:border-lightbg dark:hover:bg-lightbg dark:hover:text-darkbg"
      >
        {title}
      </Link>
    </div>
  );
};

export default SingleButton;
