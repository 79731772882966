import React, { useState, useEffect } from "react";

const MultiCheckbox = (props) => {
  const { data, index, isChecked, setIsChecked, count } = props;
  //count is where multicheckbox data index end, so using count to - data.length, where data = options, it gives array index for starting
  const [ansIndex, setAnsIndex] = useState(count - data.length);

  const handleCheck = (value, index) => {
    var temp = isChecked;
    temp[index] = !isChecked[index];
    setIsChecked(temp);
  };

  return (
    <>
      {data.map((op, opIndex) => (
        <tr>
          <td
            className={`border-r-2 border-gray-400 md:text-md text-sm px-2 ${
              data.length - 1 === opIndex ? "" : "border-b-2"
            }`}
          >
            {Object.values(op)}
          </td>
          <td
            className={`border-gray-400 text-center ${
              data.length - 1 === opIndex ? "" : "border-b-2"
            }`}
          >
            <input
              type="checkbox"
              name={Object.keys(op)}
              defaultChecked={isChecked[ansIndex + opIndex]}
              value={isChecked[ansIndex + opIndex]}
              onChange={() => {
                handleCheck(Object.values(op), ansIndex + opIndex);
              }}
            />
          </td>
        </tr>
      ))}
    </>
  );
};

export default MultiCheckbox;
