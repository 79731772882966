import React, { useState, useEffect } from "react";
import ThemeToggle from "../Theme/ThemeToggle";

const TopNavNoLoginSkele = ({ props, children }) => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <nav className="container flex flex-wrap justify-between items-center mx-auto pt-4">
      <button
        data-collapse-toggle="mobile-menu"
        type="button"
        data-toggle="collapse"
        className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        aria-controls="mobile-menu"
        aria-expanded="false"
        onClick={handleNav}
      >
        <span className="sr-only">Menu</span>
        <svg
          className="block h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
        <svg
          className="hidden h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <div className="flex-1 flex items-center justify-center md:items-stretch md:justify-end">
        <div className="hidden sm:block sm:ml-6">
          <div className="flex space-x-4">{children}</div>
        </div>
        {/*  Mobile menu, show/hide based on menu state.  */}
        {nav ? (
          <>
            <div className="sm:hidden" id="mobile-menu">
              <div className="px-2 pt-2 pb-8 space-y-1">{children}</div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </nav>
  );
};

export default TopNavNoLoginSkele;
