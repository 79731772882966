import React, { useState, useEffect } from "react";

const InfoBox = (props) => {
  const { value, title, style } = props;
  return (
    <div
      className={`border-4 border-indigo-600 m-5 dark:bg-gray-700 bg-gray-100`}
    >
      <div className="p-2 text-left text-sm md:p-5 md:text-2xl font-bold">
        {title}:{" "}
      </div>
      <div className={`pb-10 text-base md:pb-15 md:text-xl ${style}`}>
        {value}
      </div>
    </div>
  );
};

export default InfoBox;
