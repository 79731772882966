import React, { useState, useEffect } from "react";
import TwitterModal from "../TwitterModal/TwitterModal";

const CoinName = (props) => {
  const {
    coinName,
    setCoinName,
    evalSectionVisible,
    setEvalSectionVisible,
    searchedCoinName,
    setSearchedCoinName,
    searchingCoin,
  } = props;

  const [isSearchEmpty, setIsSearchEmpty] = useState(false);

  const handleChange = (e) => {
    setCoinName(e.target.value);
  };

  const getCoinData = (name) => {
    if (name.trim() != "") {
      setIsSearchEmpty(false);
      setEvalSectionVisible(true);
      setSearchedCoinName(name.trim());
      window.sessionStorage.setItem("CoinName", name.trim());
      // setSearchingCoin(true);
      //get twitter component & results with searchedCoinName
    } else {
      setIsSearchEmpty(true);
      setEvalSectionVisible(false);
      setSearchedCoinName("");
      // setSearchingCoin(false);
    }
  };

  return (
    <div className="pb-8">
      <form
        className="grid lg:grid-cols-6 grid-rows-3 gap-4 justify-evenly items-center"
        onSubmit={(e) => {
          e.preventDefault();
          getCoinData(coinName);
        }}
      >
        <span className="lg:col-span-1 lg:block hidden" />
        <label className="lg:col-span-1 row-span-1">Coin Name: </label>
        <input
          type="text"
          name="coinname"
          className="dark:bg-slategrey bg-lightbg border rounded-lg lg:col-span-2 row-span-1 w-4/5 justify-self-center lg:w-full"
          onChange={(e) => {
            handleChange(e);
          }}
          value={coinName}
        />
        {/* <span className="px-4" /> */}
        <input
          type="submit"
          disabled={searchingCoin}
          className="bg-orangebtn hover:bg-lighterorangebtn text-white text-lg font-bold py-2 rounded focus:outline-none focus:shadow-outline lg:col-span-1 row-span-1 w-4/5 justify-self-center lg:w-full cursor-pointer disabled:cursor-wait"
          value="Search Coin"
        />
        <span className="lg:col-span-1 lg:block hidden" />
      </form>
      {searchingCoin && (
        <div className="text-center text-red-500 py-5">
          Please wait, loading...
        </div>
      )}
      {isSearchEmpty && (
        <div className="text-center text-red-500 py-5">
          Please enter a relevant search term!
        </div>
      )}
    </div>
  );
};

export default CoinName;
