import React, { useState, useEffect, useContext } from "react";

import Chart from "react-apexcharts";
import { fromUnixTime, format } from "date-fns";

import { ThemeContext } from "../Theme/ThemeContext";

const Graph = (props) => {
  const currTheme = useContext(ThemeContext);

  const { selectedExchange, graphData, selectedExchangeDisplayName } = props;

  const [todayDate, setTodayDate] = useState();
  const [coinNamesArr, setCoinNamesArr] = useState([]);
  const [coinReservesArr, setCoinReservesArr] = useState([]);
  const [coinChangeArr, setCoinChangeArr] = useState([]);

  useEffect(() => {
    var tempDate = format(fromUnixTime(graphData.t), "dd MMM yyyy");
    setTodayDate(tempDate);

    setCoinNamesArr(Object.keys(graphData.balances));
    setCoinReservesArr(Object.values(graphData.balances));
    setCoinChangeArr(Object.values(graphData.changes));

    // console.log(Object.keys(tempReserve[499])[0]);
    // console.log(Object.values(tempReserve[499])[0]);
  }, []);

  const reserveSeries = [
    {
      name: "No. of coins held",
      data: coinReservesArr,
    },
  ];

  const changeSeries = [
    {
      name: "No. of coins held",
      data: coinChangeArr,
    },
  ];

  const reserveOptions = {
    chart: {
      type: "bar",
      height: 350,
      background: "transparent",
    },
    colors: ["#72BCD4"],
    theme: { mode: currTheme.theme },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    grid: {
      borderColor: currTheme.theme === "dark" ? "#fcfcfc" : "#e7e7e7",
      row: {
        colors: currTheme.theme === "dark" ? [] : ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toLocaleString();
      },
      offsetY: 80,
      style: {
        fontSize: "12px",
        colors: currTheme.theme === "dark" ? ["#FFFF22"] : ["#304758"],
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: coinNamesArr,
    },
    yaxis: {
      title: {
        text: "No. of coins / tokens",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val.toLocaleString() + " coins/tokens";
        },
      },
    },
  };

  const changeOptions = {
    chart: {
      type: "bar",
      height: 350,
      background: "transparent",
    },
    colors: ["#FF4444"],
    theme: { mode: currTheme.theme },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
        //color changes for +ve and -ve values
        colors: {
          ranges: [
            {
              from: -1,
              to: -999999999999999,
              color: "#FF4444",
            },
            {
              from: 0,
              to: 999999999999999,
              color: "#3CB371",
            },
          ],
        },
      },
    },
    grid: {
      borderColor: currTheme.theme === "dark" ? "#fcfcfc" : "#e7e7e7",
      row: {
        colors: currTheme.theme === "dark" ? [] : ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toLocaleString();
      },
      offsetY: 80,
      style: {
        fontSize: "12px",
        colors: currTheme.theme === "dark" ? ["#FFFF22"] : ["#304758"],
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: coinNamesArr,
    },
    yaxis: {
      title: {
        text: "Change in no. of coins / tokens",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val.toLocaleString() + " coins/tokens change";
        },
      },
    },
  };

  return (
    <div className="">
      <>
        <div className="pb-5">{todayDate}</div>
        <div>{selectedExchangeDisplayName}'s Self-Reported Reserves</div>
        <Chart
          options={reserveOptions}
          series={reserveSeries}
          type="bar"
          height={500}
          width={1000}
        />

        <br />
        <hr />
        <br />

        <div>{selectedExchangeDisplayName}'s Change in Reserves</div>
        <Chart
          options={changeOptions}
          series={changeSeries}
          type="bar"
          height={500}
          width={1000}
        />
      </>
    </div>
  );
};

export default Graph;
