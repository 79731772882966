export const ExchangeList = [
  { display: "Binance", value: "binance" },
  { display: "Bitfinex", value: "bitfinex" },
  { display: "BitMEX", value: "bitmex" },
  { display: "Bybit", value: "bybit" },
  { display: "Cake DeFi", value: "cakedefi" },
  { display: "Checksig", value: "checksig.com" },
  { display: "Crypto.com", value: "crypto.com" },
  { display: "Deribit", value: "deribit" },
  { display: "Huobi", value: "huobi" },
  { display: "KuCoin", value: "kucoin" },
  { display: "OKX", value: "okex" },
  { display: "SwissBorg", value: "swissborg" },
];
