import React, { useState, useEffect } from "react";

const Button = (props) => {
  const { name, type, newPrev, setNewPrev, setIsError } = props;

  return (
    <>
      <button
        className={`border border-2 rounded text-2xl p-4 ${
          type === "new"
            ? "border-green-500 text-green-500 hover:bg-green-500 hover:text-white"
            : "border-gray-700 dark:border-gray-300 hover:bg-darkbg hover:text-white dark:hover:bg-lightbg dark:hover:text-darkbg"
        }`}
        onClick={() => {
          setNewPrev(type);
          setIsError(false);
        }}
      >
        {name}
      </button>
    </>
  );
};

export default Button;
