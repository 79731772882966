import React, { useState, useEffect } from "react";

const SwingTradingHeader = (props) => {
  return (
    <div>
      <h1 className="text-center text-5xl flex justify-center pt-5">
        Swing Trading
      </h1>
    </div>
  );
};

export default SwingTradingHeader;
