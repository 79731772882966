import React, { useState, useEffect } from "react";

import axios from "axios";
import ReactPlayer from "react-player";

import PdfModal from "./PdfModal";

import { apiLinks } from "../../utils/links";

const NFTPic = (props) => {
  const ipfsLinks = [
    "https://gateway.pinata.cloud/",
    "https://cloudflare-ipfs.com/",
    "https://dweb.link/",
  ];

  const { imgStr } = props;
  const [imgUrl, setImgUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [filetype, setFiletype] = useState("img");
  const [openPdf, setOpenPdf] = useState(false);

  const noimage = require(`../../assets/images/NFT/noimage.png`).default;

  const handleOpenPdf = () => {
    setOpenPdf(!openPdf);
  };

  useEffect(() => {
    const assignIPFSLink = async (ipfsLinks) => {
      let maxWaitingTime = 10000;

      return new Promise((resolve, reject) => {
        let index = 0;
        setLoading(true);

        const checkIPFSLink = async (index) => {
          // const tmpImg = new Image();
          let currentIpfsLink = "";

          if (index >= ipfsLinks.length) {
            // All links have been called without success since no images are loading
            setLoading(false);
            console.log("no images found, all loading failed");
            setImgUrl(noimage);
            return;
          }

          if (imgStr.startsWith("ipfs://ipfs/")) {
            currentIpfsLink =
              ipfsLinks[index] + imgStr.substring(7, imgStr.length);
          } else {
            currentIpfsLink =
              ipfsLinks[index] + "ipfs/" + imgStr.substring(7, imgStr.length);
          }

          // Set the timeout for the img link check
          const timer = setTimeout(() => {
            // Timeout reached, move to the next API
            checkIPFSLink(index + 1);
          }, maxWaitingTime);

          // Perform the img link check
          await axios({
            url: currentIpfsLink,
            method: "get",
          })
            .then((response) => {
              clearTimeout(timer);

              if (response.status === 200) {
                setLoading(false);
                setImgUrl(currentIpfsLink);
                resolve();
              } else {
                //error in image - not 200
                console.error("error:", response.status);
                checkIPFSLink(index + 1); // Move to the next API
              }
            })
            .catch((error) => {
              console.log(error);
              clearTimeout(timer); // Clear the timeout since an error occurred
              checkIPFSLink(index + 1); // Move to the next link
            });
        };

        checkIPFSLink(index);
      });
    };

    //if imgStr is an ipfs link not an url
    if (imgStr.startsWith("ipfs://")) {
      assignIPFSLink(ipfsLinks);
    } else {
      setImgUrl(imgStr);
      // temp = imgStr;
    }

    // setImgUrl(temp);

    // if (imgStr.substring(0, 7) === "ipfs://") {
    //   temp = getIPFSLink();
    // }

    if (imgStr.substring(imgStr.length - 4, imgStr.length) === ".mp4") {
      setFiletype("vid");
    } else if (imgStr.substring(imgStr.length - 4, imgStr.length) === ".pdf") {
      setFiletype("pdf");
    } else {
      setFiletype("img");
    }
  }, [imgStr]);

  const handleImageError = () => {
    // var temp = "https://ipfs.io/" + imgStr.substring(7, imgStr.length);

    // console.log(temp);
    // setImgUrl(temp);
    setImgUrl(noimage);
  };

  return (
    <div>
      {loading && <div className="block">Loading image...</div>}

      {!loading && imgUrl && filetype === "img" && (
        <img
          src={imgUrl}
          // onError={handleImageError}
          className="object-scale-down h-48 w-80 opacity-75"
        />
      )}

      {!loading && filetype === "vid" && (
        <ReactPlayer
          url={imgUrl}
          // onError={handleImageError}
          controls
          width={380}
          height={192}
        />
      )}

      {!loading && filetype === "pdf" && (
        <>
          <object
            data={imgUrl}
            type="application/pdf"
            width={380}
            height={192}
            className="rounded-lg"
          ></object>
          <div className="pt-4"></div>
          <button
            className="text-base border border-2 p-2 rounded-lg dark:hover:text-darkbg hover:text-lightbg dark:hover:bg-lightbg hover:bg-darkbg"
            onClick={handleOpenPdf}
          >
            See bigger PDF
          </button>
          {openPdf && (
            <PdfModal
              openPdf={openPdf}
              setOpenPdf={setOpenPdf}
              imgUrl={imgUrl}
            />
          )}
        </>
      )}
    </div>
  );
};

export default NFTPic;
