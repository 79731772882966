import React, { useState, useEffect } from "react";

const NFTPicCompare = (props) => {
  const { text, imglink } = props;

  const [isHovering, setIsHovering] = useState(false);

  const HoverText = () => {
    const image = require(`../../assets/images/NFT/${imglink}`).default;
    return (
      <div className="fixed">
        <img src={image} className="pt-4 object-scale-down h-64 inline-block" />
      </div>
    );
  };

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <div>
      <div
        className="text-xl"
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        {text}
      </div>
      {isHovering && <HoverText />}
    </div>
  );
};

export default NFTPicCompare;
