import React, { useState, useEffect } from "react";

import LoginLogoAndTopNav from "../Login/LoginLogoAndTopNav";

const ProjectResearchPage = (props) => {
  return (
    <div className="dark:bg-hideappbg dark:text-lightbg light:bg-lightbg light:text-darkbg pb-16">
      <LoginLogoAndTopNav />
      <h1 className="text-center font-bold text-3xl py-7">
        Projects / Research
      </h1>
      <div className="text-base grid grid-cols-6 gap-4 text-justify">
        <>
          <div className="col-span-1"></div>
          <div className="col-span-4">
            <h3 className="font-bold text-xl pb-2">
              In-Depth Coin/Token Analysis
            </h3>
            <ul>
              <li className="pb-2">
                Comprehensive analysis of the fundamentals, technology, and
                adoption of specific cryptocurrencies or tokens.
              </li>
              <li className="pb-2">
                Evaluation of the project's roadmap, team, and community
                involvement.
              </li>
              <li className="pb-2">
                Assessment of the coin/token's utility, market demand, and
                potential for growth.
              </li>
            </ul>
          </div>
          <div className="col-span-1"></div>
        </>

        <>
          <div className="col-span-1"></div>
          <div className="col-span-4">
            <h3 className="font-bold text-xl pb-2">Crypto Exchange Audits</h3>
            <ul>
              <li className="pb-2">
                Detailed audits of popular cryptocurrency exchanges, including
                security measures, trading volumes, and liquidity.
              </li>
              <li className="pb-2">
                Analysis of the exchange's regulatory compliance, user
                experience, and customer support.
              </li>
              <li className="pb-2">
                Identification of potential risks and vulnerabilities associated
                with specific exchanges.
              </li>
            </ul>
          </div>
          <div className="col-span-1"></div>
        </>

        {/* <>
          <div className="col-span-1"></div>
          <div className="col-span-4">
            <h3 className="font-bold text-xl pb-2">NFT Market Research</h3>
            <ul>
              <li className="pb-2">
                In-depth exploration of the non-fungible token (NFT) market,
                including popular platforms, use cases, and trends.
              </li>
              <li className="pb-2">
                Analysis of the value proposition and potential of specific NFT
                projects or collections.
              </li>
              <li className="pb-2">
                Evaluation of the NFT ecosystem's growth, adoption, and
                potential challenges.
              </li>
            </ul>
          </div>
          <div className="col-span-1"></div>
        </> */}

        <>
          <div className="col-span-1"></div>
          <div className="col-span-4">
            <h3 className="font-bold text-xl pb-2">
              DeFi (Decentralized Finance) Ecosystem Analysis:
            </h3>
            <ul>
              <li className="pb-2">
                Comprehensive research on the rapidly evolving decentralized
                finance (DeFi) landscape.
              </li>
              <li className="pb-2">
                Evaluation of popular DeFi protocols, lending platforms, and
                yield farming opportunities.
              </li>
              <li className="pb-2">
                Assessment of the risks, regulatory implications, and potential
                impact of DeFi on traditional finance.
              </li>
            </ul>
          </div>
          <div className="col-span-1"></div>
        </>

        <>
          <div className="col-span-1"></div>
          <div className="col-span-4">
            <h3 className="font-bold text-xl pb-2">
              Blockchain Scalability Solutions
            </h3>
            <ul>
              <li className="pb-2">
                In-depth analysis of various blockchain scalability solutions,
                such as layer-2 solutions, sidechains, and sharding.
              </li>
              <li className="pb-2">
                Evaluation of the potential impact of these solutions on the
                performance and adoption of specific blockchain networks.
              </li>
              <li className="pb-2">
                Comparison of different scalability approaches and their
                respective strengths and weaknesses.
              </li>
            </ul>
          </div>
          <div className="col-span-1"></div>
        </>

        <>
          <div className="col-span-1"></div>
          <div className="col-span-4">
            <h3 className="font-bold text-xl pb-2">
              Emerging Trends and Innovations
            </h3>
            <ul>
              <li className="pb-2">
                Ongoing research and analysis of emerging trends, innovations,
                and disruptive technologies in the digital asset space.
              </li>
              <li className="pb-2">
                Exploration of new use cases, such as decentralized identity,
                decentralized social media, and interoperability solutions.
              </li>
              <li className="pb-2">
                Assessment of the potential impact of these emerging trends on
                the digital asset ecosystem.
              </li>
            </ul>
          </div>
          <div className="col-span-1"></div>
        </>
      </div>
      <div className="pt-16 pb-16"></div>
    </div>
  );
};

export default ProjectResearchPage;
