import React, { useState, useEffect } from "react";

import { CoinEvaluationInfo } from "../CoinEval/CoinEvaluationInfo";
import CoinEvalSectionResult from "./CoinEvalSectionResult";

const CoinEvalResult = (props) => {
  const { coinEvalSectionName } = props;
  const [coinEvaluated, setCoinEvaluated] = useState("");
  const [qnSections, setQnSections] = useState([]);
  const [allQns, setAllQns] = useState([]);

  useEffect(() => {
    // console.log(window.sessionStorage.getItem("CoinName"));
    setCoinEvaluated(window.sessionStorage.getItem("CoinName"));

    var tempSections = [];
    var tempAllQns = [];

    CoinEvaluationInfo.forEach((e) => {
      tempSections.push(e.sectionName);

      var tempSectionQns = [];
      e.checklist.forEach((qn) => {
        tempSectionQns.push(qn);
      });

      tempAllQns.push(tempSectionQns);
    });

    setQnSections(tempSections);
    setAllQns(tempAllQns);
  }, []);

  //   useEffect(() => {}, [isAnswered, setIsAnswered]);

  return (
    <div className="px-12 pb-8">
      <div className="text-lg">
        <span className="font-bold">Evaluating Coin / Token: </span>{" "}
        <span className="italic">{coinEvaluated}</span>
      </div>
      <br />
      {qnSections &&
        allQns &&
        qnSections.map((element, index) => {
          return (
            <CoinEvalSectionResult
              sectionName={element}
              coinEvalSectionName={coinEvalSectionName}
              key={element}
              allQns={allQns[index]}
            />
          );
        })}
    </div>
  );
};

export default CoinEvalResult;
