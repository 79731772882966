import React, { useState, useEffect } from "react";

import SingleLink from "./SingleLink";

const TopNavbarNoLogin = (props) => {
  return (
    <>
      <SingleLink title="CONSULTING" link="/#/consulting" />
      <SingleLink title="PROJECTS / RESEARCH" link="/#/projectresearch" />
      <SingleLink
        title="CONTACT"
        link="https://app.sessions.us/book/exploring-tomorrow-today./dyor"
      />
      <SingleLink title="LOGIN" link="/#/login" />
    </>
  );
};

export default TopNavbarNoLogin;
