import React, { useState, useEffect, useContext } from "react";
import ReactSpeedometer from "react-d3-speedometer";
import { ThemeContext } from "../Theme/ThemeContext";

const FeargreedHeader = (props) => {
  const { theme } = useContext(ThemeContext);
  const { overallValue, valueClassification } = props;

  return (
    <div>
      <h1 className="text-center text-5xl flex justify-center pt-5">
        Fear & Greed
      </h1>
      <h3 className="text-center text-2xl flex justify-center pt-5">
        Overall Index
      </h3>
      <p className="text-center text-xl flex justify-center pt-10">
        General Sentiment: {valueClassification}
      </p>
      <div className="pt-3 flex justify-center">
        <ReactSpeedometer
          maxValue={100}
          maxSegmentLabels={0}
          valueTextFontSize={"1.7rem"}
          valueTextFontWeight={"500"}
          currentValueText="Current Value: ${value}"
          segments={900}
          value={overallValue}
          needleTransitionDuration={800}
          startColor={"#FFA500"}
          endColor={"#50055E"}
          needleColor="#D8DEE9"
          textColor={`${theme === "dark" ? "#FFFFFF" : "#000000"}`}
          paddingVertical={10}
          forceRender={true}
          needleHeightRatio={0.8}
        />
      </div>
    </div>
  );
};

export default FeargreedHeader;
