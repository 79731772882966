import React, { useState, useEffect } from "react";

import ThemeToggle from "../Theme/ThemeToggle";
import { ThemeContext } from "../Theme/ThemeContext";

import LoginLogoAndTopNav from "../Login/LoginLogoAndTopNav";

const BeforeLoginMainScreen = (props) => {
  return (
    <div>
      <div className="absolute right-0 top-0 mr-4 mt-4 md:mr-6 md:mt-6">
        <ThemeToggle />
      </div>
      <div className="dark:bg-hideappbg">
        <LoginLogoAndTopNav />
        <div className="bg-beforeloginpagebg bg-cover bg-no-repeat bg-scroll bg-center pt-28 px-4 md:pb-76 pb-28 text-white">
          <h1 className="text-center md:pt-20 font-bold text-3xl">
            TAKE PROFITS WITH DYOR
          </h1>
          <h2 className="text-center md:pt-12 pt-8 font-bold text-xl">
            DIVE into Digital Asset Insights
          </h2>

          <div className="pt-20"></div>

          <div className="md:flex md:items-center py-10">
            <div className="md:w-1/4"></div>
            <div className="md:w-2/4 md:bg-contain text-center text-xl">
              <div className="lg:pr-4 lg:inline-block block justify-center">
                <a
                  href={
                    "https://app.sessions.us/book/exploring-tomorrow-today./dyor"
                  }
                  className="bg-buttonlink hover:bg-buttonlink-hover text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  rel="noreferrer"
                >
                  Schedule a call
                </a>
              </div>
            </div>
            <div className="md:w-1/4"></div>
          </div>

          <div className="pt-10"></div>
        </div>
      </div>
    </div>
  );
};

export default BeforeLoginMainScreen;
