import React, { useState, useEffect } from "react";

import { CSSTransition } from "react-transition-group";
import { HiChevronDoubleDown, HiChevronDoubleUp } from "react-icons/hi";
import axios from "axios";

import { apiLinks } from "../../utils/links";

import Checkbox from "./Checkbox";
import Textbox from "./Textbox";
import Radio from "./Radio";
import MultiCheckbox from "./MultiCheckbox";
import HoverMultiCheckbox from "./HoverMultiCheckbox";
import SectionCompletedCoins from "../SectionCompletedCoins/SectionCompletedCoins";

const NFTEvalSection = (props) => {
  const {
    data,
    allAns,
    sectionIndex,
    setAllAns,
    moduleName,
    sectionCompleted,
    setSectionCompleted,
    dbData,
    moduleId,
    setModuleId,
    nftName,
    email,
  } = props;
  const [isVisible, setIsVisible] = useState(false);
  const [isSubmitPressed, setIsSubmitPressed] = useState(false);
  const [dataArr, setDataArr] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   var temp = dataArr;
  //   var tempAllAns = allAns;

  //   tempAllAns[sectionIndex] = temp;

  //   setAllAns(tempAllAns);
  // }, [dataArr, setDataArr]);

  useEffect(() => {
    var tempArr = [];

    if (dbData !== "") {
      switch (sectionIndex) {
        case 0:
          tempArr[0] = dbData.art_interesting;
          tempArr[1] = dbData.art_properties;
          tempArr[2] = dbData.art_scarcity;
          tempArr[3] = dbData.art_features;
          tempArr[4] = dbData.art_concept;
          tempArr[5] = dbData.art_raritytools;
          tempArr[6] = dbData.art_raritytools_score;
          tempArr[7] = dbData.art_openseas_score;
          tempArr[8] = dbData.art_showcase;
          tempArr[9] = dbData.art_derivative;
          break;
        case 1:
          tempArr[0] = dbData.team_anonymous;
          tempArr[1] = dbData.team_significant_following;
          tempArr[2] = dbData.team_followers_no;
          tempArr[3] = dbData.team_vocal;
          tempArr[4] = dbData.team_marginalised;
          break;
        case 2:
          tempArr[0] = dbData.project_roadmap;
          tempArr[1] = dbData.project_gamified;
          tempArr[2] = dbData.project_defi_capabilities;
          tempArr[3] = dbData.project_hold_rewards;
          tempArr[4] = dbData.project_upgrades;
          tempArr[5] = dbData.project_airdrop;
          tempArr[6] = dbData.project_membership_benefits;
          tempArr[7] = dbData.project_whitelist;
          tempArr[8] = dbData.project_merchshop;
          break;
        case 3:
          tempArr[0] = dbData.community_twitter;
          tempArr[1] = dbData.community_discord;
          tempArr[2] = dbData.community_telegram;
          tempArr[3] = dbData.community_igyt;
          break;
        case 4:
          tempArr[0] = dbData.trading_proportion;
          tempArr[1] = dbData.trading_ratio;
          break;
        case 5:
          tempArr[0] = dbData.strategy_erc;
          tempArr[1] = dbData.strategy_prominent;
          tempArr[2] = dbData.strategy_editions;
          tempArr[3] = dbData.strategy_early_access;
          tempArr[4] = dbData.strategy_believe;
          tempArr[5] = dbData.strategy_promotion;
          tempArr[6] = dbData.strategy_sellfast;
          tempArr[7] = dbData.strategy_available;
          tempArr[8] = dbData.strategy_portfolio;
          tempArr[9] = dbData.strategy_pricerange;
          tempArr[10] = dbData.strategy_transact_history;
          tempArr[11] = dbData.strategy_walletno;
          tempArr[12] = dbData.strategy_marketcap;
          tempArr[13] = dbData.strategy_tradevol;
          break;
        default:
          break;
      }

      setDataArr(tempArr);
    } else {
      setDataArr(new Array(data.qns.length).fill(""));
    }
  }, []);

  const handleSubmit = async (e, sectionName) => {
    e.preventDefault();

    // window.sessionStorage.setItem(moduleName, allAns);
    setIsLoading(true);
    var urlLink = "";

    if (moduleId === "") {
      //post (create new)
      urlLink = apiLinks.reportApi + moduleName;
    } else {
      //put
      urlLink =
        apiLinks.reportApi + moduleName + "/" + email + "/cg/" + moduleId;
    }

    var dat;

    switch (sectionIndex) {
      case 0:
        dat = {
          email: email,
          name: nftName,
          art_interesting: dataArr[0],
          art_properties: dataArr[1],
          art_scarcity: dataArr[2],
          art_features: dataArr[3],
          art_concept: dataArr[4],
          art_raritytools: dataArr[5],
          art_raritytools_score: dataArr[6],
          art_openseas_score: dataArr[7],
          art_showcase: dataArr[8],
          art_derivative: dataArr[9],
        };
        break;
      case 1:
        dat = {
          email: email,
          name: nftName,
          team_anonymous: dataArr[0],
          team_significant_following: dataArr[1],
          team_followers_no: dataArr[2],
          team_vocal: dataArr[3],
          team_marginalised: dataArr[4],
        };
        break;
      case 2:
        dat = {
          email: email,
          name: nftName,
          project_roadmap: dataArr[0],
          project_gamified: dataArr[1],
          project_defi_capabilities: dataArr[2],
          project_hold_rewards: dataArr[3],
          project_upgrades: dataArr[4],
          project_airdrop: dataArr[5],
          project_membership_benefits: dataArr[6],
          project_whitelist: dataArr[7],
          project_merchshop: dataArr[8],
        };
        break;
      case 3:
        dat = {
          email: email,
          name: nftName,
          community_twitter: dataArr[0],
          community_discord: dataArr[1],
          community_telegram: dataArr[2],
          community_igyt: dataArr[3],
        };
        break;
      case 4:
        dat = {
          email: email,
          name: nftName,
          trading_proportion: dataArr[0],
          trading_ratio: dataArr[1],
        };
        break;
      case 5:
        dat = {
          email: email,
          name: nftName,
          strategy_erc: dataArr[0],
          strategy_prominent: dataArr[1],
          strategy_editions: dataArr[2],
          strategy_early_access: dataArr[3],
          strategy_believe: dataArr[4],
          strategy_promotion: dataArr[5],
          strategy_sellfast: dataArr[6],
          strategy_available: dataArr[7],
          strategy_portfolio: dataArr[8],
          strategy_pricerange: dataArr[9],
          strategy_transact_history: dataArr[10],
          strategy_walletno: dataArr[11],
          strategy_marketcap: dataArr[12],
          strategy_tradevol: dataArr[13],
        };
        break;
      default:
        dat = {
          email: email,
          name: nftName,
        };
        break;
    }

    await axios({
      url: urlLink,
      method: "post",
      data: dat,
    })
      .then((res) => {
        // console.log(res.data);
        setIsLoading(false);

        if (!res.data) {
          setIsError(true);
          setIsSubmitPressed(false);
          return;
        }

        setModuleId(res.data.id);
        setIsSubmitPressed(true);
        setIsError(false);

        window.sessionStorage.setItem(
          moduleName + " - " + sectionName,
          dataArr
        );

        var temp = [...sectionCompleted];
        temp[sectionIndex] = true;

        setSectionCompleted(temp);
      })
      .catch((err) => {
        console.log(err);

        setIsError(true);
        setIsLoading(false);
        setIsSubmitPressed(false);
      });

    // window.sessionStorage.setItem(moduleName + " - " + sectionName, dataArr);

    // var temp = [...sectionCompleted];
    // temp[sectionIndex] = true;

    // setSectionCompleted(temp);
    // setIsSubmitPressed(true);
  };

  return (
    <div>
      <div>
        <button
          type="button"
          onClick={() => {
            setIsVisible(!isVisible);
          }}
          className="text-3xl"
        >
          {data.section}
          {isVisible ? (
            <HiChevronDoubleUp className="pl-4 pt-2 float-right" />
          ) : (
            <HiChevronDoubleDown className="pl-4 pt-2 float-right" />
          )}
        </button>
      </div>
      <CSSTransition in={!isVisible} timeout={300} classNames="test">
        {!isVisible ? (
          <></>
        ) : (
          <div className="flex flex-col">
            <div className="grid grid-cols-5 text-lg pt-5">
              <div className="col-span-1"></div>
              {/* <table className="table-auto text-justify col-span-3"> */}
              <div className="table-auto text-justify col-span-3">
                {/* <tbody> */}
                {data.qns &&
                  data.qns.map((d, index) => (
                    <div key={index}>
                      {/* <Linkify
                        tagName="div"
                        className="md:text-md text-sm px-2"
                      >
                        {index}: {d.qn} - {d.type}
                      </Linkify> */}
                      {d.type && d.type === "checkbox" ? (
                        <Checkbox
                          data={d}
                          index={index}
                          dataArr={dataArr}
                          setDataArr={setDataArr}
                        />
                      ) : null}
                      {d.type && d.type === "textbox" ? (
                        <Textbox
                          data={d}
                          index={index}
                          dataArr={dataArr}
                          setDataArr={setDataArr}
                        />
                      ) : null}
                      {d.type && d.type === "radio" ? (
                        <Radio
                          data={d}
                          index={index}
                          dataArr={dataArr}
                          setDataArr={setDataArr}
                        />
                      ) : null}
                      {d.type && d.type === "multicheckbox" ? (
                        <MultiCheckbox
                          data={d}
                          index={index}
                          dataArr={dataArr}
                          setDataArr={setDataArr}
                        />
                      ) : null}
                      {d.type && d.type === "hovermulticheckbox" ? (
                        <HoverMultiCheckbox
                          data={d}
                          index={index}
                          dataArr={dataArr}
                          setDataArr={setDataArr}
                        />
                      ) : null}
                    </div>
                  ))}
                {/* </tbody> */}
              </div>
              {/* </table> */}
              <div className="col-span-1"></div>
            </div>

            <div className="self-center justify-self-center">
              <div className="pt-6">
                <input
                  type="submit"
                  value="Save"
                  disabled={isLoading}
                  onClick={(e) => {
                    handleSubmit(e, data.section);
                  }}
                  className="bg-orangebtn hover:bg-orangebtn-hover text-white py-2 px-4 rounded-xl text-base cursor-pointer disabled:cursor-wait"
                />
              </div>

              {isSubmitPressed ? (
                <>
                  <div className="pt-2 text-green-700 text-base">Saved!</div>
                </>
              ) : (
                <></>
              )}

              {isError ? (
                <>
                  <div className="pt-2 text-red-700 text-base">
                    Error saving... Please try again!
                  </div>
                  <br />
                </>
              ) : (
                <></>
              )}

              {isSubmitPressed && (
                <SectionCompletedCoins
                  isSubmitPressed={isSubmitPressed}
                  moduleName={moduleName}
                  email={email}
                  isLoading={isLoading}
                />
              )}
            </div>
          </div>
        )}
      </CSSTransition>
    </div>
  );
};

export default NFTEvalSection;
