import React, { useState, useEffect } from "react";
import axios from "axios";
import { css } from "@emotion/react";
import RingLoader from "react-spinners/RingLoader";

import { apiLinks } from "../../utils/links";

import FeargreedHeader from "./FeargreedHeader";
import CryptoScoller from "./CryptoScroller";
import CurrencyButtonScroller from "./CurrencyButtonScroller";
import CurrencyButton from "./CryptoButton";
import InfoBoxGrid from "./InfoBoxGrid";
import InfoPriceGrid from "./InfoPriceGrid";
import Questions from "../Questions/Questions";

import { FaLongArrowAltRight } from "react-icons/fa";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Feargreed = (props) => {
  const [overallValue, setOverallValue] = useState(0);
  const [valueClassification, setValueClassification] = useState("");
  const [currName, setCurrName] = useState("USD");
  const [coinName, setCoinName] = useState("");
  const [coinLink, setCoinLink] = useState("");
  const [coinId, setCoinId] = useState("");
  const [coinPrice, setCoinPrice] = useState("");
  const [coinHrChange, setCoinHrChange] = useState("");
  const [coinDayChange, setCoinDayChange] = useState("");
  const [coinWeekChange, setCoinWeekChange] = useState("");
  const [coinMaxSupply, setCoinMaxSupply] = useState("");
  const [coinTotalSupply, setCoinTotalSupply] = useState("");
  const [coinCirculateSupply, setCoinCirculateSupply] = useState("");
  const [moduleName, setModuleName] = useState("fng");
  const [loading, setLoading] = useState(false);
  const [loaderColor, setLoaderColor] = useState("#36D7B7");
  const [isCoinPriceLoading, setIsCoinPriceLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    axios({
      url: apiLinks.fearGreedGeneral,
      method: "get",
    })
      .then((res) => {
        // console.log(res.data.data[0].value);
        setOverallValue(parseInt(res.data.data[0].value, 10));
        setValueClassification(res.data.data[0].value_classification);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const getLatestIndex = async (coinId) => {
    setIsCoinPriceLoading(true);

    await axios({
      url: apiLinks.fearGreedCoin + coinId + "/",
      method: "get",
    })
      .then((res) => {
        // console.log(res.data.data[coinId]);
        setIsCoinPriceLoading(false);

        setCoinPrice(res.data.data[coinId].quotes[currName].price);
        setCoinHrChange(
          res.data.data[coinId].quotes[currName].percentage_change_1h + " %"
        );
        setCoinDayChange(
          res.data.data[coinId].quotes[currName].percentage_change_24h + " %"
        );
        setCoinWeekChange(
          res.data.data[coinId].quotes[currName].percentage_change_7d + " %"
        );
        setCoinMaxSupply(res.data.data[coinId].max_supply);
        setCoinTotalSupply(res.data.data[coinId].total_supply);
        setCoinCirculateSupply(res.data.data[coinId].circulating_supply);
      })
      .catch((err) => {
        console.log(err);
        setIsCoinPriceLoading(false);
      });
  };

  // useEffect(() => {}, [currName]);

  return (
    <div className="container mx-auto md:pb-10 pb-28">
      {loading ? (
        <>
          <div className="sm:block hidden">
            <RingLoader
              color={loaderColor}
              loading={loading}
              css={override}
              size={500}
            />
          </div>
          <div className="sm:hidden">
            <RingLoader
              color={loaderColor}
              loading={loading}
              css={override}
              size={200}
            />
          </div>
        </>
      ) : (
        <>
          <FeargreedHeader
            overallValue={overallValue}
            valueClassification={valueClassification}
          />

          {/* <br /> */}
          {/* <CurrencyButtonScroller setCurrName={setCurrName} /> */}
          <CryptoScoller
            setCoinName={setCoinName}
            setCoinLink={setCoinLink}
            setCoinId={setCoinId}
          />
          <div className="text-xl sm:hidden">
            Scroll for more coins <FaLongArrowAltRight className="inline" />
          </div>
          <br />
          <button
            className="border-4 dark:border-lightbg border-darkbg hover:border-blue-600 dark:hover:border-blue-600 rounded-lg p-5 disabled:cursor-wait text-2xl"
            disabled={isCoinPriceLoading}
            onClick={() => getLatestIndex(coinId)}
          >
            Get latest index
          </button>
          <br />
          <br />
          <h3 className="text-center text-2xl flex justify-center pt-5">
            Coin: &nbsp;<span className="font-bold">{coinName}</span>
          </h3>
          <br />
          <h3 className="text-center text-2xl flex justify-center">
            Price: &nbsp;<span className="font-bold">US$ {coinPrice}</span>
          </h3>
          <br />
          <InfoPriceGrid
            coinHrChange={coinHrChange}
            coinDayChange={coinDayChange}
            coinWeekChange={coinWeekChange}
          />
          <br />
          <InfoBoxGrid
            coinMaxSupply={coinMaxSupply}
            coinTotalSupply={coinTotalSupply}
            coinCirculateSupply={coinCirculateSupply}
          />
          <br />
          <br />
          <Questions moduleName={moduleName} />
        </>
      )}
    </div>
  );
};

export default Feargreed;
