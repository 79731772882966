import React, { useState, useEffect } from "react";
import {
  Route,
  Link,
  BrowserRouter,
  Routes,
  HashRouter,
} from "react-router-dom";

import MainRoute from "./MainRoute";
import HideApplication from "../HideApplication/HideApplication";
import Login from "../Login/Login";
import Privacy from "../LegalPages/Privacy";
import TnC from "../LegalPages/TnC";
import ConsultingPage from "../TopNavNoLogin/ConsultingPage";
import ProjectResearchPage from "../TopNavNoLogin/ProjectResearchPage";
import Footer from "../Footer/Footer";
import BeforeLoginMainScreen from "../BeforeLoginMainScreen/BeforeLoginMainScreen";

const AuthRoute = (props) => {
  const SECRETPASS = "43r9u(RDkf4933-)(I3";

  //temp turn off authenticated for ui/ux testing
  // const [authenticated, setAuthenticated] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);

  //temp turn off authenticated for ui/ux testing
  useEffect(() => {
    let auth = sessionStorage.getItem("authenticated");

    if (auth) {
      setAuthenticated(auth);
    }
  }, []);

  return (
    <HashRouter>
      {authenticated ? (
        <MainRoute />
      ) : (
        <>
          <Routes className="font-euclidreg">
            <Route path="/terms" element={<TnC />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/consulting" element={<ConsultingPage />} />
            <Route path="/projectresearch" element={<ProjectResearchPage />} />
            <Route
              path="/login"
              element={
                <Login
                  // SECRETPASS={SECRETPASS}
                  setAuthenticated={setAuthenticated}
                  authenticated={authenticated}
                />
              }
            />
            {/* <Route
              path="*"
              element={
                <HideApplication
                  SECRETPASS={SECRETPASS}
                  setAuthenticated={setAuthenticated}
                  authenticated={authenticated}
                />
              }
            /> */}
            <Route path="*" element={<BeforeLoginMainScreen />} />
          </Routes>

          <Footer />
        </>
      )}
    </HashRouter>
  );
};

export default AuthRoute;
