import React, { useState, useEffect } from "react";

import ProofOfReservesHeader from "./ProofOfReservesHeader";
import DropdownList from "./DropdownList";
import Graph from "./Graph";

const ProofOfReserves = (props) => {
  const { selectedExchange, setSelectedExchange } = props;
  const [selectedExchangeDisplayName, setSelectedExchangeDisplayName] =
    useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sectionVisible, setSectionVisible] = useState(false);
  const [graphData, setGraphData] = useState("");

  return (
    <div className="container mx-auto pb-16">
      <ProofOfReservesHeader />

      <DropdownList
        selectedExchange={selectedExchange}
        setSelectedExchange={setSelectedExchange}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        sectionVisible={sectionVisible}
        setSectionVisible={setSectionVisible}
        graphData={graphData}
        setGraphData={setGraphData}
        selectedExchangeDisplayName={selectedExchangeDisplayName}
        setSelectedExchangeDisplayName={setSelectedExchangeDisplayName}
      />
      {selectedExchange && sectionVisible && graphData && (
        <div className="grid text-center grid-cols-8 pt-12 px-4">
          <div className="col-span-1"></div>
          <div className="col-span-6">
            <Graph
              selectedExchange={selectedExchange}
              graphData={graphData}
              selectedExchangeDisplayName={selectedExchangeDisplayName}
            />
          </div>
          <div className="col-span-1"></div>
        </div>
      )}
    </div>
  );
};

export default ProofOfReserves;
