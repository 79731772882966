import React, { useState, useEffect } from "react";
import Linkify from "linkify-react";

const Radio = (props) => {
  const { data, index, dataArr, setDataArr } = props;
  const [selectedOption, setSelectedOption] = useState(dataArr[index]);

  const handleOption = (value) => {
    setSelectedOption(value);

    var temp = dataArr;
    temp[index] = value;
    setDataArr(temp);
  };

  return (
    <Linkify
      tagName="div"
      className="block pb-4"
      options={{
        rel: "noreferral",
        target: "_blank",
        className: "text-linkifylink hover:text-linkifylink-hover",
      }}
    >
      <div className="grid grid-rows-4 text-base pt-10 inline-flex items-center">
        <label className="row-span-2 text-justified pb-3 text-xl">
          {data.qn}
        </label>
        {data.options &&
          data.options.map((d, index) => (
            <span>
              <input
                className="pl-4"
                type="radio"
                name={Object.keys(data)}
                value={d}
                checked={selectedOption == d}
                key={d}
                onChange={() => {
                  handleOption(d);
                }}
              />
              <label className="pl-4 text-xl">{d}</label>
            </span>
          ))}
      </div>
    </Linkify>
  );
};

export default Radio;
