import React, { useState, useEffect } from "react";
import axios from "axios";
import { Navigate, Router, Routes, Route } from "react-router-dom";
import { parseISO, differenceInHours, setMilliseconds } from "date-fns";

import { MdEmail } from "react-icons/md";

import "../../App.css";

import { apiLinks } from "../../utils/links";
import ThemeToggle from "../Theme/ThemeToggle";
import { ThemeContext } from "../Theme/ThemeContext";

import LoginLogoAndTopNav from "./LoginLogoAndTopNav";

const Login = (props) => {
  const { theme } = React.useContext(ThemeContext);
  // const { setPassword, wrongPass, setWrongPass, SECRETPASS } = props;
  const { authenticated, setAuthenticated } = props;
  const [isEmail, setIsEmail] = useState(true);
  const [email, setEmail] = useState("");
  const [isError, setIsError] = useState(false);
  const [dt, setDt] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const emailRegex = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  useEffect(() => {
    setDt(Date.now());
  }, []);

  const handleSubmitEmail = async () => {
    setIsError(false);

    if (isEmail && email !== "") {
      setIsLoading(true);

      await axios({
        url: apiLinks.reportApi + "user/" + email.toLowerCase(),
        method: "get",
      })
        .then(async (res) => {
          if (!res.data) {
            setIsLoading(false);
            setIsError(true);
            return;
          }

          if (!res.data.subExpiry) {
            setIsLoading(false);
            setIsError(true);
            return;
          }

          if (differenceInHours(parseISO(res.data.subExpiry), dt) < 0) {
            setIsLoading(false);
            //expired
            setIsError(true);
            return;
          }

          await axios({
            url: apiLinks.reportApi + "user/" + email.toLowerCase() + "/login",
            method: "PUT",
          })
            .then((res) => {
              setIsLoading(false);
              // console.log(res);
              if (res.data) {
                window.sessionStorage.setItem("authenticated", true);
                window.sessionStorage.setItem("email", email.toLowerCase());
                window.sessionStorage.setItem("coinsNo", res.data.curr_coins);
                window.sessionStorage.setItem(
                  "daysSigned",
                  res.data.days_signed_in
                );
                window.sessionStorage.setItem(
                  "coinCollectedDate",
                  res.data.last_collected_date
                );
                window.sessionStorage.setItem(
                  "hasAgreed",
                  res.data.disclaimer_agreed
                );

                setIsError(false);
                setAuthenticated(true);
                return (
                  <Router>
                    <Navigate to="/index" />
                  </Router>
                );
              }
              //if error message (no user found)
              setIsError(true);
              return;
            })
            .catch((err) => {
              console.log(err);
              setIsError(true);
            });
        })
        .catch((err) => {
          console.log(err);
          setIsError(true);
        });
    } else {
      setIsError(true);
    }
  };

  const handleInputChange = (e) => {
    // console.log(e.target.value);
    // console.log(e.target.id);

    if (e.target.id == "email-input") {
      setEmail(e.target.value.trim());
    }
  };
  const handleBadEmail = (e) => {
    setIsError(false);
    if (!emailRegex.test(e.target.value)) {
      setIsEmail(false);
    } else {
      setIsEmail(true);
    }
  };

  return (
    <div>
      <div className="absolute right-0 top-0 mr-4 mt-4 md:mr-6 md:mt-6">
        <ThemeToggle />
      </div>
      <div className="px-4 dark:bg-hideappbg md:pb-76 pb-28">
        <LoginLogoAndTopNav />

        <div className="text-center pt-28 font-bold text-2xl">
          Take Profits: Dive into Digital Asset Insights
        </div>
        <form
          className="max-w-prose mx-auto max-w-xs pt-12"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmitEmail(email);
          }}
        >
          <div className="md:flex md:items-center pb-5">
            <div className="md:w-1/3">
              <label className="block font-bold md:text-right mb-1 md:mb-0 pr-4 text-center text-base">
                Member Login with Email: <MdEmail className="inline" />
              </label>
            </div>
            <div className="md:w-2/3">
              <input
                type="email"
                id="email-input"
                name="email"
                placeholder="charles@stayrich.com"
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:shadow-outline"
                value={email}
                onChange={(e) => handleInputChange(e)}
                onBlur={handleBadEmail}
              />
            </div>
          </div>
          <div className="flex flex-col justify-center items-center pt-2">
            <div>
              <input
                type="submit"
                className="bg-buttonlink hover:bg-buttonlink-hover text-white mt-4 lg:mt-0 font-bold py-2 px-4 rounded focus:shadow-outline cursor-pointer disabled:cursor-wait"
                value="Login"
                disabled={isLoading}
              />
            </div>
          </div>
          {!isEmail ? (
            <div className="flex flex-col justify-center items-center">
              <div>
                <h1 className="md:flex md:items-center pt-3 text-red-500 text-base">
                  Please enter a valid email!
                </h1>
              </div>
            </div>
          ) : null}

          {isError && (
            <div className="flex flex-col justify-center items-center">
              <div>
                <h1 className="md:flex md:items-center pt-3 text-red-500 text-base text-center">
                  Authentication error. Please check your email or re-subscribe.
                </h1>
              </div>
            </div>
          )}

          {isLoading && (
            <div className="flex flex-col justify-center items-center">
              <div>
                <h1 className="md:flex md:items-center pt-3 text-red-500 text-base text-center">
                  Please wait, authenticating...
                </h1>
              </div>
            </div>
          )}
        </form>

        <div className="pt-16"></div>
      </div>
    </div>
  );
};

export default Login;
