import React, { useState, useEffect } from "react";

import { SwingTradeQns } from "./SwingTradeQns";

import SwingTradingHeader from "./SwingTradingHeader";
import CandlestickGraph from "./CandlestickGraph";
import RsiGraph from "./RsiGraph";
import MacdGraph from "./MacdGraph";

import QnChecklist from "./QnChecklist";

import "../../utils/images.css";

import trendImg from "../../assets/images/SwingTrade/trend.png";
import candleImg from "../../assets/images/SwingTrade/moving.png";

const SwingTrading = (props) => {
  const [allData, setAllData] = useState([]);
  const [coinName, setCoinName] = useState("");
  const [moduleName, setModuleName] = useState("swingtrade");
  const [moduleId, setModuleId] = useState("");

  useEffect(() => {
    setAllData(SwingTradeQns);

    if (sessionStorage.getItem(moduleName + "id")) {
      setModuleId(window.sessionStorage.getItem(moduleName + "id"));
    }
  }, []);

  return (
    <div className="container mx-auto md:pb-64 pb-28 overflow-auto">
      <SwingTradingHeader />
      <h2 className="text-center text-3xl justify-center py-5">To Buy:</h2>

      {allData &&
        allData.map((data, index) => {
          return (
            <>
              <QnChecklist
                key={index}
                data={data}
                moduleName={moduleName}
                moduleId={moduleId}
                setModuleId={setModuleId}
              />{" "}
              <br />
            </>
          );
        })}

      {/** Candlestick images - buying signal, to be moved to help center */}
      {/* <div className="grid text-center grid-cols-4 pt-20 px-4">
        <div className="col-span-1"></div>
        <div className="click-zoom col-span-2">
          <label>
            <input type="checkbox" />
            <img src={trendImg} alt="trends" />
          </label>
        </div>
        <div className="col-span-1"></div>
      </div>

      <div className="grid text-center grid-cols-4 pt-5 px-4">
        <div className="col-span-1"></div>
        <div className="click-zoom col-span-2">
          <label>
            <input type="checkbox" />
            <img src={candleImg} alt="candlestick" />
          </label>
        </div>
        <div className="col-span-1"></div>
      </div> */}

      <div className="grid text-center grid-cols-8 pt-12 px-4">
        <div className="col-span-1"></div>
        <div className="col-span-6">
          <CandlestickGraph coinName={"bitcoin"} setCoinName={setCoinName} />
        </div>
        <div className="col-span-1"></div>
      </div>

      <div className="grid text-center grid-cols-8 pt-12 px-4">
        <div className="col-span-1"></div>
        <div className="col-span-6">
          <MacdGraph coinName={"BTC"} setCoinName={setCoinName} />
        </div>
        <div className="col-span-1"></div>
      </div>

      <div className="grid text-center grid-cols-8 pt-12 px-4">
        <div className="col-span-1"></div>
        <div className="col-span-6">
          <RsiGraph coinName={"BTC"} setCoinName={setCoinName} />
        </div>
        <div className="col-span-1"></div>
      </div>

      <div className="grid text-center grid-cols-8 pt-12 px-4">
        <div className="col-span-1"></div>
        <div className="col-span-6">
          <CandlestickGraph coinName={"ethereum"} setCoinName={setCoinName} />
        </div>
        <div className="col-span-1"></div>
      </div>

      <div className="grid text-center grid-cols-8 pt-12 px-4">
        <div className="col-span-1"></div>
        <div className="col-span-6">
          <MacdGraph coinName={"ETH"} setCoinName={setCoinName} />
        </div>
        <div className="col-span-1"></div>
      </div>

      <div className="grid text-center grid-cols-8 pt-12 px-4">
        <div className="col-span-1"></div>
        <div className="col-span-6">
          <RsiGraph coinName={"ETH"} setCoinName={setCoinName} />
        </div>
        <div className="col-span-1"></div>
      </div>

      {/* <div className="grid text-center grid-cols-8 pt-12 px-4">
        <div className="col-span-1"></div>
        <div className="col-span-6">
          <RsiGraph coinName={coinName} setCoinName={setCoinName} />
        </div>
        <div className="col-span-1"></div>
      </div>

      <div className="grid text-center grid-cols-8 pt-12 px-4">
        <div className="col-span-1"></div>
        <div className="col-span-6">
          <MacdGraph coinName={coinName} setCoinName={setCoinName} />
        </div>
        <div className="col-span-1"></div>
      </div> */}
    </div>
  );
};

export default SwingTrading;
