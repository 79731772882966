import React, { useState, useEffect } from "react";

import AccountHeader from "./AccountHeader";

const Account = (props) => {
  const { email } = props;
  const [totalCoins, setTotalCoins] = useState(
    window.sessionStorage.getItem("coinsNo")
  );

  return (
    <div className="container mx-auto md:pb-64 pb-28">
      <AccountHeader />

      <div className="pt-10 pb-8 text-lg ">
        <p>
          Email: <span className="text-linkifylink">{email}</span>
        </p>
        <p>
          Total Coins: <span className="text-linkifylink">{totalCoins}</span>
        </p>
      </div>
    </div>
  );
};

export default Account;
