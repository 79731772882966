import React, { useState, useEffect } from "react";

import axios from "axios";
import Linkify from "linkify-react";
import { parseISO } from "date-fns";

import { apiLinks } from "../../utils/links";

const CoinInfoModal = (props) => {
  const { coinName, teammemId, setTeammemid, twitterId, setTwitterId } = props;
  const [coinDetails, setCoinDetails] = useState("");
  const [coinError, setCoinError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    axios({
      url: apiLinks.coinEvalCoinDetails + coinName,
      method: "get",
    })
      .then((res) => {
        setLoading(false);
        setCoinDetails(res.data);
        var twitterurl = "";

        res.data.sites.map((site) => {
          if (site.type === "twitter") {
            // console.log(site.url);
            twitterurl = site.url;
          }
        });

        if (twitterurl !== "") {
          twitterurl = twitterurl.substring(20);
          //   console.log(twitterurl);
          setTwitterId(twitterurl);
        } else {
          setTwitterId("");
        }
        setTeammemid("");
        setCoinError(false);
      })
      .catch((err) => {
        // console.log(err);
        setTeammemid("");
        setTwitterId("");
        setLoading(false);
        setCoinError(true);
      });
  }, [coinName]);

  return (
    <div className="overflow-y-auto border-2 rounded-lg h-96 w-96 px-4 py-2 text-lg text-left break-words">
      {loading ? (
        <div className="block">Loading data...</div>
      ) : (
        <>
          {coinDetails && !coinError ? (
            <>
              {coinDetails.type === "coin" ? (
                <>
                  <div>
                    Name: {coinDetails.name}{" "}
                    {coinDetails.whitepaper && (
                      <>
                        (
                        <span className="cursor-pointer text-lighterbuttonlink hover:underline">
                          <a href={coinDetails.whitepaper} target="_blank">
                            See Whitepaper
                          </a>
                        </span>
                        )
                      </>
                    )}
                  </div>
                  <hr />
                  <div>Symbol: {coinDetails.ticker}</div>
                  <hr />
                  <div>Type: {coinDetails.type}</div>
                  <hr />
                  {/* <div>Project active?: {coinDetails.is_active}</div> */}
                  {/* <div>
        {coinDetails.type} description: {coinDetails.description}
      </div> */}
                  {/* <div>Open source?: {coinDetails.open_source}</div> */}
                  {coinDetails.launch_date && (
                    <>
                      <div>
                        Launch Date:{" "}
                        {parseISO(coinDetails.launch_date).toString()}
                      </div>
                      <hr />
                    </>
                  )}
                  {coinDetails.development_status && (
                    <>
                      <div>
                        Project status: {coinDetails.development_status}
                      </div>
                      <hr />
                    </>
                  )}
                  <div>Proof type: {coinDetails.proof_type}</div>
                  <hr />
                  <div>Organization structure: {coinDetails.org_structure}</div>
                  <hr />
                  <div>Hashing algorithm: {coinDetails.hash_algo}</div>
                  <hr />

                  {/* <div>Whitepaper: {coinDetails.whitepaper}</div>
      <hr /> */}
                  <div className="pb-2">
                    Important Links:
                    <br />
                    {coinDetails.sites &&
                      coinDetails.sites.map((s, index) => {
                        return (
                          <>
                            <Linkify
                              tagName="div"
                              //   className="overflow-y-auto border-2 rounded-lg h-96 w-96 px-4 py-2 text-base text-left"
                              options={{
                                rel: "noreferral",
                                target: "_blank",
                                className:
                                  "text-linkifylink hover:text-linkifylink-hover hover:underline",
                              }}
                              key={s.url}
                              data={s}
                            >
                              {" "}
                              {s.type}: {s.url}
                            </Linkify>
                          </>
                        );
                      })}
                  </div>
                  <hr />
                  <div>
                    Team Members:
                    <br />
                    {coinDetails.team &&
                      coinDetails.team.map((mem, index) => {
                        return (
                          <>
                            <div
                              key={mem.id}
                              data={mem}
                              className="cursor-pointer text-red-500 hover:underline"
                              onClick={() => {
                                setTeammemid(mem.id);
                              }}
                            >
                              {" "}
                              {mem.name}: {mem.position}
                            </div>
                          </>
                        );
                      })}
                  </div>
                </>
              ) : (
                <>
                  <div>
                    Name: {coinDetails.name}
                    {coinDetails.whitepaper && (
                      <>
                        (
                        <span className="cursor-pointer text-lighterbuttonlink hover:underline">
                          <a href={coinDetails.whitepaper} target="_blank">
                            See Whitepaper
                          </a>
                        </span>
                        )
                      </>
                    )}
                  </div>
                  <hr />
                  <div>Symbol: {coinDetails.ticker}</div>
                  <hr />
                  <div>Type: {coinDetails.type}</div>
                  <hr />
                  {coinDetails.contracts && (
                    <>
                      <div>Protocol: {coinDetails.contracts[0].type}</div>
                      <hr />
                    </>
                  )}
                  {coinDetails.parent && (
                    <>
                      <div>
                        Parent Chain: {coinDetails.parent.name} (
                        {coinDetails.parent.symbol})
                      </div>
                      <hr />
                    </>
                  )}
                  {coinDetails.contracts && (
                    <>
                      <div>
                        Wallet Address: {coinDetails.contracts[0].contract}
                      </div>
                      <hr />
                    </>
                  )}
                  {/* <div>Project active?: {coinDetails.is_active}</div> */}
                  {/* <div>
        {coinDetails.type} description: {coinDetails.description}
      </div> */}
                  {/* <div>Open source?: {coinDetails.open_source}</div> */}
                  {coinDetails.launch_date && (
                    <>
                      <div>
                        Launch Date:{" "}
                        {parseISO(coinDetails.launch_date).toString()}
                      </div>
                      <hr />
                    </>
                  )}
                  {coinDetails.development_status && (
                    <>
                      <div>
                        Project status: {coinDetails.development_status}
                      </div>
                      <hr />
                    </>
                  )}
                  <div>Proof type: {coinDetails.proof_type}</div>
                  <hr />
                  <div>Organization structure: {coinDetails.org_structure}</div>
                  <hr />
                  <div>Hashing algorithm: {coinDetails.hash_algo}</div>
                  <hr />
                  {/* <div>Whitepaper: {coinDetails.whitepaper}</div>
      <hr /> */}
                  <div>
                    Team Members:
                    <br />
                    {coinDetails.team &&
                      coinDetails.team.map((mem, index) => {
                        return (
                          <>
                            <div
                              key={mem.id}
                              data={mem}
                              className="cursor-pointer text-red-500  hover:underline"
                              onClick={() => {
                                setTeammemid(mem.id);
                              }}
                            >
                              {" "}
                              {mem.name}: {mem.position}
                            </div>
                          </>
                        );
                      })}
                  </div>
                </>
              )}
            </>
          ) : (
            <div>Coin/token not found!</div>
          )}
        </>
      )}
    </div>
  );
};

export default CoinInfoModal;
