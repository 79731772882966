export const CoinEvaluationInfo = [
  {
    sectionName: "Market Cap",
    description:
      "Having a low market cap can be a bad sign (less than $100 million); it means not enough money has flowed into that cryptocurrency. There are some exceptions, however. Which of the following sites show a market cap of more than $100 million? ",
    checklist: [
      { CoinGecko: "https://www.coingecko.com/en" },
      { CoinMarketCap: "https://www.coinmarketcap.com/" },
    ],
  },

  {
    sectionName: "Community Size",
    description:
      "How big is the community relative to the Market Cap? Most cryptocurrencies with market caps in the hundreds of millions will have tens of thousands of followers. Those with market caps in the billions must have hundreds of thousands of followers to give it a large enough market cap. Community is relative to market cap participation; low numbers can suggest paid followers, only tick “yes” if the following is true: ",
    checklist: [
      {
        millions:
          "Hundreds of millions of dollars ($) of market cap with 10,000 or more followers",
      },
      {
        billions:
          "Billions of dollars ($) of market cap with 100,000 or more followers",
      },
    ],
  },

  {
    sectionName: "Exchange Support",
    description:
      "Not every cryptocurrency exchange (Kucoin, FTX, Binance, Kraken, Coinbase and Gemini) is reputable with listing standards. Most reputable cryptocurrency exchanges don't want to be associated with coins that they deem as without “value”. Some exchanges like Kucoin and FTX list coins before they are mainstream. It helps to know that the trading volume is probably actual, and their standards are higher than DEXes like Uniswap (which have no standards and are easily manipulated). Is the coin or token on the following exchanges? *Note: A new popup screen will appear for each link.",
    checklist: [
      { Kucoin: "https://www.kucoin.com/markets" },
      { Binance: "https://www.binance.us/en/markets" },
      { Kraken: "https://www.kraken.com/prices" },
      { Coinbase: "https://www.coinbase.com/explore" },
      { Gemini: "https://www.gemini.com/prices" },
    ],
  },

  {
    sectionName: "Tokens vs Coins",
    description:
      "It's easy to create a token on a smart contract compatible blockchain such as Ethereum or Solana. By contrast, creating a cryptocurrency coin takes a lot of money and manpower, and this kind of investment increases the likelihood that the cryptocurrency in question won't be a “shitcoin”. Check if it's a token or coin on the web.",
    checklist: [
      {
        fake: "Are you able to find real and authentic reviews? Check out with google.com or go to the official reddit from the coin panel (if available)",
      },
    ],
  },

  {
    sectionName: "Whitepaper",
    description:
      "Check the whitepaper of the coin/token. Look out for a poorly written, overly complicated or lengthy whitepaper.",
    checklist: [
      {
        find: "Can you easily find the white paper on its website, Coinmarketcap.com or CoinGecko.com?",
      },
      {
        jargon:
          "Is the white paper written in plain English (avoids jargon) and shows technical expertise?",
      },
      {
        recent:
          "Does the paper begin with recent updates to technology pertaining to the coin or token (the most up to date information)?",
      },
    ],
  },

  {
    sectionName: "Use Case and Tokenomics",
    description:
      "In terms of tokenomics, initial coin or token distribution and vesting should be detailed along with the token smart contracts' admin keys. More importantly, current coin or token distribution should be easily verifiable on a blockchain explorer, and all wallet addresses belonging to the team should be clearly labelled.",
    checklist: [
      {
        purpose: "Does the coin serve a purpose?",
      },
      {
        evidence:
          "Does it explain how it outperforms another coin and supports that claim with evidence?",
      },
      {
        code: "Is the coin/token's code verifiable on Github.com (i.e. open source)?",
      },
      {
        distribution:
          "Is the initial coin or token distribution and vesting detailed along with the admin keys of the token smart contracts (if any)?",
      },
      {
        explorer:
          "Is the coin or token distribution easily verifiable on a blockchain explorer? (Check https://blockchain.tokenview.io/en/)",
      },
      {
        team: "Are all wallet addresses belonging to the coin/token team clearly labeled?",
      },
    ],
  },

  {
    sectionName: "Excessive Marketing",
    description:
      "Great technical projects don't need much marketing - excessive marketing means a lower quality product. Look at Twitter feed, if available.",
    checklist: [
      {
        growth:
          "Are there signs of organic growth? (Do not tick if there are signs of paid influencers or community efforts to villify anyone who questions the project on discord/telegram)",
      },
    ],
  },

  {
    sectionName: "Founders",
    description:
      "Check the founders on LinkedIn - not necessarily a good sign if they have worked at a big tech company (corporate experience doesn't confer start up ability).",
    checklist: [
      {
        startup:
          "Do the founders have start up experience on their LinkedIn profile? (Use https://www.linkedin.com/pub/dir/+/+?trk=homepage-basic_guest_nav_menu_people to search if needed.)",
      },
      {
        interviews:
          "Are they sincere when answering questions on live and recorded interviews? (Check youtube.com)",
      },
      {
        vision:
          "Do the founders have a clear vision of what they want their crypto project to become?",
      },
      {
        meme: "Is a crypto project original and non-derivative (not from a meme, slogan or a celebrity)?",
      },
    ],
  },
];
