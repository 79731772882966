import React, { useState, useEffect } from "react";

import TutorialHeader from "./TutorialHeader";
import TutorialTable from "./TutorialTable";

const Tutorial = (props) => {
  return (
    <div className="container mx-auto md:pb-64 pb-28">
      <TutorialHeader />
      <TutorialTable />
    </div>
  );
};

export default Tutorial;
