import React, { useState, useEffect, useContext } from "react";

import { ThemeContext } from "./Theme/ThemeContext";

import { FaHandPaper } from "react-icons/fa";

import imgdark from "../assets/images/maindark.png";
import imglight from "../assets/images/mainlight.png";
import startimg from "../assets/images/startbg.png";

import DisclaimerModal from "./DisclaimerModal/DisclaimerModal";
import StartButton from "./StartButton";

const Start = (props) => {
  // const { theme } = useContext(ThemeContext);
  //px-4 md:pb-64 pb-28 md:pt-20 h-screen w-full md:bg-startbg md:bg-right md:bg-cover md:bg-no-repeat

  const [agreed, setAgreed] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    setAgreed(window.sessionStorage.getItem("hasAgreed"));
    setEmail(window.sessionStorage.getItem("email"));
  }, []);

  useEffect(() => {
    setAgreed(window.sessionStorage.getItem("hasAgreed"));
  }, [agreed, setAgreed]);

  return (
    <div className="flex md:items-center h-screen">
      <div className="flex flex-col">
        <h1 className="md:text-7xl text-2xl text-center font-black">
          Dive into Digital Asset Insights
        </h1>
        <div className="py-10"></div>
        <StartButton title="Start Research" link="/news" />
      </div>

      <DisclaimerModal agreed={agreed} setAgreed={setAgreed} email={email} />
      {/* {theme == "dark" ? (
        <img className="pt-40 pb-48 pl-40" src={imgdark} />
      ) : (
        <img className="pt-40 pb-48 pl-40" src={imglight} />
      )} */}
    </div>
  );
};

export default Start;
