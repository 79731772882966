import React, { useState, useEffect } from "react";

import axios from "axios";

import { QuestionList } from "./QuestionList";
import QuestionHeader from "./QuestionHeader";
import SectionCompletedCoins from "../SectionCompletedCoins/SectionCompletedCoins";
import { apiLinks } from "../../utils/links";

const Questions = (props) => {
  const { moduleName } = props;
  const [email, setEmail] = useState(window.sessionStorage.getItem("email"));
  const [qnList, setQnList] = useState(
    QuestionList.find((element) => element.module == moduleName)
  );
  const [qns, setQns] = useState([]);
  const [arrLength, setArrLength] = useState(0);
  const [allAnswered, setAllAnswered] = useState(false);
  const [totalAnswered, setTotalAnswered] = useState(0);
  const [isSubmitPressed, setIsSubmitPressed] = useState(false);
  const [moduleId, setModuleId] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    var allQns = [];
    setArrLength(qnList.qns.length);

    // See if we have a moduleId value, if moduleId exists, means put, else post (create new data)
    if (sessionStorage.getItem(moduleName + "id")) {
      setModuleId(window.sessionStorage.getItem(moduleName + "id"));
    }

    qnList.qns.forEach((qn) => {
      var data = window.sessionStorage.getItem(qn);

      if (data) {
        // console.log(data);
        allQns.push({
          qn: qn,
          ans: data,
        });
      } else {
        allQns.push({
          qn: qn,
          ans: "",
        });
      }
    });
    setQns(allQns);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setTotalAnswered(0);
    setAllAnswered(false);
    setIsError(false);
    setIsSubmitPressed(false);
    setIsLoading(true);

    var count = 0;
    var tempId = "";
    var urlLink = "";

    if (moduleId === "") {
      //create, does not exist
      urlLink = apiLinks.reportApi + moduleName;
    } else {
      //edit, exists
      urlLink =
        apiLinks.reportApi + moduleName + "/" + email + "/cg/" + moduleId;
    }

    //TODO: possible design flaw - to make sure questions have been answered before sending answers to server and collecting coins?
    //TODO: See below todo for the trim logic
    if (moduleName === "calendar") {
      await axios({
        url: urlLink,
        method: "post",
        data: {
          email: email,
          significant_events: qns[0].ans.trim(),
          meme_coin: qns[1].ans.trim(),
          coin_updates: qns[2].ans.trim(),
          promising_altcoins: qns[3].ans.trim(),
        },
      })
        .then((res) => {
          // console.log(res.data);
          setIsLoading(false);

          if (!res.data) {
            setIsError(true);
            return;
          }

          tempId = res.data.id;
        })
        .catch((err) => {
          console.log(err);

          setIsLoading(false);
          setIsError(true);
        });
    } else if (moduleName === "fng") {
      await axios({
        url: urlLink,
        method: "post",
        data: {
          email: email,
          market_shortterm: qns[0].ans.trim(),
          micro_macro: qns[1].ans.trim(),
          stock_similarities: qns[2].ans.trim(),
        },
      })
        .then((res) => {
          // console.log(res.data);

          setIsLoading(false);

          if (!res.data) {
            setIsError(true);
            return;
          }

          tempId = res.data.id;
        })
        .catch((err) => {
          console.log(err);

          setIsLoading(false);
          setIsError(true);
        });
    } else if (moduleName === "walletmovement") {
      await axios({
        url: urlLink,
        method: "post",
        data: {
          email: email,
          wash_trading: qns[0].ans.trim(),
        },
      })
        .then((res) => {
          // console.log(res.data);

          setIsLoading(false);

          if (!res.data) {
            setIsError(true);
            return;
          }

          tempId = res.data.id;
        })
        .catch((err) => {
          console.log(err);

          setIsLoading(false);
          setIsError(true);
        });
    } else if (moduleName === "cryptonews") {
      await axios({
        url: urlLink,
        method: "post",
        data: {
          email: email,
          info_summary: qns[0].ans.trim(),
          news_bias: qns[1].ans.trim(),
          predictions: qns[2].ans.trim(),
          sell_signal_info: qns[3].ans.trim(),
          buy_signal_info: qns[4].ans.trim(),
        },
      })
        .then((res) => {
          // console.log(res.data);

          setIsLoading(false);

          if (!res.data) {
            setIsError(true);
            return;
          }

          tempId = res.data.id;
        })
        .catch((err) => {
          console.log(err);

          setIsLoading(false);
          setIsError(true);
        });
    } else if (moduleName === "por") {
      await axios({
        url: urlLink,
        method: "post",
        data: {
          email: email,
          disclosed_backing: qns[0].ans.trim(),
          insolvency: qns[1].ans.trim(),
        },
      })
        .then((res) => {
          // console.log(res.data);

          setIsLoading(false);

          if (!res.data) {
            setIsError(true);
            return;
          }

          tempId = res.data.id;
        })
        .catch((err) => {
          console.log(err);

          setIsLoading(false);
          setIsError(true);
        });
    }
    // else if (moduleName === ""){}

    //TODO: possible design flaw - to make sure questions have been answered before sending answers to server and collecting coins?
    qns.forEach((qn) => {
      if (qn.ans.trim() !== "") {
        count++;
      }

      window.sessionStorage.setItem(qn.qn, qn.ans.trim());
      // console.log(qn.ans);
    });

    setTotalAnswered(count);
    setIsSubmitPressed(true);
    window.sessionStorage.setItem(moduleName + "id", tempId);

    if (count == arrLength) {
      setAllAnswered(true);
      window.sessionStorage.setItem(moduleName, true);
      // console.log(moduleName + ": true(q)");
    } else {
      setAllAnswered(false);
      window.sessionStorage.setItem(moduleName, false);
      // console.log(moduleName + ": false(q)");
    }
  };

  const handleInputChange = (e, index) => {
    let q = [...qns];
    q[index].ans = e.target.value;

    setQns(q);
  };

  return (
    <div className="px-4">
      <div className="grid grid-cols-1 border-4 dark:border-lightbg border-darkbg border-opacity-100 rounded-lg items-center justify-center">
        <QuestionHeader />
        <div className="pt-4 px-4">
          {qns.map((q, i) => {
            return (
              <div key={i}>
                <label className="pb-4">
                  {i + 1}. {q.qn}{" "}
                </label>
                <br />
                <div className="pt-4"></div>
                <textarea
                  name="ans"
                  type="text"
                  value={q.ans || ""}
                  rows={4}
                  className="w-5/6 overflow-scroll dark:bg-slategrey bg-lightbg border"
                  onChange={(e) => handleInputChange(e, i)}
                />
                <div className="pb-8"></div>
              </div>
            );
          })}

          {isSubmitPressed ? (
            <>
              <div className="text-green-700">Saved!</div>

              <br />
            </>
          ) : (
            <></>
          )}

          {isError ? (
            <>
              <div className="text-red-700">
                Error saving... Please try again!
              </div>
              <br />
            </>
          ) : (
            <></>
          )}

          <input
            type="submit"
            value="Save my answers"
            onClick={handleSubmit}
            className="bg-orangebtn hover:bg-orangebtn-hover text-white py-2 px-4 rounded-xl cursor-pointer disabled:cursor-wait text-lg md:text-3xl"
            disabled={isLoading}
          />

          {isSubmitPressed && (
            <SectionCompletedCoins
              isSubmitPressed={isSubmitPressed}
              moduleName={moduleName}
              email={email}
              isLoading={isLoading}
            />
          )}
          <br />
          <br />
        </div>
        <br />
      </div>
    </div>
  );
};

export default Questions;
