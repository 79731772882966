import React, { useState, useEffect } from "react";

import { apiLinks } from "../../utils/links";

import CoinInfoModal from "./CoinInfoModal";
import TwitterModalGetByUsername from "../TwitterModal/TwitterModalGetByUsername";
import TwitterModal from "../TwitterModal/TwitterModal";
import CoinEvalTeamMember from "./CoinEvalTeamMember";
import CoinEvalNews from "./CoinEvalNews";

const CoinInfoPanel = (props) => {
  const { searchedCoinName } = props;

  const [teammemId, setTeammemid] = useState("");
  const [twitterId, setTwitterId] = useState("");

  return (
    <div className="overflow-y-auto border-2 rounded-lg lg:w-auto max-w-96 max-h-96 px-4 pt-2 pb-4 grid place-items-center">
      <div className="pb-2">Coin / Token Info</div>
      <div className="capitalize font-bold pb-5">{searchedCoinName}</div>
      <CoinInfoModal
        coinName={searchedCoinName}
        teammemId={teammemId}
        setTeammemid={setTeammemid}
        twitterId={twitterId}
        setTwitterId={setTwitterId}
      />
      <br />

      {/** temp remove - twitter changed api so this is not working, may change to matradon */}
      {/* {twitterId ? (
        <TwitterModalGetByUsername nftName={twitterId} imgStr={twitterId} />
      ) : (
        <TwitterModal nftName={searchedCoinName} imgStr={searchedCoinName} />
      )} */}

      {<CoinEvalNews searchedCoinName={searchedCoinName} />}
      {teammemId && <CoinEvalTeamMember teammemId={teammemId} />}
    </div>
  );
};

export default CoinInfoPanel;
