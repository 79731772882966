import React, { useState, useEffect } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { NavLink, withRouter, Link } from "react-router-dom";
import { useMoralis } from "react-moralis";
import ReactTooltip from "react-tooltip";

import MainIcon from "../../assets/images/icon_left.png";
import FnGIcon from "../../assets/images/LeftNav/7fearandgreed.svg";
import CryptoNewsIcon from "../../assets/images/LeftNav/3&4news.svg";
import CryptoCalendarIcon from "../../assets/images/LeftNav/5calendar.svg";
import RocketMovementIcon from "../../assets/images/LeftNav/8wallettowallet.svg";
import AirDropIcon from "../../assets/images/LeftNav/11airdrop.svg";
import CoinEvalIcon from "../../assets/images/LeftNav/1coinevaluation.svg";
import SwingTradeIcon from "../../assets/images/LeftNav/2swingtrading.svg";
import NFTEvalIcon from "../../assets/images/LeftNav/12nftevaluation.svg";
import ResultsIcon from "../../assets/images/LeftNav/results.svg";
import ExitIcon from "../../assets/images/LeftNav/exit.svg";

const LeftNavbar = (props) => {
  const { open, setOpen } = props;
  // const { logout } = useMoralis();

  // const logOut = async () => {
  //   await logout();
  // };

  return (
    <nav className="navbar" role="navigation" aria-label="side navigation">
      <div
        className={`top-0 left-0 leftNav fixed z-1 bg-slategrey transition flex flex-col duration-500 ease-in-out h-full ${
          open ? "w-1/5" : "w-0"
        }`}
      >
        <div className="overflow-x-hidden h-full">
          <div className="flex flex-col items-center text-lightbg">
            <NavLink
              className="lg:w-16 lg:h-16 pt-6 w-8 h-8"
              to="/index"
              activeClassName="is-active"
            >
              <img src={MainIcon} alt="home" />
            </NavLink>

            {/* <NavLink to="/news" activeClassName="is-active">
              <div className="grid grid-cols-3">
                <div></div>
                <img
                  className="w-28 h-28 pt-12"
                  src={NFTEvalIcon}
                  alt="nft evaluation"
                />
                <div></div>
              </div>
              <div className="lg:text-base md:text-sm text-xs font-bold pt-1">
                News
              </div>
            </NavLink> */}

            <div className="py-4"></div>

            <div
              data-tip
              data-for="newscal"
              data-event="click focus"
              className="cursor-pointer"
            >
              <div className="grid grid-cols-3">
                <div></div>
                <img className="w-28 h-28 pt-12" src={NFTEvalIcon} alt="news" />
                <div></div>
              </div>
              <div className="lg:text-base md:text-sm text-xs font-bold pt-1">
                News
              </div>
            </div>

            <ReactTooltip
              id="newscal"
              place="right"
              effect="solid"
              type={`info`}
              multiline={true}
              clickable={true}
              globalEventOff="click"
            >
              <NavLink
                to="/news"
                activeClassName="is-active"
                className="text-lg"
              >
                News
              </NavLink>
              <br />
              <NavLink
                to="/cryptocal"
                activeClassName="is-active"
                className="text-lg"
              >
                Calendar
              </NavLink>
            </ReactTooltip>

            <div
              data-tip
              data-for="researcheval"
              data-event="click focus"
              className="cursor-pointer"
            >
              <div className="grid grid-cols-3">
                <div></div>
                <img
                  className="w-28 h-28 pt-12"
                  src={CryptoCalendarIcon}
                  alt="research"
                />
                <div></div>
              </div>
              <div className="lg:text-base md:text-sm text-xs font-bold pt-1">
                Research
              </div>
            </div>

            <ReactTooltip
              id="researcheval"
              place="right"
              effect="solid"
              type={`info`}
              multiline={true}
              clickable={true}
              globalEventOff="click"
            >
              <NavLink
                to="/coineval"
                className="text-lg"
                activeClassName="is-active"
              >
                Coin Evaluation
              </NavLink>
              <br />
              {/* TODO: Redo and relink nft search url - remove moralis due to it deprecating search function  */}
              {/* <NavLink
                to="/nfteval"
                activeClassName="is-active"
                className="text-lg"
              >
                NFT Evaluation
              </NavLink> */}
            </ReactTooltip>

            {/* <NavLink to="/cryptocal" activeClassName="is-active">
              <div className="grid grid-cols-3">
                <div></div>
                <img
                  className="w-28 h-28 pt-12"
                  src={CryptoCalendarIcon}
                  alt="crypto calendar"
                />
                <div></div>
              </div>
              <div className="lg:text-base md:text-sm text-xs font-bold pt-1">
                Crypto Calendar
              </div>
            </NavLink> */}

            {/* <NavLink to="/coineval" activeClassName="is-active">
              <div className="grid grid-cols-3">
                <div></div>
                <img
                  className="w-28 h-28 pt-12"
                  src={CoinEvalIcon}
                  alt="coin evaluation"
                />
                <div></div>
              </div>
              <div className="lg:text-base md:text-sm text-xs font-bold pt-1">
                Coin Evaluation
              </div>
            </NavLink>

            <NavLink to="/nfteval" activeClassName="is-active">
              <div className="grid grid-cols-3">
                <div></div>
                <img
                  className="w-28 h-28 pt-12"
                  src={NFTEvalIcon}
                  alt="nft evaluation"
                />
                <div></div>
              </div>
              <div className="lg:text-base md:text-sm text-xs font-bold pt-1">
                NFT Evaluation
              </div>
            </NavLink> */}

            <NavLink to="/exchanges" activeClassName="is-active">
              <div className="grid grid-cols-3 ">
                <div></div>
                <img
                  className="w-28 h-28 pt-12"
                  src={CoinEvalIcon}
                  alt="exchanges"
                />
                <div></div>
              </div>
              <div className="lg:text-base md:text-sm text-xs font-bold pt-1">
                Exchanges
              </div>
            </NavLink>

            <NavLink to="/walletmovement" activeClassName="is-active">
              <div className="grid grid-cols-3">
                <div></div>
                <img
                  className="w-28 h-28 pt-12"
                  src={RocketMovementIcon}
                  alt="wallet movement data"
                />
                <div></div>
              </div>
              <div className="lg:text-base md:text-sm text-xs font-bold pt-1">
                Data
              </div>
            </NavLink>

            <NavLink to="/technicalanalysis" activeClassName="is-active">
              <div className="grid grid-cols-3 ">
                <div></div>
                <img
                  className="w-28 h-28 pt-12"
                  src={SwingTradeIcon}
                  alt="charting"
                />
                <div></div>
              </div>
              <div className="lg:text-base md:text-sm text-xs font-bold pt-1">
                Charting
              </div>
            </NavLink>

            <div className="py-8"></div>

            <NavLink to="/results" activeClassName="is-active">
              <div className="grid grid-cols-3">
                <div></div>
                <img
                  className="lg:w-20 lg:h-20 w-16 h-16 pt-12"
                  src={ResultsIcon}
                  alt="results"
                />
                <div></div>
              </div>
              <div className="lg:text-base md:text-sm text-xs font-bold pt-1">
                Results
              </div>
            </NavLink>
            <div className="py-8"></div>

            {/* <button onClick={logOut}>
              <div className="grid grid-cols-3">
                <div></div>
                <img
                  className="lg:w-20 lg:h-20 w-16 h-16 pt-12"
                  src={ExitIcon}
                  alt="log out"
                />
                <div></div>
              </div>
              <div className="lg:text-base md:text-sm text-xs font-bold pt-1">
                Log Out
              </div>
            </button> */}
          </div>
        </div>

        <div className={`flex items-center absolute inset-y-0 right-0 -mr-7`}>
          <button
            className={`rounded-full border border-purple bg-purple flex items-center justify-center ${
              open
                ? "h-14 w-14"
                : "h-6 w-6 transform duration-300 hover:scale-150"
            }`}
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <FaArrowLeft color="white" />
            ) : (
              <FaArrowRight color="white" />
            )}
          </button>
        </div>
      </div>
    </nav>
  );
};

export default LeftNavbar;
