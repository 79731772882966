import React, { useState, useEffect } from "react";

const ProofOfReservesHeader = (props) => {
  return (
    <div>
      <h1 className="text-center text-5xl flex justify-center pt-5">
        Proof Of Reserves
      </h1>
      <h3 className="text-center text-lg pt-5">
        Shows up to 10 largest coin reserves for each exchange on a 24 hours
        period
      </h3>
    </div>
  );
};

export default ProofOfReservesHeader;
