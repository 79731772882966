export const ConsultingPageInfo = [
  {
    title: "Digital Asset Due Diligence",
    list: [
      "In-depth analysis of news, calendar events, coins, tokens, NFTs, and crypto exchanges",
      "Evaluation of the credibility and potential of digital assets",
      "Identification of potential fraud signs and risk mitigation strategies",
      "AI-powered market analysis and technical analysis (MACD, RSI charts)",
      "Whale wallet movement monitoring and assessment",
    ],
  },
  {
    title: "Investment Strategy Advisory",
    list: [
      "Development of customized investment strategies based on client goals and risk profiles",
      "Portfolio construction and asset allocation recommendations",
      "Ongoing monitoring and rebalancing of digital asset portfolios",
      "Identification of hidden opportunities and emerging trends in the digital asset market",
    ],
  },
  {
    title: "Educational Resources",
    list: [
      "Comprehensive training and educational materials on digital asset investing",
      "Guidance on portfolio management tools and risk analysis techniques",
      "Access to industry experts and thought leaders for knowledge sharing",
    ],
  },
  {
    title: "Vision Consulting",
    list: [
      "Trends: Future of the world",
      "Long Term Thinking: Where we are really going",
      "Secular Trends and Exponential Effects",
    ],
  },
  {
    title: "Crypto Consulting",
    list: [
      "Advisory Desk: Due Diligence and Investment Advisory",
      "Portfolio Management and Valuation",
      "Tokenization and HF Strategies",
    ],
  },
  {
    title: "Ongoing Support and Consultation",
    list: [
      "Regular consultations and advisory sessions with our team of digital asset experts",
      "Customized reporting and analysis tailored to client-specific needs",
      "Access to a dedicated client success manager for personalized support",
    ],
  },
];
