export const SwingTradeQns = [
  {
    section: "trend",
    qns: [
      {
        qn:
          "Look for the long price trend, is it bullish (do you see any of the following) for the time frame?",
        options: [
          { "52d": "On 52 day moving average" },
          { "128d": "On 128 day moving average" },
          { "200d": "On 200 day moving average" },
        ],
      },
      {
        qn: "Has the MACD turned up from below zero?",
      },
      {
        qn:
          "Is the RSI reading of 30 or below? (A sign that indicates that the coin/token is oversold and may soon rise.)",
      },
    ],
  },
];
