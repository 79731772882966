import React, { useState, useEffect } from "react";

const SingleLink = (props) => {
  const { title, link } = props;

  return (
    <div className="lg:pr-4 lg:inline-block block justify-center">
      <a
        href={link}
        className="block py-2 px-3 leading-none hover:bg-darkbg dark:hover:bg-lightbg hover:text-lightbg  dark:hover:text-darkbg rounded mt-4 lg:mt-0"
        rel="noreferrer"
      >
        {title}
      </a>
    </div>
  );
};

export default SingleLink;
