import React, { useState, useEffect } from "react";

import { FaArrowDown } from "react-icons/fa";

import ProofOfReserves from "../ProofOfReserves/ProofOfReserves";
import WalletBalanceExchange from "../WalletBalanceExchange/WalletBalanceExchange";
import ExchangeNetPosChange from "../ExchangeNetPosChange/ExchangeNetPosChange";
import Questions from "../Questions/Questions";

const ExchangeEvaluation = (props) => {
  const [selectedExchange, setSelectedExchange] = useState("");
  const [moduleName, setModuleName] = useState("por");

  return (
    <div className="container mx-auto md:pb-64 pb-28">
      <ProofOfReserves
        selectedExchange={selectedExchange}
        setSelectedExchange={setSelectedExchange}
      />

      {/* {selectedExchange && (
        <>
          <div className="pb-10 align-items-center justify-center flex">
            <FaArrowDown size={100} color="red" />
          </div>

          <WalletBalanceExchange
            selectedExchange={selectedExchange}
            setSelectedExchange={setSelectedExchange}
          />

          <div className="pb-10 align-items-center justify-center flex">
            <FaArrowDown size={100} color="red" />
          </div>

          <ExchangeNetPosChange
            selectedExchange={selectedExchange}
            setSelectedExchange={setSelectedExchange}
          />
        </>
      )} */}

      <Questions moduleName={moduleName} />
    </div>
  );
};

export default ExchangeEvaluation;
