import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const SingleButton = (props) => {
  const { title, link } = props;

  return (
    <div className="lg:pr-4 lg:inline-block block justify-center">
      <Link
        to={link}
        className="block py-2 px-3 leading-none border border-darkbg dark:border-lightbg hover:bg-darkbg hover:text-lightbg dark:hover:bg-lightbg dark:hover:text-darkbg rounded mt-4 lg:mt-0"
      >
        {title}
      </Link>
    </div>
  );
};

export default SingleButton;
