export const HelpInfo = [
  {
    sectionName: "Getting started with DYOR",
    description:
      "Welcome to DYOR — a suite of tools for evaluating digital assets. DYOR transforms the way you do due diligence by offering a comprehensive and efficient solution for assessing the value and risk of digital assets.",
    subSectionName: [
      "Sidebar",
      "Closed Sidebar",
      "Modules",
      "Sub Modules",
      "Top Navigation bar",
    ],
    subSectionText: [
      "From the sidebar, you can access the different modules in DYOR. You'll be able to use the arrow button to open or close the sidebar. When the sidebar is open, you'll see a list of sections to for doing research in.",
      "If the sidebar is closed, you can click on the arrow button in the left hand corner to open it.",
      "Modules are fundamental to working in DYOR — they are different areas of due diligence you would need to do to get a comprehensive understanding of a digital asset. On clicking each different module, it will bring you to a different section of the application for your research. Clicking on the logo brings you back to the home screen.",
      "When clicked, some modules open up a sub module for you to choose. A sub module is a specific area of research under the same research theme of the module. Clicking on the sub module brings you to the section of research of the sub module.",
      "Use the navigation bar to move to bonus sections of the application to help you get started in using DYOR, to change to light / dark mode, or for rewards for using DYOR.",
    ],
    subSectionPics: [
      "sidebar.jpg",
      "closedsidebar.jpg",
      "modules.jpg",
      "submodule.jpg",
      "navigationbar.jpg",
    ],
  },

  {
    sectionName: "News",
    description:
      "The News module contains 2 subsections: News and Calendar. You will be able to check out digital asset related news and events to find out about the latest developments and trends in the cryptocurrency and NFT world.",
    subSectionName: ["News module icon", "Crypto News", "Calendar"],
    subSectionText: [
      "Clicking the News button on the sidebar will reveal a submenu to navigate to either the News or Calendar section. You can click on the News button again to close the submenu.",
      "Clicking on the News submenu will navigate to show you the latest Crypto News from around the web, drawn from various news sources from different crypto news sites. Clicking on the title of each news box will open up a popup with the news article. You can minimize, maximize or close the new popup, similar to a browser window. \n\nThere are a series of questions that will guide you to evaluate the news you have perused. Click on the Save my answers button to save your answers after you have answered the questions. Going through the questions will earn you Gryphon coins once per day. Be sure to answer as many questions as you can to aid yourself in thinking through the latest development in the digital assets market.",
      "Clicking on the Calendar submenu will navigate to show you the latest Event Calendar from around the web, drawn from CoinMarketCal. Clicking on the image of each news box will open up a popup with the CoinMarketCal event. You can minimize, maximize or close the new popup, similar to a browser window. \n\nThere are a series of questions that will guide you to evaluate the events you have perused. Click on the Save my answers button to save your answers after you have answered the questions. Going through the questions will earn you Gryphon coins once per day. Be sure to answer as many questions as you can to aid yourself in thinking through the latest development in the digital assets market.",
    ],
    subSectionPics: ["news-submenu.jpg", "cryptonews.jpg", "calendar.jpg"],
  },

  {
    sectionName: "Research",
    description:
      "The Research module contains 2 subsections: Coin Evaluation and NFT Evaluation. You will be guided through an in-depth research process for a coin or token in the Coin Evaluation sub module, and for an NFT in the NFT Evaluation sub module.",
    subSectionName: [
      "Research module icon",
      "Coin Evaluation",
      "Getting started with Coin Evaluation",
      "Sub sections of Coin Evaluation",
      "NFT Evaluation",
      "Getting started with NFT Evaluation",
      "Sub sections of NFT Evaluation",
    ],
    subSectionText: [
      "Clicking the Research button on the sidebar will reveal a submenu to navigate to either the Coin Evaluation or NFT Evaluation section. You can click on the Research button again to close the submenu.",
      "Clicking on the Coin Evaluation submenu will navigate to a screen where you can choose to do a New Analysis of a coin or token, or continue with a Previous Analysis. If you have never done a Coin Evaluation before, click on the New Analysis button.",
      "After choosing 'New Analysis', enter the name of the coin or token you want to do an analysis on, then click on the Search Coin button.",
      "The program will then gather information about the coin or token you have entered. Each sub section comes with different questions that will guide you through the evaluation of the coin or token. The text in red will open up a popup with the each different link. You can minimize, maximize or close the new popup, similar to a browser window. \n\nClick on Save at the end of each sub section to save your answers. Going through the questions will earn you Gryphon coins once per day for evaluation of 1 coin or token. Be sure to answer as many questions as you can to aid yourself in doing due diligence for the coin or token you have chosen.",
      "Clicking on the NFT Evaluation submenu will navigate to a screen where you can choose to do a New Analysis of a NFT project, or continue with a Previous Analysis. If you have never done a NFT Evaluation before, click on the New Analysis button.",
      "After choosing 'New Analysis', enter the name of the NFT project you want to do an analysis on, then click on the Search NFT button.",
      "The program will then gather information about the NFT project you have entered. Each sub section comes with different questions that will guide you through the evaluation of the NFT project. The text in red will open up a popup with the each different link. You can minimize, maximize or close the new popup, similar to a browser window. \n\nClick on Save at the end of each sub section to save your answers. Going through the questions will earn you Gryphon coins once per day for evaluation of 1 coin or token. Be sure to answer as many questions as you can to aid yourself in doing due diligence for the NFT project you have chosen.",
    ],
    subSectionPics: [
      "research-submenu.jpg",
      "coineval1.jpg",
      "coineval2.jpg",
      "coineval3.jpg",
      "nfteval1.jpg",
      "nfteval2.jpg",
      "nfteval3.jpg",
    ],
  },

  {
    sectionName: "Exchanges",
    description:
      "The Exchanges module contains information for Proof of Reserves on various exchanges. These reserves are self-reported by the exchange in an effort to be transparent with their users. Note that the section shows the top ten coins available in the exchange's reserves based on number of coins itself, not USD.",
    subSectionName: ["Proof of Reserves"],
    subSectionText: [
      "To get the latest Proof of Reserves of an exchange, choose an exchange from the list, then click on 'Get Reserves' to get the latest Proof of Reserves of the exchange you have chosen. Details of the number of each of the top ten coins in terms of numbers will be displayed in the first graph. The second graph shows the exchange's number of coins change in reserves. Mouse over the graph for each coin or token at the 0.0 line will show more details.\n\nThere are a series of questions that will guide you to evaluate the events you have perused. Click on the Save my answers button to save your answers after you have answered the questions. Going through the questions will earn you Gryphon coins once per day. Be sure to answer as many questions as you can to aid yourself in thinking through the latest development in the digital assets market.",
    ],
    subSectionPics: ["proofreserve.jpg"],
  },

  {
    sectionName: "Data",
    description:
      "The Data module contains information of wallet movement for large amount of cryptocurrencies (over US$500,000). This can be used to observe and analysis the flow of digital assets from one wallet to another.",
    subSectionName: ["Wallet Movements"],
    subSectionText: [
      "To get the latest large wallet movements of a coin, choose a coin from the list, then click on 'Get latest movements' to get the latest wallet movements of the coin you have chosen. Details of the sending and receiving wallets would be provided, if available.\n\nThere are a series of questions that will guide you to evaluate the events you have perused. Click on the Save my answers button to save your answers after you have answered the questions. Going through the questions will earn you Gryphon coins once per day. Be sure to answer as many questions as you can to aid yourself in thinking through the latest development in the digital assets market.",
    ],
    subSectionPics: ["wallet.jpg"],
  },

  {
    sectionName: "Charting",
    description:
      "The Charting module contains the Fear & Greed sub section and the Swing Trading sub section. Swing Trading is located under the Fear & Greed subsection. This module is used to evaluate the general trend of cryptocurrencies.",
    subSectionName: [
      "Fear & Greed General Sentiment",
      "Coin's latest index",
      "Swing Trading Checklist",
      "Candlestick Graph",
      "MacD and RSI Graphs",
    ],
    subSectionText: [
      "The general sentiment of the Cryptocurrency market is shown at the Overall Index, with a value ranging from 0 to 100. Higher number indicated greed, while lower number indicated fear.",
      "To get the latest index of a coin, choose a coin from the list, then click on 'Get latest index' to get the current price and price trends of the coin you have chosen. Details of the coins such as the number of circulation coins are also displayed, if available.\n\nThere are a series of questions that will guide you to evaluate the events you have perused. Click on the Save my answers button to save your answers after you have answered the questions. Going through the questions will earn you Gryphon coins once per day. Be sure to answer as many questions as you can to aid yourself in thinking through the latest development in the digital assets market.",
      "The Swing Trading checklist is a list of questions designed to guide you to interpret the Candlestick, RSI and MacD graphs of Bitcoin and Ethereum, the current 2 main players in the cryptocurrency market. Click on the Save button to save your checklist answers after you have answered the questions. Going through the questions will earn you Gryphon coins once per day. Be sure to answer as many questions as you can to aid yourself in thinking through the latest development in the digital assets market.",
      "The Candlestick Graph for both Bitcoin and Ethereum allows you to choose between 52, 128 and 200 days to help you with the swing trading questions. Click on the radio button to change views of the graph between 52, 128 and 200 days.",
      "The MacD graph shows data for up to 20 previous days, while the RSI graph shows data for up to 15 previous days.",
    ],
    subSectionPics: [
      "fng1.jpg",
      "fng2.jpg",
      "swingtrade1.jpg",
      "swingtrade2.jpg",
      "swingtrade3.jpg",
    ],
  },

  {
    sectionName: "Results",
    description:
      "The Results page contains the answers you have provided to the questions you have ran through when doing your due diligence in each section.",
    subSectionName: ["Saving an answer", "Your answers on the Results page"],
    subSectionText: [
      "When you save the answers for all answered questions in a section, if it is the first time you are answering the questions in a module for the day, you will receive Gryphon coins as a reward for completing due diligence. You will not receive coins for editing an answer and saving it.",
      "Once your results are saved, you can find it on the Results page. Please note that only modules where all questions have been answered will show up, otherwise, the module is deemed incomplete and does not show up on the Results page.",
    ],
    subSectionPics: ["results1.jpg", "results2.jpg"],
  },

  {
    sectionName: "Top Navigation Bar",
    description:
      "The top Navigation Bar contains links for bonus content in the digital asset evaluation suite. You will be able to find the Tutorial, Airdrops, Rewards, Daily Check-in, Account, Help Center, and Dark/Light mode.",
    subSectionName: [
      "Tutorial",
      "Airdrops",
      "Rewards",
      "Daily Check-in",
      "Account",
      "Help Center",
      "Dark/Light mode",
    ],
    subSectionText: [
      "The Tutorial section contains questions and answers you may have regarding the different technical jargons in each module. Clicking on each information icon for each question opens up a video to explain each term used in the digital asset world. Clicking on the X in the left hand corner or anywhere on the page (except the video) dismisses the video popup.",
      "The Airdrops section contains a list of airdrops available for different protocols and the blockchain they are based on. There are also details showing the action required on your part if you wish to acquire the airdrop for yourself. Clicking on each red link under Protocol will bring you to the page where the airdrop is based on.",
      "The Rewards page is coming soon.",
      "The Daily Check-in page allows you to check in to get Gryphon coins, which you can use to exchange for rewards on the Rewards page. You can get coins once per day. If you break your streak, you will start again from day 1. After you collect your coins on the 7th day, it will be reset back to day 1. The daily coin collection time resets at 0:00 UTC time.",
      "The Accounts page contains information about your account, such as your email address and the number of Gryphon coins you have acquired.",
      "The Help Center section contains a list of topics that would help you get acquainted with using DYOR. Each section can be collapsed and uncollapsed as needed by clicking on each topic header, and will guide you through how to use each section of DYOR. ",
      "The Sun/Moon icon changes the theme of the app to Light/Dark mode. Clicking on the Sun icon changes it to Light mode, while clicking on the Moon turns it to Dark mode. DYOR's default is Dark mode.",
    ],
    subSectionPics: [
      "tutorial.jpg",
      "airdrops.jpg",
      "rewards.jpg",
      "dailycheckin.jpg",
      "account.jpg",
      "helpcenter.jpg",
      "darklightmode.jpg",
    ],
  },

  {
    sectionName: "Footer",
    description:
      "The footer contains links for Privacy Policy, Terms of Service, and Contact Us pages. Our social media links are also located at the footer, where you can find DYOR social media profiles.",
    subSectionName: ["Legal links", "Social Media links"],
    subSectionText: [
      "You can find all our legal links and contact us page here. Each page will open in a new window when clicked.",
      "You can find our social media pages here. Each page will open in a new window when clicked.",
    ],
    subSectionPics: ["legallinks.jpg", "sociallinks.jpg"],
  },
];
