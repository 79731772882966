import React, { useState, useEffect } from "react";

import { TutorialInfo } from "./TutorialInfo";
import ExplainerVideo from "../ExplainerVideo/ExplainerVideo";

const TutorialTable = (props) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(TutorialInfo);
  }, []);

  return (
    <div className="flex flex-auto grid text-center grid-cols-9 pt-8 pr-4">
      <div className="col-span-1"></div>
      <table className="table-auto col-span-7 whitespace-pre-line">
        {/*<thead>
           <tr>
            <th className="border-2 border-gray-400">Section No.</th>
            <th className="border-2 border-gray-400">Section Name</th>
            <th className="border-2 border-gray-400">Description</th>
          </tr> 
        </thead>*/}
        <tbody>
          {tableData &&
            tableData.map((dat, index) => (
              <tr key={index}>
                <td
                  className={`text-xl py-8 ${
                    index !== tableData.length - 1 ? "border-b" : ""
                  }`}
                >
                  {dat.sectionNo}
                </td>
                <td
                  className={`text-2xl py-8 ${
                    index !== tableData.length - 1 ? "border-b" : ""
                  }`}
                >
                  {dat.sectionName}
                </td>
                <td
                  className={`text-lg text-left py-8 ${
                    index !== tableData.length - 1 ? "border-b" : ""
                  }`}
                >
                  {dat.questions.map((qn, j) => (
                    <>
                      <span className="pr-2">{qn}</span>
                      <ExplainerVideo
                        vidId={dat.ytLink[j]}
                        tooltipText={dat.tooltipText[j]}
                      />
                      <br />
                    </>
                  ))}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default TutorialTable;
