import ReactModal from "react-modal";

import React, { useState, useEffect } from "react";

const PdfModal = (props) => {
  const { openPdf, setOpenPdf, imgUrl } = props;

  const handleClose = () => {
    setOpenPdf(!openPdf);
  };

  return (
    <>
      <ReactModal
        isOpen={openPdf}
        contentLabel={"Coin Gryphon Disclaimer"}
        className="outline-none focus:outline-none text-darkbg dark:text-lightbg w-3/5 h-2/3 absolute top-17 left-1/3 overflow-y-auto bg-darkbg p-4 rounded flex-auto"
      >
        <div
          className="float-right font-bold text-xl cursor-pointer pr-2 text-lightbg"
          onClick={handleClose}
        >
          X
        </div>
        <div className="pb-10"></div>
        <object
          data={imgUrl}
          type="application/pdf"
          className="rounded-lg"
          width={955}
          height={580}
        ></object>
      </ReactModal>
    </>
  );
};

export default PdfModal;
