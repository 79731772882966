import React, { useState, useEffect } from "react";

import axios from "axios";

import { apiLinks } from "../../utils/links";

import CoinNewsModal from "./CoinNewsModal";

const CoinEvalNews = (props) => {
  const { searchedCoinName } = props;

  const [coinNewsInfo, setCoinNewsInfo] = useState("");
  const [coinNewsError, setCoinNewsError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [infoIndex, setInfoIndex] = useState("");

  useEffect(() => {
    axios({
      url: apiLinks.coinNewsApi + searchedCoinName,
      method: "get",
    })
      .then((res) => {
        setCoinNewsError(false);
        setCoinNewsInfo(res.data);
      })
      .catch((err) => {
        setCoinNewsError(true);
        console.log(err);
      });
  }, [searchedCoinName]);

  const handleClick = (index) => {
    setShowModal(!showModal);
    setInfoIndex(index);
  };

  return (
    <>
      {coinNewsInfo && !coinNewsError && (
        <div className="pt-5">
          <div className="py-2">News</div>
          <div className="overflow-y-auto border-2 rounded-lg h-96 w-96 px-4 py-2 text-base text-left">
            {coinNewsInfo.map((info, index) => {
              return (
                <div key={info.title} className="py-2">
                  <div
                    className="cursor-pointer text-linkifylink hover:underline"
                    onClick={() => handleClick(index)}
                  >
                    {info.title}
                  </div>
                  <div>
                    Published: {new Date(`${info.published_at}`).toString()}
                  </div>
                  <div>Author(s): {info.author.name}</div>
                  {info.pdfUrl && (
                    <div>
                      Additional Materials:{" "}
                      <a
                        className="cursor-pointer text-linkifylink hover:underline"
                        href={info.pdfUrl}
                        target="_blank"
                      >
                        PDF
                      </a>
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          {showModal && (
            <CoinNewsModal
              info={coinNewsInfo[infoIndex]}
              showModal={showModal}
              setShowModal={setShowModal}
            />
          )}
        </div>
      )}
    </>
  );
};

export default CoinEvalNews;
