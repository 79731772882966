import React, { useState, useEffect } from "react";

import ResultsHeader from "./ResultsHeader";
import BiteResult from "./BiteResult";
import CoinEvalResult from "./CoinEvalResult";
import SwingTradeResult from "./SwingTradeResult";
import NftEvalResult from "./NftEvalResult";

const coinEvalSectionName = "coineval";
const nftEvalSectionName = "nfteval";
const swingTradeSectionName = "swingtrade";

const Results = (props) => {
  const moduleNames = [
    "calendar",
    "fng",
    "walletmovement",
    "cryptonews",
    "por",
  ];

  const [coinEval, setCoinEval] = useState(
    window.sessionStorage.getItem(coinEvalSectionName)
  );

  const [swingTrade, setSwingTrade] = useState(
    window.sessionStorage.getItem(swingTradeSectionName)
  );

  const [nftEval, setNftEval] = useState(
    window.sessionStorage.getItem(nftEvalSectionName)
  );

  // useEffect(() => {
  //   console.log("coineval: " + coinEval);
  //   console.log("swing: " + swingTrade);
  //   console.log("nfteval: " + nftEval);
  // }, []);

  return (
    <div>
      <ResultsHeader />
      <div className="pt-20 md:pb-64 pb-28">
        {coinEval === true && (
          <>
            <CoinEvalResult coinEvalSectionName={coinEvalSectionName} />
            <hr />
          </>
        )}

        <br />

        {swingTrade && (
          <>
            <SwingTradeResult swingTradeSectionName={swingTradeSectionName} />
            <hr />
          </>
        )}

        <br />
        <br />

        {moduleNames.map((element) => {
          return <BiteResult moduleName={element} key={element} />;
        })}

        <br />
        <br />

        {nftEval === true && (
          <>
            <hr />
            <NftEvalResult nftEvalSectionName={nftEvalSectionName} />
          </>
        )}
      </div>
    </div>
  );
};

export default Results;
