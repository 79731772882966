import React, { useState, useEffect } from "react";

const NFTEvalHeader = (props) => {
  return (
    <div>
      <h1 className="text-center text-5xl flex justify-center pt-5">
        NFT Evaluation
      </h1>
    </div>
  );
};

export default NFTEvalHeader;
