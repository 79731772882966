import React, { useState, useEffect } from "react";

import SingleButton from "./SingleButton";

const TopNavbar = (props) => {
  return (
    <>
      {/* <div className="text-sm lg:flex-grow bg-yellow-500"> */}
      {/* <a
          href="#responsive-header"
          className="mt-4 inline-block lg:mt-0 text-teal-200 hover:text-white"
        >
          Blog
        </a> */}
      {/* </div> */}
      <SingleButton title="Tutorial" link="/tutorial" />
      <SingleButton title="Get Help" link="/help" />
      <div className="px-5"></div>
      <SingleButton title="Airdrops" link="/airdrops" />
      {/* <SingleButton title="Rewards" link="/rewards" /> */}
      <div className="px-5"></div>
      <SingleButton title="Daily Check-in" link="/daily" />
      <SingleButton title="Account" link="/account" />
    </>
  );
};

export default TopNavbar;
