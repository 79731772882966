import React, { useState, useEffect } from "react";

import { NFTEvalList } from "../NFTEval/NFTEvalList";
import NftEvalSectionResult from "./NftEvalSectionResult";

const NftEvalResult = (props) => {
  const { nftEvalSectionName } = props;

  const [nftName, setNftName] = useState("");
  const [qnSections, setQnSections] = useState([]);
  const [allQns, setAllQns] = useState([]);

  useEffect(() => {
    setNftName(window.sessionStorage.getItem("NftName"));

    var tempSections = [];
    var tempAllQns = [];

    NFTEvalList.forEach((e) => {
      tempSections.push(e.section);

      var tempSectionQns = [];
      e.qns.forEach((qn) => {
        tempSectionQns.push(qn);
      });

      tempAllQns.push(tempSectionQns);
    });

    setQnSections(tempSections);
    setAllQns(tempAllQns);
  }, []);

  return (
    <div className="px-12 py-8">
      <div className="text-lg">
        <span className="font-bold">Evaluating NFT: </span>{" "}
        <span className="italic">{nftName}</span>
      </div>

      <br />

      {qnSections &&
        allQns &&
        qnSections.map((element, index) => {
          return (
            <NftEvalSectionResult
              sectionName={element}
              nftEvalSectionName={nftEvalSectionName}
              key={element}
              allQns={allQns[index]}
            />
          );
        })}
    </div>
  );
};

export default NftEvalResult;
