import React, { useState, useEffect } from "react";

import { CSSTransition } from "react-transition-group";
import { HiChevronDoubleDown, HiChevronDoubleUp } from "react-icons/hi";
import Linkify from "linkify-react";
import axios from "axios";

import "./CollapsiblePanel.css";

// const SECTIONNAME_REFERENCE = [
//   { "Market Cap": "marketcap" },
//   { "Community Size": "commsize" },
//   { "Exchange Support": "exchange" },
//   { "Tokens vs Coins": "reviews" },
//   { Whitepaper: "whitepaper" },
//   { "Use Case and Tokenomics": "coin" },
//   { "Excessive Marketing": "organic" },
//   { Founders: "founder" },
// ];

const CollapsiblePanel = (props) => {
  const { data } = props;

  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <h3
        type="button"
        onClick={() => {
          setIsVisible(!isVisible);
          // console.log(dbData);
          // console.log(Object.values(SECTIONNAME_REFERENCE[index]));
        }}
        className="font-bold text-xl pb-2 text-justify"
      >
        {data.title}
        {isVisible ? (
          <HiChevronDoubleUp size={30} className="pl-4 inline" />
        ) : (
          <HiChevronDoubleDown size={30} className="pl-4 inline" />
        )}
      </h3>

      <CSSTransition in={!isVisible} timeout={300} classNames="test">
        {!isVisible ? (
          <></>
        ) : (
          <ul>
            {data &&
              data.list.map((d, i) => {
                return (
                  <li key={d} className="pb-2">
                    {d}
                  </li>
                );
              })}
          </ul>
        )}
      </CSSTransition>
    </>
  );
};

export default CollapsiblePanel;
