import React, { useState, useEffect } from "react";

const CoinCard = (props) => {
  const { eventDetail, index } = props;
  const [eventDate, setEventDate] = useState("");
  var tempdate = "";

  useEffect(() => {
    // console.log(eventDetail);
    let temp = eventDetail.date_event.split(/\D/);

    tempdate =
      temp[0] +
      "-" +
      temp[1] +
      "-" +
      temp[2] +
      "T" +
      temp[3] +
      ":" +
      temp[4] +
      ":" +
      temp[5].substr(0, 3) +
      "+00:00";

    var thisdate = new Date(tempdate);
    setEventDate(thisdate + "");
  }, []);

  return (
    <div className="border-2 rounded h-max p-4 dark:bg-gray-700 bg-gray-100">
      <a href={eventDetail.source} target="_blank">
        {/* <div>{index + 1}</div> */}
        <div className="font-bold hover:underline text-2xl pb-2">
          {eventDetail.title.en}
        </div>
        <div className="text-center text-lg pt-5 dark:text-cryptocalcoin text-buttonlink">
          <span className="font-bold">{"Coins / Tokens:\xa0"}</span>
          {eventDetail.coins.map((coin, index) => {
            return (
              <span>
                {coin.fullname}
                {index < eventDetail.coins.length - 1 ? ",\xa0" : ""}
              </span>
            );
          })}
        </div>
        <div className="text-center text-sm pt-5 text-cryptocaldate dark:text-green-500">
          <span className="font-bold">Event Date:</span> {eventDate}
        </div>
        <div className="text-center text-lg pt-5 text-green-500 dark:text-cryptocalcoin">
          <span className="font-bold">{"Event Type:\xa0"}</span>
          {eventDetail.categories.map((cat, index) => {
            //   console.log(cat.name);
            return (
              <span>
                {cat.name}
                {index < eventDetail.categories.length - 1 ? ",\xa0" : ""}
              </span>
            );
          })}
        </div>
        <div>
          <div className="pt-8 pb-4">
            <img
              className="place-self-center"
              src={eventDetail.proof}
              alt={eventDetail.title.en}
            />
          </div>
        </div>
      </a>
    </div>
  );
};

export default CoinCard;
