import React, { useState, useEffect } from "react";

import { SwingTradeQns } from "../SwingTrading/SwingTradeQns";

const SwingTradeResult = (props) => {
  const { swingTradeSectionName } = props;

  const [allQns, setAllQns] = useState([]);
  const [allAns, setAllAns] = useState([]);

  useEffect(() => {
    var temp = window.sessionStorage.getItem(swingTradeSectionName + " tobuy");

    if (temp) {
      var arr = temp.split(",");
      setAllAns(arr);
    }

    var tempAllQns = [];

    SwingTradeQns.forEach((e) => {
      e.qns.forEach((qn) => {
        if (qn.options) {
          qn.options.forEach((op) => {
            tempAllQns.push(qn.qn + " (" + Object.values(op) + ")");
            // console.log(qn.qn + " (" + Object.values(op) + ")");
          });
        } else {
          tempAllQns.push(qn.qn);
        }
      });
    });

    setAllQns(tempAllQns);
  }, []);

  return (
    <div className="px-12 pb-8">
      <div className="text-lg font-bold">Technical Analysis</div>
      {allQns && allAns && (
        <div className="text-lg text-left pt-6">
          {allQns.map((element, index) => {
            return (
              <>
                <span>{Object.values(element)} : </span>
                <span className="italic text-orangebtn">
                  {allAns[index] === "true" ? "yes" : "no"}
                </span>
                <br />
              </>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SwingTradeResult;
