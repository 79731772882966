import React, { useState, useEffect } from "react";

import HelpHeader from "./HelpHeader";
import HelpTable from "./HelpTable";
import PossibleBuySignalsPanel from "./PossiblyBuySignalsPanel";

const Help = (props) => {
  return (
    <div className="container mx-auto md:pb-64 pb-28">
      <HelpHeader />
      <HelpTable />
      <PossibleBuySignalsPanel />
    </div>
  );
};

export default Help;
