import React, { useState, useEffect } from "react";

import axios from "axios";

import { apiLinks } from "../../utils/links";

const SectionCompletedCoins = (props) => {
  //isLoading is passed to make sure module save post finishes BEFORE updating section completed coins, else getting errors of that running concurrently before save query completes
  const { isSubmitPressed, moduleName, email, isLoading } = props;
  const [coinNo, setCoinNo] = useState(
    window.sessionStorage.getItem("coinsNo")
  );
  const [thisSectionIsLoading, setThisSectionIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [coinsGained, setCoinsGained] = useState(0);
  const [coinsAlreadyReceived, setCoinsAlreadyReceived] = useState(false);

  useEffect(() => {
    var tempOldCoinVal = coinNo;

    const getSectionCompletedCoins = async () => {
      setThisSectionIsLoading(true);

      await axios({
        url: apiLinks.reportApi + "coin/" + email,
        method: "post",
        data: {
          module_name: moduleName,
        },
      })
        .then((res) => {
          setThisSectionIsLoading(false);
          // console.log(res.data);

          if (!res.data) {
            setIsError(true);
            setCoinsAlreadyReceived(false);
            return;
          }

          //coin already received for today for this area
          if (res.data.message) {
            setCoinsAlreadyReceived(true);
            return;
          }

          setCoinNo(res.data.curr_coins);
          setCoinsGained(res.data.curr_coins - tempOldCoinVal);
          window.sessionStorage.setItem("coinsNo", res.data.curr_coins);
          setCoinsAlreadyReceived(true);
        })
        .catch((err) => {
          console.log(err);

          setCoinsAlreadyReceived(false);
          setThisSectionIsLoading(false);
          setIsError(true);
        });
    };

    getSectionCompletedCoins();
  }, []);

  return (
    <div>
      <>
        {isSubmitPressed && !isLoading && (
          <div className="pt-4 text-lg">
            Congratulations, you gained {coinsGained} coins! You currently have{" "}
            {coinNo} coins.
          </div>
        )}
      </>
      <>
        {coinsAlreadyReceived && (
          <div className="pt-4 text-lg">
            You have already received coins for completing this section
            previously!
          </div>
        )}
      </>
    </div>
  );
};

export default SectionCompletedCoins;
