import React, { useState, useEffect } from "react";
import { startOfMonth, getTime, getYear, getMonth, format } from "date-fns";
import axios from "axios";

import { FaLongArrowAltRight } from "react-icons/fa";

import RocketMovementHeader from "./RocketMovementHeader";
import CryptoScoller from "./CryptoScroller";
import Transfer from "./Transfer";
import { apiLinks } from "../../utils/links";
import Questions from "../Questions/Questions";

const RocketMovement = (props) => {
  // const [rocketMonthDate, setRocketMonthDate] = useState();
  // const [rocketMonth, setRocketMonth] = useState("");
  // const [rocketYear, setRocketYear] = useState("");
  // const [monthTimestamp, setMonthTimestamp] = useState(0);
  // const [timeStampRetrieve, setTimeStampRetrieve] = useState(0);
  const [todayDate, setTodayDate] = useState("");
  const [clickAway, setClickAway] = useState(false);

  const [transactionNo, setTransactionNo] = useState(0);
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [coinName, setCoinName] = useState("");
  const [fullCoinName, setFullCoinName] = useState("");
  const [noTransaction, setNoTransaction] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [moduleName, setModuleName] = useState("walletmovement");

  const setAllDateTime = () => {
    const timeElapsed = Date.now();
    // const timeToRetrieve = Math.floor((timeElapsed - 300000) / 1000);
    const thisdateToday = new Date(timeElapsed);
    // const thisMonthStartDate = startOfMonth(thisdateToday);
    // const thisMonthTimestamp = getTime(thisMonthStartDate);
    // const thisYear = getYear(thisMonthStartDate);
    // const thisMonth = getMonth(thisMonthStartDate);
    // console.log(timeElapsed);
    // console.log(timeToRetrieve);

    // setRocketYear(thisYear);
    // setRocketMonth(thisMonth);
    // setMonthTimestamp(thisMonthTimestamp);
    // setRocketMonthDate(thisMonthStartDate);
    // setTimeStampRetrieve(timeToRetrieve);
    setTodayDate(format(thisdateToday, "dd MMM yyyy"));
  };

  const getRocketMovements = async () => {
    setIsLoading(true);

    await axios({
      url: apiLinks.rocketMovement + coinName,
      method: "get",
    })
      .then((res) => {
        // console.log(res.data);
        // console.log(res.data.transactions);
        setIsLoading(false);

        if (res.data.result == "success") {
          setTransactionNo(res.data.count);
          setError(false);
          if (res.data.count > 0) {
            setTransactionDetails(res.data.transactions);
            setNoTransaction(false);
          } else {
            setNoTransaction(true);
          }
        } else {
          //error 429 - rate limiting of 10 calls per min
          setError(true);
        }
      })
      .catch((err) => {
        console.log(err);

        setIsLoading(false);
        setError(true);
      });
  };

  useEffect(() => {
    setAllDateTime();
  }, []);

  return (
    <div className="container mx-auto md:pb-64 pb-28">
      <RocketMovementHeader />
      <h2 className="text-center text-3xl justify-center pt-5">{todayDate}</h2>
      <br />
      <br />
      <CryptoScoller
        className="mx-auto justify-center"
        setCoinName={setCoinName}
        setFullCoinName={setFullCoinName}
        setClickAway={setClickAway}
      />
      <div className="text-xl sm:hidden">
        Scroll for more coins <FaLongArrowAltRight className="inline" />
      </div>
      <br />
      <br />
      <button
        className="border-4 bg-yellow-400 hover:bg-green-400 dark:border-lightbg border-darkbg dark:text-darkbg rounded-lg p-5 disabled:cursor-wait text-2xl"
        disabled={isLoading}
        onClick={() => {
          getRocketMovements();
          setClickAway(false);
        }}
      >
        Get latest movements
      </button>
      <br />
      <br />

      {error && !clickAway ? (
        <h3 className="text-center text-2xl flex justify-center pt-5">
          Error! Please wait for 2 minutes before trying again.
        </h3>
      ) : null}

      {noTransaction && !clickAway && !error ? (
        <h3 className="text-center text-3xl flex justify-center pt-5">
          {fullCoinName} has no transactions over USD$500,000 recently!
        </h3>
      ) : null}

      {isLoading && (
        <h3 className="text-center text-3xl flex justify-center pt-5">
          Getting wallet movements of {fullCoinName}...
        </h3>
      )}

      {transactionNo > 0 && !clickAway && !error ? (
        <>
          <h3 className="text-center text-3xl flex justify-center pt-5">
            {fullCoinName} has {transactionNo} transactions over USD$500,000
            recently!
          </h3>
          <br />
          <br />
          {transactionDetails.map((transaction) => {
            return (
              <>
                <Transfer transaction={transaction} />
                <br />
                <br />
              </>
            );
          })}
        </>
      ) : null}

      <br />
      <br />
      <Questions moduleName={moduleName} />
    </div>
  );
};

export default RocketMovement;
