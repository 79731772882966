import React, { useState, useEffect } from "react";

import Coins from "../../assets/images/Coin/daily.png";

const CoinCollectionTile = (props) => {
  const { dayNo, coinNo, currDayNo, setCurrDayNo } = props;

  return (
    <div
      className={`border rounded-xl border-none text-center justify-center items-center w-1/12 grid grid-row-4 py-2 ${
        dayNo == currDayNo ? "bg-purple text-lightbg" : "bg-slate"
      }`}
    >
      <p className="text-sm row-span-1">{`Day ${dayNo + 1}`}</p>
      <div className="row-span-2 flex items-center justify-center">
        <img className="w-2/4" src={Coins} alt="" />
      </div>

      <p className="text-sm row-span-1">{`+${coinNo}`}</p>
    </div>
  );
};

export default CoinCollectionTile;
